/* ------------------------------------------------------------ *\
	Wrapper
\* ------------------------------------------------------------ */

.wrapper {
	overflow: hidden;
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-flow: column nowrap;
	width: 100%;
}
