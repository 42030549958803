/* ------------------------------------------------------------ *\
	Services
\* ------------------------------------------------------------ */

.services {
	.services__items {
		max-width: 940px;
		display: flex;
		justify-content: center;
		flex-flow: row wrap;
		align-items: center;
		margin: auto;
		margin-bottom: -56px;

		@include breakpoint-down(mobile) {
			flex-direction: column;
			margin-bottom: -32px;
		}
	}

	.services__item {
		max-width: 27%;
		flex: 0 0 27%;
		margin-bottom: 56px;

		@include breakpoint-down(mobile) {
			max-width: 210px;
			flex: 0 0 100%;
			margin-bottom: 32px;
		}
	}
}

.service {
	max-width: 210px;
	margin: auto;

	.service__icon {
		min-height: 64px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
	}

	.service__head {
		margin-bottom: 5px;
	}

	.service__entry p {
		line-height: 1.36;
	}

	.service__title {
		font-weight: 300;
		@include breakpoint-down(mobile) {
			font-size: 24px;
		}
	}

	@include breakpoint-down(mobile) {
		max-width: 100%;
	}
}
