/* ------------------------------------------------------------ *\
	Section Intro
\* ------------------------------------------------------------ */

.section-intro {
	min-height: 1080px;
	display: flex;
	flex-direction: row;

	.section__container {
		width: 100%;
		flex-direction: column;
		justify-content: center;
		min-height: inherit;
	}

	.section__title {
		font-weight: 300;
		line-height: 1.04;
		letter-spacing: 1px;
		margin-bottom: 84px;

		@include breakpoint-down(large-desktop) {
			margin-bottom: 74px;
		}

		@include breakpoint-down(tablet) {
			letter-spacing: 0;
			line-height: 1.2;
			margin-bottom: 22px;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 46px;
		}
	}

	.section__subtitle {
		position: relative;
	}

	.section__subtitle h3 {
		margin-bottom: 0;
		font-weight: 300;
		line-height: 1.31;
		@include breakpoint-down(tablet) {
			line-height: 1.4;
		}
	}

	.section__shell {
		@include breakpoint-down(large-desktop) {
			max-width: 1191px;
		}
	}

	@include breakpoint-down(large-desktop) {
		min-height: 800px;
	}

	@include breakpoint-down(tablet) {
		min-height: auto;
		padding: 176px 0 114px;
	}

	@include breakpoint-down(mobile) {
		padding: 181px 0 130px;
	}
}

/* Section Intro alt */

.section-intro--alt {
	.section__head {
		width: 49%;
		margin-bottom: 0;
		@include breakpoint-down(mobile) {
			width: 93%;
			margin-bottom: 31px;
		}
	}

	.section__shell {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: row;
		min-height: inherit;

		@include breakpoint-down(tablet) {
			padding-left: 69px;
			padding-right: 69px;
		}

		@include breakpoint-down(mobile) {
			padding-left: 20px;
			padding-right: 20px;
			flex-direction: column;
			align-items: flex-start;
		}
	}

	.section__title {
		letter-spacing: 0;
		line-height: 1.19;
		margin-bottom: 0;

		@include breakpoint-down(mobile) {
			font-size: 28px;
			line-height: 1.36;
		}
	}

	.section__image {
		width: 41%;
		@include breakpoint-down(tablet) {
			width: 48%;
		}

		@include breakpoint-down(mobile) {
			width: 100%;
		}
	}

	.section__image img {
		width: 100%;
	}

	@include breakpoint-down(tablet) {
		padding: 150px 0 185px;
	}

	@include breakpoint-down(mobile) {
		padding: 114px 0 0;
	}
}


/* ------------------------------------------------------------ *\
	Section Intro OB
\* ------------------------------------------------------------ */

.section-intro--ob {
	@include breakpoint-down(tablet) {
		padding: 113px 0 17px; 
	}

	@include breakpoint-down(mobile) {
		padding: 58px 0 0;
		margin-bottom: -27px;
	}


	.section__head {
		@include breakpoint-down(tablet) {
		    margin-bottom: 72px;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 85px;
		}

	}

	.section__title {
		@include breakpoint-down(tablet) {
		    font-size: 28px;
		    line-height: 1.38; 
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Intro RHS
\* ------------------------------------------------------------ */

.section-intro--rhs {
	@include breakpoint-down(tablet) {
		padding: 145px 0 0; 
	}

	@include breakpoint-down(mobile) {
		padding: 58px 0 0;
		margin-bottom: -27px;
	}


	.section__head {
		@include breakpoint-down(tablet) {
		    margin-bottom: 18px;
		}
	}

	.section__title {
		@include breakpoint-down(tablet) {
		    font-size: 28px;
		    line-height: 1.38; 
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Intro Topps
\* ------------------------------------------------------------ */

.section-intro--topps {
	@include breakpoint-down(tablet) {
		padding: 65px 0 12px !important;
	}

	@include breakpoint-down(mobile) {
		padding: 58px 0 12px !important;
	}

	.section__head {
		margin-bottom: 22px;

		@include breakpoint-down(tablet) {
		    margin-bottom: 18px;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 85px;
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Intro Vashi
\* ------------------------------------------------------------ */

.section-intro--vashi {
	@include breakpoint-down(tablet) {
		padding: 134px 0 7px;
	}

	@include breakpoint-down(mobile) {
		padding: 58px 0 13px;
	}

	.section__head {
		margin-bottom: 30px;

		@include breakpoint-down(mobile) {
			margin-bottom: 47px;
		}
	}
}