/* ------------------------------------------------------------ *\
	Highlight
\* ------------------------------------------------------------ */

.highlight {
	position: relative;
	display: inline-block;

	.highlight__ico {
		position: absolute;
		top: 101%;
		left: -21px;
		overflow: hidden;
		width: 0;
		transition: width 1s;
		height: 15px;
		display: block;

		@include breakpoint-down(tablet) {
			left: 0;
		}
	}

	.highlight__ico svg {
		position: absolute;
		top: 0;
		left: 0;
		/* width: 100%; */
	}
}

.highlight--1 {
	.highlight__ico {
		@include breakpoint-down(mobile) {
			margin-top: -8px;
		}
	}
}

.highlight--2 {
	.highlight__ico {
		@include breakpoint-down(tablet) {
			margin-top: -3px;
		}
	}
}

.highlight--3 {
	.highlight__ico {
		@include breakpoint-down(tablet) {
			margin-top: -3px;
		}
	}
}

.highlight--4 {
	.highlight__ico {
		margin-top: -4px;
		left: -25px;

		@include breakpoint-down(tablet) {
			margin-top: -11px;
		}

		@include breakpoint-down(mobile) {
			margin-top: -8px;
		}
	}
}

.highlight--5 {
	.highlight__ico {
		margin-top: -5px;
		left: -5px;
		@include breakpoint-down(large-desktop) {
			margin-top: -8px;
		}

		&.is-active {
			@include breakpoint-down(large-desktop) {
				width: 79px !important;
			}
		}
	}
}

.highlight--6 {

	.highlight__ico {
		left: -4px;
		top: 88%;

		@include breakpoint-down(mobile) {
			left: 7px;
			margin-top: -5px;
		}
	}
}

.highlight--7 {
	.highlight__ico {
		left: -4px;
		margin-top: -1px;
	}
}

.highlight--8 {
	.highlight__ico {
		left: -4px;
		margin-top: -5px;

		@include breakpoint-down(mobile) {
			margin-top: 0;
		}
	}
}

.highlight--9 {
	.highlight__ico {
		left: 2px;
		margin-top: -1px;

		@include breakpoint-down(mobile) {
			margin-top: 0;
		}
	}
}
