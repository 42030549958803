//Variables
$font-size-base: 14px;
$font-weight-base: 400;
$line-height-base: 1.71;

$ff-sans-serif: 'Lato', Helvetica, Arial, sans-serif;
$ff-nanum-pen: 'Nanum Pen Script', cursive;
$ff-plantin: 'Plantin', sans-serif;
$ff-serif: Georgia, 'Times New Roman', Times, serif;
$ff-domaine: 'Domaine', sans-serif;

$t-duration: 0.4s;

$shell-max-width: 1210px;
$shell-large: 1392px;
$shell-small: 1056px;
$shell-medium: 1130px;
$shell-gutter: 32px;

$c-black: #000;
$c-white: #fff;
$c-pearl: #f7f4f1;
$c-light-blue: #eff3f3;
$c-base-1: #212121;
$c-base-2: #222;
$c-pink: #e12954;
$c-pink-2: #D53657;
$c-light-gray-1: #dcdcdc;
$c-light-gray-2: #d4d4d4;
$c-light-gray-3: #949494;
$c-light-gray-3: #949494;
$c-light-gray-4: #9E9E9E;


$responsive-ios: 812px;

$breakpoints: (
	mobile-small: 374px,
	mobile-medium: 575px,
	mobile: 767px,
	admin-bar: 782px,
	tablet: 991px,
	small-desktop: 1199px,
	large-desktop: 1440px
);
