/* ------------------------------------------------------------ *\
	List Categories
\* ------------------------------------------------------------ */

.list-caregories {
	list-style-type: none;
	display: flex;

	a {
		text-decoration: none;
		position: relative; 
		z-index: 2;
		font-weight: 700; 
		color: $c-pink-2;

		@include breakpoint-down(mobile) {
			font-size: 12px;
		}
	}

	li {
		position: relative; 
		z-index: 2;
		padding-left: 20px; 
	}

	li:after {
		position: absolute;
		content: ''; 
		left: 0;
		top: -11px; 
		height: 42px;	
		background-repeat: no-repeat;
		z-index: 1;

		@include breakpoint-down(mobile) {
			height: 38px;
			top: -5px
		}
	}

	li + li {
		padding-left: 50px; 
	}

	li + li:after {
		left: 34px;
	}
}