/* ------------------------------------------------------------ *\
	Articles Olivers
\* ------------------------------------------------------------ */

.articles-olivers {
	.article-single {
		margin-bottom: 142px;
		padding: 0 54px 0 41px;
		justify-content: space-between;

		@include breakpoint-down(tablet) {
			margin-bottom: 80px;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 126px;
		}

		.article__content {
			margin-top: -20px; 
		}
	}

	.article-single:first-child {
		align-items: flex-start;

		.article__content {
			margin-top: 97px; 
		}
	}

	.article-single:nth-child(2) {
		.article__image {
			@include breakpoint-down(tablet) {
				text-align: center; 
			}

			img {
				@include breakpoint-down(tablet) {
					width: 81%;
				}

				@include breakpoint-down(mobile) {
					width: 100%;
				}
			}		
		}
	}

	.article-single:last-child {
		padding-right: 120px; 
	}
	
	.article-single--reversed {
		padding-left: 155px; 
	}

	.article-single:last-child {
		margin-bottom: 0
	}
}

/* ------------------------------------------------------------ *\
	Articles Agent
\* ------------------------------------------------------------ */

.articles-agent {
	padding: 130px 0 47px;

	@include breakpoint-down(tablet) {
		padding: 25px 0 37px; 
	}
	 
	.article-single {
		margin-bottom: 182px;
		padding: 0 16px 0 72px;
		justify-content: space-between;

		.article__content {
			margin-top: 0; 

			p {
				letter-spacing: 0; 
			}
		}

		.article__image {
			@include breakpoint-down(tablet) {
				margin-bottom: 65px;
			}

			@include breakpoint-down(mobile) {
				margin-bottom: 40px;
			}
		}

		@include breakpoint-down(tablet) {
			margin-bottom: 106px;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 148px;
		}
	}

	.article-single:first-child {
		justify-content: space-evenly;
		padding-left: 0;

		.article__content {
			margin: -40px 0 0 0; 
		}
	}

	.article-single:nth-child(2) {
		@include breakpoint-down(tablet) {
			margin-bottom: 150px;
		}

		.article__content {
			margin: -30px 79px 0 0;
		}

		.article__image {
			@include breakpoint-down(tablet) {
				text-align: center; 
				margin-bottom: 26px;
			}

			@include breakpoint-down(mobile) {
				margin-bottom: 20px;
			}

			img {
				@include breakpoint-down(tablet) {
					width: 100%;
				}
			}		
		}
	}

	.article-single:nth-child(3) {
		align-items: flex-start;
		margin-bottom: 273px;

		@include breakpoint-down(tablet) {
			margin-bottom: 120px;
		}

		.article__content {
			margin-top: 118px;

			@include breakpoint-down(large-desktop) {
				margin-top: 30px; 
			}

			h4 {
				letter-spacing: -0.1px;
			}
		}

		.article__image {
			img {
				height: 408px;
				width: auto;

				@include breakpoint-down(large-desktop) {
					width: 100%;
					height: auto;
				}
			}

			@include breakpoint-down(tablet) {
				text-align: center; 
			}

			img {
				@include breakpoint-down(tablet) {
					width: 81%;
					height: auto;
				}

				@include breakpoint-down(mobile) {
					width: 100%;
				}
			}		
		}
	}


	.article-single:nth-child(4) {
		justify-content: space-evenly;
		align-items: flex-start;
		padding: 0 23px;	
		margin-bottom: 265px;

		@include breakpoint-down(tablet) {
			margin-bottom: 98px;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 141px;
		}
		
		.article__image {
			@include breakpoint-down(tablet) {
				margin-bottom: 92px;
			}

			@include breakpoint-down(mobile) {
				margin-bottom: 70px;
			}

			img {
				@include breakpoint-down(mobile) {
					width: 85%;
				    height: auto;
				}
			}
		}

		.article__content {
			margin-top: 157px; 
		}
	}


	.article-single:last-child {
		padding: 0 120px 0 80px;
		align-items: flex-start;

		.article__image {
			@include breakpoint-down(tablet) {
				margin-bottom: 30px;
			}
		}

		.article__content {
			margin-top: 85px; 
		}
	}

	.article-single--reversed {
		padding-left: 97px; 
	}

	.article-single:last-child {
		margin-bottom: 0
	}
}

/* ------------------------------------------------------------ *\
	Articles RHS
\* ------------------------------------------------------------ */

.articles-rhs {
	padding-top: 30px;

	@include breakpoint-down(tablet) {
		padding-top: 0;
	}

	.article-single {
		margin-bottom: 136px;

		@include breakpoint-down(tablet) {
			margin-bottom: 150px;
		}

		.article__content p {
			letter-spacing: 0;
		}
	}

	.article-single:first-child {
		margin-bottom: 181px;
		padding-left: 100px;
		align-items: flex-start;

		@include breakpoint-down(tablet) {
			margin-bottom: 100px;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 142px;
		}

		.article__content {
			margin: 90px 80px 0 0; 

			@include breakpoint-down(small-desktop) {
				margin-top: 50px; 
			}	

			h4 {
				max-width: 220px;

				@include breakpoint-down(tablet) {
					max-width: 100%
				}
			}		
		}

		.article__image {
			@include breakpoint-down(tablet) {
				margin-bottom: 26px;
			}

			@include breakpoint-down(mobile) {
				margin-bottom: 18px;
			}

			img {
				@include breakpoint-down(tablet) {
					width: 100%;
				}
			}
		}
	}

	.article-single:nth-child(2) {
		padding: 0 107px 0 38px;
		align-items:flex-start;

		@include breakpoint-down(mobile) {
			margin-bottom: 130px;
		}	

		.article__content {
			margin-top: 145px; 

			@include breakpoint-down(small-desktop) {
				margin-top: 100px; 
			}		
		}

		.article__image {
			img {
				@include breakpoint-down(tablet) {
					width: 64%;
					height: auto;
				}

				@include breakpoint-down(mobile) {
					width: 80%;
				}
			}
		}
	}

	.article-single:nth-child(3) {
		align-items:flex-start;

		@include breakpoint-down(tablet) {
			margin-bottom: 110px;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 140px;
		}

		.article__content {
			margin: 197px 99px 0 0; 
			
			@include breakpoint-down(small-desktop) {
				margin-top: 120px; 
			}			
		}

		.article__image {
			img {
				@include breakpoint-down(tablet) {
					width: 64%;
					height: auto;
				}

				@include breakpoint-down(mobile) {
					width: 82%;
				}
			}
		}
	}

	.article-single:nth-child(4) {
		align-items:flex-start;
		padding: 8px 119px 0 38px;

		@include breakpoint-down(tablet) {
			margin-bottom: 0;
		}

		.article__content {
			margin: 203px 99px 0 0; 

			@include breakpoint-down(small-desktop) {
				margin-top: 50px; 
			}
		}

		.article__image {
			@include breakpoint-down(tablet) {
				margin-bottom: 60px;
			}

			@include breakpoint-down(mobile) {
				margin-bottom: 38px;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Articles topps
\* ------------------------------------------------------------ */

.articles-topps {
	@include breakpoint-down(tablet) {
		padding-top: 0;
	}

	.article-single {
		.article__content p {
			letter-spacing: 0;
		}
	}

	.article-single:first-child {
		max-width: 740px;
		width: 100%;
		margin: 0 auto 222px;
		display: block;

		@include breakpoint-down(mobile) {
			margin-bottom: 120px;
		}

		.article__content {
			max-width: 100% !important;
			padding-left: 0; 
			margin-top: 0; 
		}

		h3 {
			@include breakpoint-down(mobile) {
				line-height: 1.3; 
			}
		}

		.article__image {
			margin-bottom: 29px;

			@include breakpoint-down(tablet) {
				margin-bottom: 53px;
			}

			@include breakpoint-down(mobile) {
				margin-bottom: 26px;
			}

			img {
				@include breakpoint-down(tablet) {
					width: 100%;
				}
			}
		}
	}

	.article-single:nth-child(2) {
		padding: 0 107px 0 38px;
		align-items:flex-start;
		margin-bottom: 100px;

		@include breakpoint-down(mobile) {
			margin-bottom: 130px;
		}	

		.article__content {
			margin: 75px 32px 0 0; 

			@include breakpoint-down(small-desktop) {
				margin-top: 50px; 
			}		
		}

		.article__image {
			img {
				max-width: 544px;

				@include breakpoint-down(tablet) {
					max-width: 100%;
					height: auto;
				}
			}
		}
	}

	.article-single:nth-child(3) {
		padding: 0 40px;
		margin-bottom: 99px;

		@include breakpoint-down(tablet) {
			margin-bottom: 148px;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 130px;
		}

		.article__content {
			margin: 0 99px 25px 0; 
			
			@include breakpoint-down(small-desktop) {
				margin-top: 120px; 
			}			
		}

		.article__image {
			@include breakpoint-down(tablet) {
				margin-bottom: 24px;
			}

			@include breakpoint-down(mobile) {
				margin-bottom: 12px;
			}

			img {
				@include breakpoint-down(tablet) {
					max-width: 100%;
					height: auto;
				}
			}
		}
	}

	.article-single:nth-child(4) {
		padding: 0 0 0 38px;
		margin-bottom: 175px;

		@include breakpoint-down(tablet) {
			margin-bottom: 146px;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 128px;
		}

		.article__content {
			margin: 0 178px 28px 0; 

			@include breakpoint-down(small-desktop) {
				margin-top: 50px; 
			}
		}

		.article__image {
			@include breakpoint-down(tablet) {
				margin-bottom: 24px;
			}

			@include breakpoint-down(mobile) {
				margin-bottom: 16px;
			}

			img {
				@include breakpoint-down(tablet) {
					width: 100%;
				}
			}
		}
	}

	.article-single:nth-child(5) {
		padding: 0 42px;
		margin-bottom: 40px;

		@include breakpoint-down(mobile) {
			margin-bottom: 17px;
		}

		.article__content {
			margin: 0 178px 28px 0; 

			@include breakpoint-down(small-desktop) {
				margin-top: 50px; 
			}
		}

		.article__image {
			@include breakpoint-down(tablet) {
				margin-bottom: 49px;
			}

			@include breakpoint-down(mobile) {
				margin-bottom: 14px;
			}
		}
	}

}

/* ------------------------------------------------------------ *\
	Articles Vashi 
\* ------------------------------------------------------------ */

.articles-vashi {
	.article-single:first-child {
		align-items: flex-start;
		padding: 0 42px 135px 158px;

		@include breakpoint-down(tablet) {
			margin-bottom: 152px;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 156px;
		}

		.article__content {
			margin-top: 63px; 
		}
	}

	.article-single:nth-child(2) {
		align-items: flex-start;
		padding: 0 74px 152px 39px;

		@include breakpoint-down(tablet) {
			margin-bottom: 144px;
		}

		@include breakpoint-down(mobile) {
		    margin-bottom: 127px;
		}

		.article__content {
			margin: 78px 74px 0 0; 
			max-width: 340px;

			@include breakpoint-down(small-desktop) {
				margin-top: 50px; 
			}
		}
	}

	.article-single:nth-child(3) {
		align-items: flex-start;
		padding: 0px 36px 216px 159px;

		@include breakpoint-down(tablet) {
			margin-bottom: 147px;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 125px;
		}

		.article__content {
			margin: 100px 0 0 0; 

			p {
				max-width: 290px;

				@include breakpoint-down(tablet) {
					max-width: 100%;
				}
			}
		}

		.article__image {
			@include breakpoint-down(tablet) {
				margin-bottom: 28px;
			}
		}
	}


	.article-single:nth-child(4) {
		align-items: flex-start;
		padding: 0px 136px 26px 38px;

		@include breakpoint-down(tablet) {
			margin-bottom: 40px;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 20px;
		}

		.article__content {
			margin: 67px 0 0 0; 
		}

		.article__image {
			@include breakpoint-down(tablet) {
				margin-bottom: 28px;
			}

			@include breakpoint-down(mobile) {
				margin-bottom: 17px;
			}
		}
	}

	.article-single {
		@include breakpoint-down(small-desktop) {
			padding: 0 40px 135px 40px !important;
		}

		@include breakpoint-down(tablet) {
			padding: 0  !important; 
		}

		.article__image {
			img {
				@include breakpoint-down(tablet) {
					max-width: 100%;
					width: 100%;
					height: auto;
				}
			}

			@include breakpoint-down(mobile) {
				margin-bottom: 27px;
			}
		}
	}
} 