/* ------------------------------------------------------------ *\
	Article single
\* ------------------------------------------------------------ */

.article-single {
	display: flex;
	justify-content: space-between;
	align-items: center;

	@include breakpoint-down(tablet) {
		display: block;
		padding: 0 37px !important;
	}

	@include breakpoint-down(mobile) {
		padding: 0 !important;
	}

	.article__content {
		max-width: 435px;
		width: 100%;
		margin: 53px 93px 0 0; 
		padding-left: 20px;

		@include breakpoint-down(tablet) {
			padding-left: 0; 
		}
	
		&--xs {
			max-width: 320px;
		}

		&--295w {
			max-width: 295px !important;
		}

		&--320w {
			max-width: 320px !important;
		}

		&--310w {
			max-width: 310px !important;
		}

		&--260w {
			max-width: 260px !important;
		}

		&--350w {
			max-width: 350px !important;
		}

		&--335w {
			max-width: 335px !important;
		}

		&--390w {
			max-width: 390px !important;
		}

		@include breakpoint-down(tablet) {
			max-width: 510px !important;
			margin: 0 auto !important;
		}
	}

	.article__content p {
		letter-spacing: 0.15px;
	}

	&--ap {
		align-items: flex-start;
		
		.article__image {
			@include breakpoint-down(tablet) {
				margin-bottom: 34px;
			}
		}

		.article__content {
			margin: 55px 93px 0 0; 
		}

		h4 {
			margin-bottom: 31px;
		}

		p {
			margin-bottom: 33px;
		}
	}

	&--rhs {
		align-items: flex-start;
		margin-bottom: 57px;

		@include breakpoint-down(tablet) {
			margin-bottom: 0;
		}

		.article__content {
			margin-top: 60px; 

			@include breakpoint-down(tablet) {
				max-width: 100% !important;
				margin-left: 0 !important;  
			}

			h4 {
				margin-bottom: 33px;
			}

			p {
				@include breakpoint-down(tablet) {
					max-width: 97% !important;
					margin-bottom: 20px !important;
				}

				@include breakpoint-down(mobile) {
					margin-bottom: 27px !important;
				}
			}
		}

		.article__image {
			 @include breakpoint-down(mobile) {
			 	margin-bottom: 37px !important;
			 }
		}
	}

	&--topps {
		.article__image {
			@include breakpoint-down(mobile) {
				margin-bottom: 18px !important;
			}
		}

		h4 {
			margin-bottom: 30px !important;
		}
	}

	h4 {
		font-weight: 400; 
		@include breakpoint-down(mobile) {
			font-size: 24px;
		}
	}

	.article__image {
		@include breakpoint-down(tablet) {
			text-align: center; 
			margin-bottom: 38px;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 19px;
		}
	}

	&--reversed {
		flex-direction: row-reverse;

		.article__content {
			padding-left: 0;
		}

		&--xs {
			max-width: 330px;
		}

		&--295w {
			max-width: 315px !important;
		}

		&--320w {
			max-width: 340px !important;
		}

		&--310w {
			max-width: 330px !important;
		}

		&--260w {
			max-width: 280px !important;
		}

		&--350w {
			max-width: 370px !important;
		}

		&--335w {
			max-width: 355px !important;
		}

		&--390w {
			max-width: 410px !important;
		}
	}
}

.article-single--alt {
	align-items: flex-start;

	p {
		font-size: 16px;
		line-height: 1.5;
	}

	h4 {
		@include breakpoint-down(tablet) {
			max-width: 415px;
		}

		@include breakpoint-down(mobile) {
			font-size: 24px;
		}
	}

	img {
		@include breakpoint-down(tablet) {
			width: 100%;
		}
	}

	.article__content {
		margin: 182px 82px 0 0;

		@include breakpoint-down(tablet) {
			max-width: 99% !important;
			margin-left: 0 !important;  
		}
	}
}

.article-single--vashi {
	align-items: flex-start;

	.article__content {
		margin-top: 55px;

		h4 {
			margin-bottom: 40px;
		}

		p {
			@include breakpoint-down(tablet) {
				margin-bottom: 20px !important;
			}
		}
	}

	.article__image {
		@include breakpoint-down(tablet) {
			margin-bottom: 37px !important;
		}

		@include breakpoint-down(tablet) {
			margin-bottom: 22px !important;
		}
	}
}