/* ------------------------------------------------------------ *\
  Vendor
\* ------------------------------------------------------------ */

@import '~bootstrap/dist/css/bootstrap.css';
@import '~aos/dist/aos.css';
@import '~swiper/css/swiper.css';
@import '~magnific-popup/dist/magnific-popup.css';

//@import '~vendor-name/vendor.scss/css';

/* ------------------------------------------------------------ *\
	Generic
\* ------------------------------------------------------------ */

@import 'generic/variables';
@import 'generic/functions';
@import 'generic/mixins';
@import 'generic/reset';
@import 'generic/fonts';
@import 'generic/base';
@import 'generic/helpers';

/* ------------------------------------------------------------ *\
	Components
\* ------------------------------------------------------------ */

@import '_sprite.css';
@import "components/_article-alt.scss";
@import "components/_article-blog.scss";
@import "components/_article-single.scss";
@import "components/_article-small.scss";
@import "components/_article.scss";
@import "components/_articles-blog.scss";
@import "components/_articles.scss";
@import "components/_block-project.scss";
@import "components/_block.scss";
@import "components/_btn.scss";
@import "components/_category.scss";
@import "components/_checkbox.scss";
@import "components/_circle.scss";
@import "components/_field.scss";
@import "components/_footer.scss";
@import "components/_form.scss";
@import "components/_grid.scss";
@import "components/_header.scss";
@import "components/_highlight.scss";
@import "components/_icons.scss";
@import "components/_link.scss";
@import "components/_list-basic.scss";
@import "components/_list-categories.scss";
@import "components/_list-contacts-alt.scss";
@import "components/_list-contacts.scss";
@import "components/_list-partners-alt.scss";
@import "components/_list-partners.scss";
@import "components/_list-services.scss";
@import "components/_logo.scss";
@import "components/_main-container.scss";
@import "components/_main.scss";
@import "components/_nav-filter.scss";
@import "components/_nav-trigger.scss";
@import "components/_nav.scss";
@import "components/_popup.scss";
@import "components/_radio.scss";
@import "components/_section-article.scss";
@import "components/_section-articles.scss";
@import "components/_section-bg.scss";
@import "components/_section-blog.scss";
@import "components/_section-default.scss";
@import "components/_section-images-ob.scss";
@import "components/_section-intro.scss";
@import "components/_section-simple.scss";
@import "components/_section-stats.scss";
@import "components/_section.scss";
@import "components/_select.scss";
@import "components/_service.scss";
@import "components/_shell.scss";
@import "components/_slider.scss";
@import "components/_stat.scss";
@import "components/_testimonial.scss";
@import "components/_wrapper-outer.scss";
@import "components/_wrapper.scss";

/* ------------------------------------------------------------ *\
	Overwrites
\* ------------------------------------------------------------ */

@import 'overwrites/mfp.scss';
@import 'overwrites/nav-slider.scss';


