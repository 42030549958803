/* ------------------------------------------------------------ *\
    Fonts
\* ------------------------------------------------------------ */

@font-face {
	font-family: 'Plantin';
	src: url('../fonts/Plantin-Semibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Plantin';
	src: url('../fonts/Plantin-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Plantin';
	src: url('../fonts/Plantin-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Plantin';
	src: url('../fonts/Plantin-SemiboldItalic.woff') format('woff');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato-Semibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}


@font-face {
    font-family: 'Domaine';
    src: url('../fonts/DomaineText-Regular.woff2') format('woff2'),
        url('../fonts/DomaineText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

