/* ------------------------------------------------------------ *\
	Article
\* ------------------------------------------------------------ */

.article {
	.article__head {
		max-width: 624px;
		margin: 0 auto 63px;

		@include breakpoint-down(large-desktop) {
			max-width: 660px;
		}

		@include breakpoint-down(tablet) {
			max-width: 83%;
			margin: 0 auto 30px;
		}

		@include breakpoint-down(mobile) {
			margin: 0 0 45px;
		}
	}

	.article__title {
		font-size: 55px;
		font-weight: 300;
		line-height: 1.16;

		@include breakpoint-down(tablet) {
			font-size: 30px;
		}

		@include breakpoint-down(mobile) {
			font-size: 28px;
			line-height: 1.21;
		}
	}

	.article__subtitle {
		font-weight: 300;
		line-height: 1.33;
		margin-bottom: 33px;

		@include breakpoint-down(tablet) {
			margin-bottom: 15px;
		}

		@include breakpoint-down(mobile) {
			line-height: 1.5;
			margin-bottom: 31px;
		}
	}

	.article__entry p {
		font-size: 16px;
		line-height: 1.75;
		@include breakpoint-down(mobile) {
			font-size: 14px;
			line-height: 1.78;
			margin-bottom: 0;
		}
	}

	.article__entry p + p {
		@include breakpoint-down(mobile) {
			margin-top: 34px;
		}
	}

	.article__group {
		max-width: 624px;
		margin: auto;

		@include breakpoint-down(large-desktop) {
			max-width: 660px;
		}

		@include breakpoint-down(tablet) {
			max-width: 83%;
		}

		@include breakpoint-down(mobile) {
			margin: 0;
			max-width: 92%;
		}
	}

	.article__group--medium {
		max-width: 816px;

		@include breakpoint-down(large-desktop) {
			max-width: 740px;
		}
	}

	.article__group--small {
		max-width: 528px;

		@include breakpoint-down(large-desktop) {
			max-width: 506px;
		}

		@include breakpoint-down(mobile) {
			margin: auto;
			max-width: 88%;
		}
	}

	.article__group--large {
		max-width: 100%;
		@include breakpoint-down(large-desktop) {
			max-width: 974px;
		}
	}

	.article__images {
		display: flex;
		justify-content: space-between;

		@include breakpoint-down(mobile) {
			flex-direction: column;
		}
	}

	.article__image + .article__image {
		margin-top: 117px;

		@include breakpoint-down(large-desktop) {
			margin-top: 128px;
		}

		@include breakpoint-down(mobile) {
			margin-top: 40px;
		}
	}

	.article__image img {
		width: 100%;
	}

	.article__image--half {
		@include breakpoint-down(large-desktop) {
			width: 48%;
		}

		@include breakpoint-down(mobile) {
			width: 100%;
		}
	}

	.article__image--bg {
		padding-bottom: 56%;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		@include breakpoint-down(mobile) {
			padding-bottom: 83%;
			position: relative;
			width: 100vw;
			left: 50%;
			right: 50%;
			margin-left: -50vw;
			margin-right: -50vw;
		}
	}

	.article__group + .article__group {
		margin-top: 64px;

		@include breakpoint-down(tablet) {
			margin-top: 30px;
		}

		@include breakpoint-down(tablet) {
			margin-top: 45px;
		}
	}

	.article__body ul li {
		margin-left: 40px;
	}

}
