/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */

.section {
	min-height: 800px;
	display: flex;
	flex-direction: row;

	.section__shell {
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: inherit;
	}

	.section__head {
		text-align: center;
		margin-bottom: 67px;
		@include breakpoint-down(tablet) {
			margin-bottom: 29px;
		}
	}

	.section__title {
		font-weight: 300;
		@include breakpoint-down(tablet) {
			font-size: 28px;
		}

		@include breakpoint-down(mobile) {
			font-size: 20px;
		}
	}

	.section__highlight-ico-1 {
		top: 75%;
	}

	.section__images {
		display: flex;
		justify-content: center;
		padding: 150px 0 140px;

		@include breakpoint-down(large-desktop) {
			padding: 150px 0 130px;
		}

		@include breakpoint-down(tablet) {
			padding: 93px 0 95px;
		}

		@include breakpoint-down(mobile) {
			flex-direction: column;
			padding: 37px 0 98px;
		}
	}

	.section__image {
		@include breakpoint-down(mobile) {
			max-width: 265px;
		}
	}

	.section__image + .section__image {
		margin-left: 80px;
		margin-top: 150px;
		@include breakpoint-down(tablet) {
			margin-top: 70px;
			margin-left: 38px;
		}

		@include breakpoint-down(mobile) {
			margin-left: 69px;
			margin-top: 40px;
		}
	}

	@include breakpoint-down(large-desktop) {
		min-height: 700px;
	}

	@include breakpoint-down(tablet) {
		min-height: auto;
	}
}

/* Section Alt */

.section--alt {
	@include breakpoint-down(tablet) {
		padding: 112px 0 120px;
	}

	@include breakpoint-down(mobile) {
		padding: 72px 0 83px;
	}
}

.section--small {
	min-height: 0;
	background: #f7f4f1;
	margin-bottom: 100px;
	padding: 50px 0 !important;

	.section__head--alt {
		margin-bottom: 0;
	}

	.section__head--alt h3 {
		font-style: italic;
	}
}
