/* ------------------------------------------------------------ *\
	Articles Blog
\* ------------------------------------------------------------ */

.articles-blog {
	display: flex;
	flex-wrap: wrap;
	margin: -24px -20px -43px;
	// min-height: 1301.5px !important;

	@include breakpoint-down(mobile) {
		display: block;
	}
}