/* ------------------------------------------------------------ *\
	Icons
\* ------------------------------------------------------------ */

.ico-arrow {
	position: absolute;
	right: 15px;
	top: -43px;

	@include breakpoint-down(large-desktop) {
		right: 45px;
	}

	@include breakpoint-down(small-desktop) {
		right: 0;
	}

	@include breakpoint-down(tablet) {
		width: 32px;
		height: 45px;
		right: 170px;
		top: -33px;
	}

	@include breakpoint-down(mobile) {
		right: 20px;
		top: -47px;
	}
}

.ico-mouse {
	position: absolute;
	bottom: -28px;
	left: -159px;
	width: 155px;
	height: 85px;

	@include breakpoint-down(tablet) {
		width: 99px;
		height: 54px;
		bottom: -32px;
		left: -89px;
	}

	@include breakpoint-down(mobile) {
		left: 100%;
		transform: scale(-1, 1);
	}
}

.ico-bulb {
	position: absolute;
	top: -40px;
	right: 1px;

	@include breakpoint-down(tablet) {
		width: 38px;
		height: 53px;
		top: -34px;
		right: 9px;
	}
}

.ico-arrow-small {
	display: inline-block;
	vertical-align: middle;
}

.logo-oliver-bonas {
	width: 155px;
	height: 14px;
	display: inline-block;
	img {
		display: block;
		width: 100%;
		height: 100%;
	}

	@include breakpoint-down(tablet) {
		width: 109px;
		height: 10px;
	}

	@include breakpoint-down(mobile) {
		width: 121px;
		height: 11px;
	}
}

.ap-logo {
	width: 187px;
	height: 32px;
	display: inline-block;
	img {
		display: block;
		width: 100%;
		height: 100%;
	}

	@include breakpoint-down(tablet) {
		width: 131px;
		height: 23px;
	}

	@include breakpoint-down(mobile) {
		width: 146px;
		height: 25px;
	}
}

.topps-logo {
	width: 130px;
	height: 28px;
	display: inline-block;
	img {
		display: block;
		width: 100%;
		height: 100%;
	}
	@include breakpoint-down(tablet) {
		width: 91px;
		height: 19px;
	}

	@include breakpoint-down(mobile) {
		width: 108px;
		height: 23px;
	}
}

.logo-microsoft-dynamics {
	margin-top: -13px;
}

.scouts-logo {
	width: 127px;
	height: 36px;
	display: inline-block;
	margin-top: -8px;
	img {
		display: block;
		width: 100%;
		height: 100%;
	}

	@include breakpoint-down(tablet) {
		width: 89px;
		height: 25px;
	}
}

.john-lobb-logo {
	width: 140px;
	height: 18px;
	display: inline-block;
	img {
		display: block;
		width: 100%;
		height: 100%;
	}

	@include breakpoint-down(tablet) {
		width: 98px;
		height: 13px;
	}
}

.lsa-logo {
	width: 91px;
	height: 50px;
	display: inline-block;
	img {
		display: block;
		width: 100%;
		height: 100%;
	}

	@include breakpoint-down(tablet) {
		width: 64px;
		height: 34px;
	}

	@include breakpoint-down(mobile) {
		width: 71px;
		height: 38px;
	}
}

.creed-logo {
	width: 112px;
	height: 44px;
	display: inline-block;
	img {
		display: block;
		width: 100%;
		height: 100%;
	}

	@include breakpoint-down(tablet) {
		width: 78px;
		height: 31px;
	}

	@include breakpoint-down(mobile) {
		width: 93px;
		height: 37px;
	}
}

.logo-biscuiteers {
	width: 172px;
	height: 23px;
	display: inline-block;
	img {
		display: block;
		width: 100%;
		height: 100%;
	}

	@include breakpoint-down(tablet) {
		width: 120px;
		height: 15px;
	}

	@include breakpoint-down(mobile) {
		width: 132px;
		height: 17px;
	}
}

.millies-logo {
	width: 127px;
	height: 58px;
	display: inline-block;
	img {
		display: block;
		width: 100%;
		height: 100%;
	}

	@include breakpoint-down(tablet) {
		width: 89px;
		height: 40px;
	}

	@include breakpoint-down(mobile) {
		width: 106px;
		height: 48px;
	}
}

.pooky-logo {
	width: 136px;
	height: 55px;
	display: inline-block;
	img {
		display: block;
		width: 100%;
		height: 100%;
	}

	@include breakpoint-down(tablet) {
		width: 95px;
		height: 39px;
	}
}

.daisy-logo {
	width: 124px;
	height: 44px;
	display: inline-block;
	img {
		display: block;
		width: 100%;
		height: 100%;
	}

	@include breakpoint-down(tablet) {
		width: 86px;
		height: 31px;
	}
}

.em-logo {
	width: 160px;
	height: 32px;
	display: inline-block;
	img {
		display: block;
		width: 100%;
		height: 100%;
	}

	@include breakpoint-down(tablet) {
		width: 112px;
		height: 23px;
	}
}

.logo-oliver-bonas-large {
	width: 200px;
	height: 19px;
	display: inline-block;
	img {
		display: block;
		width: 100%;
		height: 100%;
	}

	@include breakpoint-down(tablet) {
		width: 160px;
		height: 16px;
	}
}

.topps-logo-large {
	width: 178px;
	height: 37px;
	display: inline-block;
	img {
		display: block;
		width: 100%;
		height: 100%;
	}

	@include breakpoint-down(tablet) {
		width: 141px;
		height: 31px;
	}
}

.topps-logo-small {
	width: 135px;
	height: 28px;
	display: inline-block;
	img {
		display: block;
		width: 100%;
		height: 100%;
	}
}

.logo-oliver-bonas-small {
	width: 160px;
	height: 15px;
	display: inline-block;
	img {
		display: block;
		width: 100%;
		height: 100%;
	}
	@include breakpoint-down(mobile) {
		width: 140px;
		height: 14px;
	}
}

.pooky-logo-small {
	width: 99px;
	height: 40px;
	display: inline-block;
	img {
		display: block;
		width: 100%;
		height: 100%;
	}
}

.creed-logo-small {
	width: 93px;
	height: 37px;
	display: inline-block;
	img {
		display: block;
		width: 100%;
		height: 100%;
	}
}
