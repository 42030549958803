/* ------------------------------------------------------------ *\
	Section Simple
\* ------------------------------------------------------------ */

.section-simple {
	min-height: 500px;
	padding: 48px 0 200px;

	.section__cols {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-flow: row wrap;
	}

	.section__col {
		max-width: 100%;
		flex: 1;
	}

	.section__col--size1 {
		max-width: 38%;
		flex: 0 0 38%;

		@include breakpoint-down(tablet) {
			max-width: 100%;
			flex: 0 0 100%;
		}
	}

	.section__col--size2 {
		max-width: 41%;
		flex: 0 0 41%;

		@include breakpoint-down(tablet) {
			max-width: 100%;
			flex: 0 0 100%;
			display: flex;
			justify-content: space-between;
		}

		@include breakpoint-down(mobile) {
			flex-direction: column;
		}
	}

	.section__col + .section__col {
		@include breakpoint-down(tablet) {
			margin-top: 55px;
		}
	}

	.section__list {
		@include breakpoint-down(tablet) {
			max-width: 48%;
		}

		@include breakpoint-down(mobile) {
			max-width: 100%;
		}
	}

	.section__list + .section__list {
		margin-top: 40px;
		@include breakpoint-down(tablet) {
			margin-top: 0;
		}

		@include breakpoint-down(mobile) {
			margin-top: 30px;
		}
	}

	.section__head {
		margin-bottom: 65px;

		@include breakpoint-down(mobile) {
			margin-bottom: 32px;
		}
	}

	.section__head--small {
		max-width: 702px;
		margin: 0 auto 65px;
		@include breakpoint-down(large-desktop) {
			margin: 0 auto 82px;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 55px;
		}
	}

	.section__title {
		font-weight: 300;
		line-height: 1.31;

		@include breakpoint-down(tablet) {
			font-size: 32px;
		}

		@include breakpoint-down(mobile) {
			font-size: 24px;
		}
	}

	.section__title {
		@include breakpoint-down(mobile) {
			font-size: 20px;
			line-height: 1.4;
		}
	}

	.section__subtitle {
		margin-bottom: 14px;
		font-weight: 600;
		font-family: $ff-sans-serif;
		text-transform: uppercase;
	}


	.section__head--alt {
		max-width: 890px;
		padding: 28px 0;
		margin: 0 auto 282px;

		@include breakpoint-down(tablet) {
			margin-bottom: 97px;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 106px;
		}

		.section__subtitle {
			margin-bottom: 5px;

			@include breakpoint-down(tablet) {
				margin-bottom: 1px;
			}

			@include breakpoint-down(mobile) {
				margin-bottom: 7px;
			}
		}

		.section__title {
			@include breakpoint-down(tablet) {
				font-size: 28px;
				line-height: 1.5;
				padding: 0 25px;
			}

			@include breakpoint-down(mobile) {
				padding: 0 2px;
				font-size: 24px;
				line-height: 1.33;
			}

		}
	}

	.section__images {
		display: flex;
		justify-content: center;
		align-items: center;

		@include breakpoint-down(tablet) {
			justify-content: flex-start;
		}

		/* @include breakpoint-down(mobile) {
			justify-content: center;
		} */
	}

	.section__image {
		position: relative;
	}

	.section__image img {
		width: 100%;
	}

	.section__image + .section__image {
		margin-left: 90px;
		@include breakpoint-down(large-desktop) {
			margin-left: 128px;
		}

		@include breakpoint-down(tablet) {
			margin-left: 29px;
		}

		@include breakpoint-down(mobile) {
			margin-left: 40px;
		}
	}

	.section__image--mobile {
		@include breakpoint-down(mobile) {
			display: flex;
			justify-content: center;
		}
	}

	.section__image--mobile img {
		@include breakpoint-down(mobile) {
			width: 132px;
			height: 258px;
		}
	}

	.section__image-outer {
		position: absolute;
		left: 100%;
		top: 0;
		bottom: 0;
		margin-left: 20px;
		width: 150px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-direction: column;

		@include breakpoint-down(large-desktop) {
			margin-left: 11px;
		}

		@include breakpoint-down(mobile) {
			position: static;
		}
	}

	.section__info span {
		display: block;
		letter-spacing: 1px;
		font: 400 21px/1 $ff-nanum-pen;
		margin-left: 29px;

		@include breakpoint-down(mobile) {
			font-size: 16px;
			margin-left: 13px;
		}
	}

	.section__info--top {
		margin-top: 92px;
		@include breakpoint-down(mobile) {
			margin-top: 55px;
		}
	}

	.section__info--bottom {
		margin-bottom: 163px;

		@include breakpoint-down(large-desktop) {
			margin-bottom: 23px;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 43px;
		}
	}

	.section__info--bottom .section__icon img {
		transform: scale(1, -1);

		@include breakpoint-down(large-desktop) {
			transform: scale(1, 1);
		}

		@include breakpoint-down(mobile) {
			transform: scale(1, -1);
		}
	}

	.section__info--bottom .section__icon {
		@include breakpoint-down(large-desktop) {
			width: 91px;
			height: 153px;
		}

		@include breakpoint-down(mobile) {
			width: 50px;
			height: 47px;
		}
	}

	.section__info--top span {
		margin-bottom: -13px;

		@include breakpoint-down(mobile) {
			margin-bottom: -5px;
		}
	}

	.section__info--bottom span {
		margin-top: -16px;
		@include breakpoint-down(large-desktop) {
			margin-top: 4px;
		}

		@include breakpoint-down(mobile) {
			margin-top: -11px;
		}
	}

	.section__icon {
		width: 67px;
		height: 69px;
		display: inline-block;
		@include breakpoint-down(mobile) {
			width: 50px;
			height: 47px;
		}
	}

	.section__icon img {
		width: 100%;
		height: 100%;
		display: block;
	}

	.section__icon--arrow-2 {
		display: none !important;

		@include breakpoint-down(large-desktop) {
			display: block !important;
		}

		@include breakpoint-down(mobile) {
			display: none !important;
		}
	}

	.section__icon--arrow-1 {
		@include breakpoint-down(large-desktop) {
			display: none !important;
		}

		@include breakpoint-down(mobile) {
			display: block !important;
		}
	}

	.section__group {
		min-height: 700px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-flow: row wrap;
		@include breakpoint-down(small-desktop) {
			min-height: auto;
		}
	}

	.section__group + .section__group {
		@include breakpoint-down(tablet) {
			margin-top: 175px;
		}

		@include breakpoint-down(mobile) {
			margin-top: 40px;
		}
	}

	.section__lists {
		min-width: 565px;
		width: 100%;
		margin-right: 0;

		@include breakpoint-down(large-desktop) {
			min-width: 41%;
		}

		@include breakpoint-down(tablet) {
			padding-top: 73px;
		}

		@include breakpoint-down(mobile) {
			padding-top: 44px;
		}

		h5 {
			@include breakpoint-down(tablet) {
				font-size: 18px;
				margin-bottom: 27px;
			}

			@include breakpoint-down(mobile) {
				font-size: 16px;
			}

		}

		.section__list {
			@include breakpoint-down(tablet) {
				max-width: 100%;
			}

			li {
				@include breakpoint-down(tablet) {
					width: 30%;
					margin-right: 0;
				}
			}
		}

		.section__list + .section__list {
			@include breakpoint-down(tablet) {
				margin-top: 50px;
			}
		}

	}

	.section__shell--flex {
		min-height: inherit;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.section__shell {
		@include breakpoint-down(large-desktop) {
			max-width: 1185px;
		}

		@include breakpoint-down(tablet) {
			padding-left: 69px;
			padding-right: 69px;
		}

		@include breakpoint-down(mobile) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	@include breakpoint-down(large-desktop) {
		padding-bottom: 140px;
	}

	@include breakpoint-down(tablet) {
		min-height: auto;
		padding-bottom: 43px;
	}

	@include breakpoint-down(mobile) {
		padding: 43px 0;
	}
}

.section-simple--small-padding {
	padding: 48px 0;
	display: flex;
	flex-direction: row;

	.section__shell {
		@include breakpoint-down(tablet) {
			padding-left: 34px;
			padding-right: 34px;
		}
	}
	@include breakpoint-down(tablet) {
		padding: 108px 0 70px;
	}

	@include breakpoint-down(mobile) {
		padding: 56px 0 34px;
	}
}

.section-simple--no-padding {
	padding: 0;

	.section__head {
		margin-bottom: 0;
		padding: 83px 0;
		max-width: 510px;

		@include breakpoint-down(large-desktop) {
			padding: 193px 0 127px;
		}

		@include breakpoint-down(tablet) {
			padding: 163px 0 70px;
		}

		@include breakpoint-down(mobile) {
			padding: 42px 0 32px;
		}
	}

	.section__shell {
		@include breakpoint-down(large-desktop) {
			max-width: 1272px;
		}
	}
}

.section-simple--large-padding {
	padding: 154px 0 50px;

	@include breakpoint-down(tablet) {
		padding-top: 75px;
	}

	@include breakpoint-down(mobile) {
		padding-top: 51px;
	}

	.section__head--alt {
		margin-bottom: 184px;

		@include breakpoint-down(tablet) {
			margin-bottom: 122px;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 155px;
		}

		.section__title {
			@include breakpoint-down(tablet) {
				padding: 0;
			}
		}
	}

	.article-single--alt .article__content {
		margin-top: 126px;
	}
}

.section-simple--rhs {
	padding-bottom: 183px;

	@include breakpoint-down(tablet) {
		padding: 118px 0 94px;
	}

	@include breakpoint-down(mobile) {
		padding: 85px 0 138px;
	}

	.section__head--alt {
		margin-bottom: 173px;

		@include breakpoint-down(tablet) {
			padding: 0 25px;
			margin-bottom: 130px;
		}

		@include breakpoint-down(mobile) {
			padding: 0 8px;
		}
	}

	.article-single--alt {
		padding-left: 70px;

		.article__content {
			max-width: 357px;
			margin: 135px -29px 0 0;

			@include breakpoint-down(large-desktop) {
				margin-right: 0;
			}

			@include breakpoint-down(tablet) {
				max-width: 510px !important;
				margin: 0 auto  !important;
			}

			h4 {
				margin-bottom: 28px;

				@include breakpoint-down(tablet) {
					margin-bottom: 23px;
				}
			}

		}
	}
}

.section-simple--topps {
	padding-bottom: 93px;

	@include breakpoint-down(tablet) {
		padding: 34px 0 36px;
	}

	@include breakpoint-down(mobile) {
		padding: 4px 0 104px;
	}

	.section__head--alt {
		margin-bottom: 256px;

		@include breakpoint-down(tablet) {
			padding: 0 25px;
			margin-bottom: 123px;
		}

		@include breakpoint-down(mobile) {
			padding: 0 7px;
			margin-bottom: 134px;
		}
	}

	.article-single {
	    margin-bottom: 160px;

	    @include breakpoint-down(tablet) {
	    	margin-bottom: 70px;
	    }

	    @include breakpoint-down(mobile) {
	    	margin-bottom: 23px;
	    }


	    h4 {
	    	@include breakpoint-down(tablet) {
	    		max-width: 515px;
	    	}
	    }
	}

	.section__lists {
		@include breakpoint-down(mobile) {
			padding-top: 105px;
		}
	}
}

.section-simple--vashi {
	padding-bottom: 298px;

	@include breakpoint-down(tablet) {
		padding: 10px 0 113px;
	}

	@include breakpoint-down(mobile) {
		padding: 0 0 120px;
	}

	.section__head--alt {
		@include breakpoint-down(tablet) {
			margin-bottom: 120px;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 105px;
		}
	}


	.article-single {
		align-items: flex-start;

		.article__content {
			margin: 111px 78px 0 0;

			@include breakpoint-down(tablet) {
				max-width: 515px !important;
				margin: 0 auto !important;
			}
		}

		h4 {
			margin-bottom: 33px;

			@include breakpoint-down(tablet) {
				margin-bottom: 20px;
			}
		}
	}
}

.section-simple--no-padding-mobile {
	@include breakpoint-down(mobile) {
		padding: 0;
	}
}

.section-quote {
	padding: 0 !important;
}
