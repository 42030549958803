/* ------------------------------------------------------------ *\
	Block Project
\* ------------------------------------------------------------ */

.block-project {
	position: relative;
	& > a {
		position: absolute;
		top: 0;
		bottom: 0;
		display: block;
		left: 0;
		right: 0;
		z-index: 5;
	}
	.block__image {
		padding-bottom: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		margin-bottom: 22px;
		@include breakpoint-down(mobile) {
			margin-bottom: 19px;
		}
	}

	.block__foot {
		text-align: center;
		position: relative;
		padding-bottom: 52px;

		@include breakpoint-down(mobile) {
			padding-bottom: 0;
		}
	}

	.block__info {
		color: $c-pink;
		letter-spacing: 0.5px;
		position: absolute;
		bottom: -10px;
		left: 0;
		right: 0;
		text-align: center;
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.3s, visibility 0.3s, bottom 0.3s;

		@include breakpoint-down(mobile) {
			display: none;
		}
	}

	&:hover .block__info {
		bottom: 0;
		opacity: 1;
		visibility: visible;
	}
}

.block-project--alt {
	margin-top: 120px;
	@include breakpoint-down(tablet) {
		margin-top: 70px;
	}

	@include breakpoint-down(mobile) {
		margin-top: 32px;
	}
}

.block-project--slider {
	.block__partner {
		min-height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.block__image {
		margin-bottom: 14px;

		@include breakpoint-down(mobile) {
			margin-bottom: 6px;
		}
	}
}
