/* ------------------------------------------------------------ *\
	Article Small
\* ------------------------------------------------------------ */

.article-small {
	.article__title {
		font-weight: 300;
		line-height: 1.31;

		@include breakpoint-down(mobile) {
			font-size: 24px;
			line-height: 1.42;
		}
	}

	.article__head {
		margin-bottom: 27px;

		@include breakpoint-down(tablet) {
			max-width: 320px;
			margin-bottom: 15px;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 23px;
		}
	}

	.article__entry {
		max-width: 384px;

		@include breakpoint-down(tablet) {
			max-width: 303px;
		}
	}

	.article__foot {
		margin-top: 54px;
		@include breakpoint-down(tablet) {
			max-width: 100%;
			margin-top: 0;
		}

		@include breakpoint-down(mobile) {
			margin-top: 20px;
		}
	}

	@include breakpoint-down(tablet) {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
}

/* ------------------------------------------------------------ *\
	Article Small Alt
\* ------------------------------------------------------------ */

.article-small--alt {
	.article__head {
		@include breakpoint-down(tablet) {
			max-width: 265px;
		}

		@include breakpoint-down(mobile) {
			max-width: 100%;
		}
	}

	.article__entry {
		@include breakpoint-down(mobile) {
			max-width: 100%;
		}
	}

	.article__title {
		@include breakpoint-down(tablet) {
			font-size: 24px;
			line-height: 1.17; 
		}
	}
	.article__foot {
		@include breakpoint-down(tablet) {
			max-width: 303px;
			width: 100%;
			margin-left: auto;
			margin-top: 37px;   
		}

		@include breakpoint-down(mobile) {
			margin-left: 0;
			margin-top: 46px;  
		}

	}
}

.article-small--topps {
	.article__title {
		max-width: 500px;
		
		@include breakpoint-down(tablet) {
			display: none;
		}

		@include breakpoint-down(mobile) {
			display: block;
		}
	}

	.article__entry {
		max-width: 460px;

		@include breakpoint-down(tablet) {
			max-width: 100%;
			display: flex;
			flex-wrap: wrap;
		}

		@include breakpoint-down(mobile) {
			display: block;
		}

		.article__title {
			@include breakpoint-down(tablet) {
				display: block;
				width: 50%;
				font-size: 22px;
				line-height: 1.27; 
				order: -4;
				max-width: 270px;
				margin-right: 15px;
			}

			@include breakpoint-down(mobile) {
				display: none;
			}
		}

		p {
			@include breakpoint-down(tablet) {
				width: 50%;
				max-width: 285px;
			}

			@include breakpoint-down(mobile) {
				width: 100%;
				max-width: 100%;
			}

			&:nth-child(2) {
				@include breakpoint-down(tablet) {
					order: -2;
				}
			}
		}

		p + p {
			@include breakpoint-down(tablet) {
				margin-left: 40px; 
			}

			@include breakpoint-down(mobile) {
				margin-left: 0; 
			}
		} 
	}
}

.article-small--vashi {
	.article__head {
		max-width: 330px;
	}

	.article__entry {
		max-width: 420px;
		
		@include breakpoint-down(tablet) {
			max-width: 303px;
		}

	}
}