/* ------------------------------------------------------------ *\
	Article Alt
\* ------------------------------------------------------------ */

.article-alt {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	min-height: inherit;
	flex-direction: row;

	.article__images,
	.article__body {
		width: 48%;
	}

	.article__title {
		font-weight: 300;
		@include breakpoint-down(mobile) {
			line-height: 1.4;
		}
	}

	.article__head {
		margin-bottom: 22px;
		@include breakpoint-down(mobile) {
			margin-bottom: 15px;
		}
	}

	.article__inner {
		max-width: 336px;
	}

	.article__body {
		padding-left: 95px;
		/* min-height: 635px; */
		display: flex;
		align-items: center;
		@include breakpoint-down(large-desktop) {
			padding-left: 116px;
			width: 48%;
			min-height: 590px;
		}

		@include breakpoint-down(tablet) {
			width: 100%;
			min-height: auto;
			padding-left: 83px;
			max-width: 508px;
			margin: auto;
		}

		@include breakpoint-down(mobile) {
			padding-left: 0;
		}
	}

	.article__images {
		position: relative;
		min-height: 635px;

		@include breakpoint-down(large-desktop) {
			min-height: 590px;
			width: 51%;
		}

		@include breakpoint-down(tablet) {
			width: 100%;
			max-width: 508px;
			margin: auto;
			min-height: 480px;
			margin-bottom: 60px;
		}

		@include breakpoint-down(mobile) {
			min-height: 317px;
			margin-bottom: 44px;
		}
	}

	.article__images--small {
		min-height: 530px;
		@include breakpoint-down(large-desktop) {
			min-height: 400px;
		}
	}

	.article__images--small {
		@include breakpoint-down(mobile) {
			min-height: 335px;
		}
	}

	.article__body--small {
		@include breakpoint-down(large-desktop) {
			padding-left: 160px;
		}

		@include breakpoint-down(small-desktop) {
			padding-left: 0px;
		}

		@include breakpoint-down(tablet) {
			min-height: auto;
			padding-left: 83px;
		}

		@include breakpoint-down(mobile) {
			padding-left: 0;
		}
	}

	.article__image {
		position: absolute;
	}

	.article__image--1 {
		top: 114px;
		left: 0;
		width: 437px;
		height: 436px;

		@include breakpoint-down(large-desktop) {
			width: 405px;
			height: 405px;
		}

		@include breakpoint-down(tablet) {
			width: 330px;
			height: 330px;
			top: 86px;
		}

		@include breakpoint-down(mobile) {
			width: 217px;
			height: 217px;
			top: 57px;
		}
	}

	.article__image--2 {
		top: 0;
		right: -7px;
		width: 305px;
		height: 405px;

		@include breakpoint-down(large-desktop) {
			width: 283px;
			height: 376px;
			right: -9px;
			top: 6px;
		}

		@include breakpoint-down(tablet) {
			top: 0;
			width: 231px;
			height: 306px;
			right: 0;
		}

		@include breakpoint-down(mobile) {
			width: 152px;
			height: 202px;
		}
	}

	.article__image--3 {
		bottom: 0;
		right: 67px;
		width: 375px;
		height: 375px;

		@include breakpoint-down(large-desktop) {
			width: 348px;
			height: 348px;
			right: 59px;
			bottom: -7px;
		}

		@include breakpoint-down(tablet) {
			bottom: 0;
			right: 55px;
			width: 284px;
			height: 284px;
		}

		@include breakpoint-down(mobile) {
			width: 187px;
			height: 187px;
			right: 36px;
		}
	}

	.article__image--4 {
		top: 0;
		left: 0;
		width: 405px;
		height: 405px;

		@include breakpoint-down(large-desktop) {
			width: 306px;
			height: 305px;
			left: 70px;
		}

		@include breakpoint-down(small-desktop) {
			left: 0;
		}

		@include breakpoint-down(mobile) {
			display: none;
		}
	}

	.article__image--5 {
		top: 98px;
		right: 0;
		width: 576px;
		height: 432px;

		@include breakpoint-down(large-desktop) {
			width: 435px;
			height: 326px;
			right: 40px;
			top: 76px;
		}

		@include breakpoint-down(small-desktop) {
			right: 0;
		}

		@include breakpoint-down(mobile) {
			width: 335px;
			height: 335px;
			left: 0;
			right: 0;
			top: 0;
		}
	}

	.article__entry {
		@include breakpoint-down(mobile) {
			max-width: 310px;
		}
	}
}

/* Article Alt reverse */

.article-alt--reverse {
	flex-direction: row-reverse;
}
