/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

body {
	min-width: 320px;
	background: $c-white;
	font-family: $ff-sans-serif;
	font-weight: $font-weight-base;
	font-size: $font-size-base;
	line-height: $line-height-base;
	color: $c-base-1;

	&.is-fixed {
		position: fixed;
		height: 100vh;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		overflow-y: auto;
	}
}

a {
	color: inherit;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}

	&[href^='tel'] {
		text-decoration: none;
		color: $c-pink;
	}
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
	margin-bottom: calc(#{$line-height-base}em / 2);
	font-family: $ff-plantin;
}

.h1,
h1 {
	font-size: 96px;

	@include breakpoint-down(tablet) {
		font-size: 54px;
	}

	@include breakpoint-down(mobile) {
		font-size: 38px;
	}
}

.h2,
h2 {
	font-size: 54px;
	@include breakpoint-down(tablet) {
		font-size: 30px;
	}
}

.h3,
h3 {
	font-size: 32px;
	@include breakpoint-down(tablet) {
		font-size: 20px;
	}
}

.h4,
h4 {
	font-size: 24px;

	@include breakpoint-down(mobile) {
		font-size: 20px;
	}
}

.h5,
h5 {
	font-size: 20px;
	@include breakpoint-down(tablet) {
		font-size: 16px;
	}
}

.h6,
h6 {
	font-size: 14px;
}

p,
ul,
ol,
dl,
hr,
table,
blockquote {
	margin-bottom: #{$line-height-base}em;
}

h1[class],
h2[class],
h3[class],
h4[class],
h5[class],
h6[class],
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
table:last-child,
blockquote:last-child {
	margin-bottom: 0;
}

@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}
