/* ------------------------------------------------------------ *\
	Section articles
\* ------------------------------------------------------------ */

.section-articles {
	.section__head {
		margin-bottom: 94px;
		text-align: center;  

		@include breakpoint-down(tablet) {
			margin-bottom: 122px;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 60px;
		}
	}

	.section__head--rhs {
		@include breakpoint-down(tablet) {
			margin-bottom: 64px;
		}
	}

	.section__head--topps {
		margin-bottom:  75px;
		
		@include breakpoint-down(tablet) {
			margin-bottom: 64px;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 43px;
		}
	}

	.section__head--vashi {
		margin-bottom: 82px;

		@include breakpoint-down(mobile) {
			margin-bottom: 50px;
		}
	}

	h3 {
		@include breakpoint-down(tablet) {
			font-size: 32px;
		}

		@include breakpoint-down(mobile) {
			font-size: 24px;
		}

	}
}