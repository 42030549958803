/* ------------------------------------------------------------ *\
	Article Blog
\* ------------------------------------------------------------ */

.article-blog {
	width: 50%;
	padding: 24px 20px 43px;

	@include breakpoint-down(mobile) {
		width: 100%;
		padding: 0 20px 57px;
	}

	.article__image {
		margin-bottom: 20px; 
		width: 100%;

		@include breakpoint-down(mobile) {
			margin-bottom: 16px; 
		}
	}

	.article__image img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	
	.article__title {
		font-family: $ff-domaine; 
		line-height: 1.4;
		font-weight: 400; 	
		margin-bottom: 19px; 

		@include breakpoint-down(mobile) {
			line-height: 1.5; 
			margin-bottom: 16px; 
		}
	}

	.article__title a {
		text-decoration: none;
		transition: $t-duration;
	}

	.article__title a:hover {
		color: $c-pink;
	}

	h6 {
		font-family: $ff-sans-serif; 
		font-size: 16px;
		color: $c-light-gray-4;
		font-weight: 400; 
		margin-bottom: 28px; 

		@include breakpoint-down(mobile) {
			font-size: 14px;
			margin-bottom: 25px; 
		}
	}
}