/* ------------------------------------------------------------ *\
	Section Blog
\* ------------------------------------------------------------ */

.section-blog {
	padding: 143px 0 145px;
	position: relative; 

	@include breakpoint-down(mobile) {
		padding: 91px 0 108px;
	}

	&:before {
		content: '';
		width: 600px;
		height: 495px;
		background: url('/../images/svg/circle-5.svg');
		transform: rotate(-19deg) translate(-24%, -59%);
		position: absolute; 
		left: 0;
		top: 0;
		z-index: 1;

		@include breakpoint-down(mobile) {
			display: none;
		}
	}

	.highlight--6 .highlight__ico {
		top: 100%;
	}

	.section__title {
		margin-bottom: 49px; 
		font-family: $ff-domaine; 

		@include breakpoint-down(mobile) {
			margin-bottom: 25px; 
		}
	}

	.section__head {
		text-align: center; 
		margin-bottom: 51px; 
		padding: 0 20px;

		@include breakpoint-down(mobile) {
			padding: 0;
			margin-bottom: 25px; 
		}
	}

	.section__actions {
		margin-top: 69px; 
		text-align: center; 

		@include breakpoint-down(mobile) {
			margin-top: 63px; 
		}
	}

	.section__nav {
		max-width: 350px;
		height: 100%;
		position: relative;
		margin: 0 auto 20px;
		padding: 0 40px;

		@include breakpoint-down(mobile) {
			text-align: left;
			overflow-x: hidden; 
			padding: 0 60px 0 40px;
		}

		@include breakpoint-down(mobile-small) {
			padding: 0 27px 0 20px;
		}

		&:after {
			@include breakpoint-down(mobile) {
				content: '';
				width: 24px;
				height: 6px;
				background: url('/../images/svg/arrow-6.svg'); 
				background-repeat: no-repeat;	
				position: absolute;
				left: calc(100% - 24px);
				top: 50%;
				transform: translate(0, -50%); 
			}
		} 
	}

	.section__nav-inner {
		@include breakpoint-down(mobile) {
			overflow-x: hidden;
		}
	}

	.section__body {
		padding-top: 25px; 
	}

	&.hide-actions {
		.section__actions {
			display: none;
		}
	}
}