/* ------------------------------------------------------------ *\
	Section Default
\* ------------------------------------------------------------ */

.section-default {
	position: relative;
	padding: 174px 0 83px;
	text-align: center;

	.section__head {
		margin-bottom: 56px;
		@include breakpoint-down(large-desktop) {
			margin-bottom: 36px;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 42px;
		}
	}

	.section__body {
		position: relative;
	}

	.section__title {
		font-weight: 300;
		@include breakpoint-down(large-desktop) {
			font-size: 32px;
		}

		@include breakpoint-down(mobile) {
			font-size: 24px;
		}
	}

	@include breakpoint-down(large-desktop) {
		padding: 166px 0 83px;
	}

	@include breakpoint-down(tablet) {
		padding: 166px 0 46px;
	}

	@include breakpoint-down(mobile) {
		padding: 111px 0 46px;
	}

	&--ob {
		padding-top: 130px; 

		@include breakpoint-down(tablet) {
			padding-top: 105px; 
		}
	}
}

/* Section Default Alt */

.section-default--alt {
	.section__head {
		max-width: 684px;
		margin: auto;

		@include breakpoint-down(mobile) {
			max-width: 330px;
		}
	}

	.section__title {
		@include breakpoint-down(large-desktop) {
			font-size: 54px;
		}

		@include breakpoint-down(mobile) {
			font-size: 38px;
			line-height: 1.11;
		}
	}

	.section__outer {
		margin-top: 75px;
		margin-bottom: 60px;
		@include breakpoint-down(large-desktop) {
			margin-bottom: 73px;
			margin-left: 87px;
		}

		@include breakpoint-down(mobile) {
			margin-top: 34px;
			margin-bottom: 41px;
			margin-left: 100px;
		}
	}

	.section__outer span {
		font: 400 21px/1 $ff-nanum-pen;
		color: $c-pink;
		display: block;
		margin-bottom: 9px;

		@include breakpoint-down(mobile) {
			font-size: 16px;
			margin-bottom: 6px;
		}
	}

	.section__icon {
		margin-left: -10px;
		@include breakpoint-down(mobile) {
			display: inline-block;
			width: 28px;
			height: 36px;
		}
	}

	.section__icon img {
		@include breakpoint-down(mobile) {
			width: 100%;
			height: 100%;
			display: block;
		}
	}

	.section__body {
		@include breakpoint-down(tablet) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	@include breakpoint-down(large-desktop) {
		padding: 246px 0 166px;
	}

	@include breakpoint-down(tablet) {
		padding: 132px 0 119px;
	}
}

/* SEction Default Small */

.section-default--small {
	padding: 165px 0 0;
	height: calc(100vh - 65px);
	min-height: 950px;

	@include breakpoint-down(large-desktop) {
		min-height: 662px;
	}

	@include breakpoint-down(mobile) {
		padding: 108px 0 0;
		height: calc(100vh - 84px);
		min-height: 580px;
	}

	@include breakpoint-down(mobile-small) {
		min-height: 480px;
	}

	@media only screen and (min-device-width: 320px) and (max-device-width: 767px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
		min-height: 800px;
	}

	@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
		min-height: 700px;
	}

	@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
		min-height: 840px;
	}
}

/* Section default services */

.section-default--services {
	@include breakpoint-down(large-desktop) {
		padding: 120px 0;
	}
}
