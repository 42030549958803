/* ------------------------------------------------------------ *\
	List Contacts Alt
\* ------------------------------------------------------------ */

.list-contacts-alt {
	list-style-type: none;
	h5 {
		margin-bottom: 8px;

		@include breakpoint-down(mobile) {
			font-size: 20px;
		}
	}

	a {
		text-decoration: none;
		line-height: 1.71;
		transition: color 0.3s;
		&[href^='tel'] {
			text-decoration: none;
			color: $c-base-1;
		}

		&:hover {
			color: $c-pink;
		}
	}

	address {
		line-height: 1.71;
	}

	li {
		@include breakpoint-down(mobile) {
			width: 47%;
			margin-bottom: 46px;
		}
	}

	li + li {
		margin-top: 46px;
		@include breakpoint-down(mobile) {
			margin-top: 0;
		}
	}

	@include breakpoint-down(mobile) {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
}
