/* ------------------------------------------------------------ *\
	list basic
\* ------------------------------------------------------------ */

.list-basic {
	ul {
		display: flex;
		flex-wrap: wrap;
		list-style-type: none;
	}
	
	li {
		width: 20%;
		margin-bottom: 20px;
	}

	a {
		text-decoration: none;
	}

	&--alt {
		li {
			width: 33.33%
		}
	}
}