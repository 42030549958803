/* ------------------------------------------------------------ *\
	Magnific popup overites
\* ------------------------------------------------------------ */

.mfp-container {
	padding: 0;
}

.mfp-content {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.mfp-close {
	opacity: 1;
	width: 24px;
	height: 24px;
	line-height: 24px;
	font-size: 0;
	top: 45px;
	right: 103px;

	&:active {
		top: 45px;
	}

	@include breakpoint-down(large-desktop) {
		right: 44px;
	}

	@include breakpoint-down(tablet) {
		top: 21px;
		right: 25px;
		&:active {
			top: 25px;
		}
	}

	@include breakpoint-down(mobile) {
		width: 14px;
		height: 14px;
		top: 17px;
		right: 25px;

		&:active {
			top: 17px;
		}
	}
}
