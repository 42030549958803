/* ------------------------------------------------------------ *\
	Section Stats
\* ------------------------------------------------------------ */

.section-stats {
	@include breakpoint-down(tablet) {
		padding-bottom: 105px; 
	}

	@include breakpoint-down(mobile) {
		padding: 101px 0 61px;
	}

	.section__head {
		max-width: 545px;
		width: 100%;
		margin: 0 auto 65px;

		@include breakpoint-down(mobile) {
			margin-bottom: 48px;
		}
	}

	.section__head h3 {
		@include breakpoint-down(tablet) {
			font-size: 28px;
			line-height: 1;
			max-width: 386px;
			margin: 0 auto;
		}

		@include breakpoint-down(mobile) {
			font-size: 24px;
			line-height: 1.17; 
			padding: 0 20px;
		}

	}

	.section__shell {
		@include breakpoint-down(mobile) {
			padding-left: 0;
			padding-right: 0; 
		}
	}

	&--height-auto {
		min-height: auto;
		padding: 49px 0 32px;

		@include breakpoint-down(tablet) {
			padding-top: 80px; 
		}

		@include breakpoint-down(mobile) {
			padding-top: 72px; 
		}

		.section__head .section__title {
			@include breakpoint-down(tablet) {
				font-size: 28px;
			}

			@include breakpoint-down(mobile) {
				font-size: 24px;
			}
		}
	}

	&--rhs {
		padding-bottom: 7px; 

		@include breakpoint-down(mobile) {
			padding-top: 84px; 
		}
	}

	&--topps {
		padding-bottom: 22px;

		.section__head {
			margin-bottom: 47px;

			@include breakpoint-down(tablet) {
				margin-bottom: 65px;
			}

			@include breakpoint-down(mobile) {
				margin-bottom: 46px;
			}
		} 

		@include breakpoint-down(tablet) {
			padding: 118px 0 113px; 
		}

		@include breakpoint-down(mobile) {
			padding: 84px 0 86px; 
		}
	}
}