/* ------------------------------------------------------------ *\
	Slider
\* ------------------------------------------------------------ */

.slider {
	/* margin-left: 120px; */
	.slider__slide {
		transform: scale(0.8);
		transition: transform 0.3s;
		width: 29.5%;
		@include breakpoint-down(large-desktop) {
			width: 30.3%;
		}

		@include breakpoint-down(tablet) {
			width: 58.3%;
		}

		@include breakpoint-down(mobile) {
			width: 75%;
		}
	}

	/* .slider__clip.is-active {
		overflow: visible;
	} */

	.slider__actions {
		position: absolute;
		right: 0;
		left: 0;
		top: -33px;
		width: 100%;
		@include breakpoint-down(mobile) {
			top: -26px;
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		background-image: url(../images/svg/arrow-right-slider.svg);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		width: 58px;
		height: 11px;
		margin-bottom: 0;
		position: absolute;
		top: 0;
		margin-top: 0;
		transition: opacity 0.3s;
		&:after {
			display: none;
		}

		&:hover {
			opacity: 0.7;
		}

		@include breakpoint-down(mobile) {
			width: 38px;
			height: 7px;
		}
	}

	.swiper-button-next {
		right: 110px;

		@include breakpoint-down(large-desktop) {
			right: 80px;
		}

		@include breakpoint-down(mobile) {
			right: 18px;
		}
	}

	.swiper-button-prev {
		transform: scale(-1, 1);
		left: 110px;

		@include breakpoint-down(large-desktop) {
			left: 80px;
		}

		@include breakpoint-down(mobile) {
			left: 18px;
		}
	}

	.swiper-slide-active {
		transform: scale(1);
	}

	/* @include breakpoint-down(large-desktop) {
		margin-left: 40px;
	}
	
	@include breakpoint-down(tablet) {
		margin-left: 34px;
	}
	
	@include breakpoint-down(mobile) {
		margin-left: 20px;
	} */
}
