/* ------------------------------------------------------------ *\
	Block
\* ------------------------------------------------------------ */

.blocks {
	padding-bottom: 86px;
	.blocks__items {
		display: flex;
		justify-content: center;

		@include breakpoint-down(mobile) {
			flex-direction: column;
		}
	}

	.blocks__item {
		width: 47%;
		@include breakpoint-down(mobile) {
			width: 100%;
		}
	}

	.blocks__item + .blocks__item {
		margin-left: 80px;

		@include breakpoint-down(tablet) {
			margin-left: 40px;
		}

		@include breakpoint-down(mobile) {
			margin-left: 0;
		}
	}

	@include breakpoint-down(tablet) {
		padding-bottom: 0;
	}
}

/* Blocks Alt */

.blocks--alt {
	@include breakpoint-down(mobile) {
		padding-bottom: 100px;
	}
}

.block {
	position: relative;
	transition: opacity 0.3s;
	background-color: $c-pearl;
	padding-bottom: 100%;
	position: relative;
	& > a {
		position: absolute;
		top: 0;
		bottom: 0;
		display: block;
		left: 0;
		right: 0;
		z-index: 5;
	}

	.block__inner {
		position: absolute;
		bottom: 160px;
		right: 75px;
		text-align: right;

		@include breakpoint-down(small-desktop) {
			right: 20px;
		}

		@include breakpoint-down(tablet) {
			right: 48px;
			bottom: 120px;
		}

		@include breakpoint-down(mobile) {
			right: auto;
			left: 37px;
			text-align: left;
			bottom: 83px;
		}
	}

	.block__title {
		font-weight: 300;
		@include breakpoint-down(tablet) {
			letter-spacing: 0.5px;
		}
	}

	.block__text {
		position: absolute;
		top: -81px;
		left: -8px;

		@include breakpoint-down(tablet) {
			top: -53px;
			left: 14px;
		}

		@include breakpoint-down(mobile) {
			left: auto;
			right: -24px;
			top: -49px;
		}
	}

	.block__icon {
		position: absolute;
		top: 31px;
		right: -1px;
		width: 25px;
		height: 43px;

		@include breakpoint-down(tablet) {
			width: 15px;
			height: 26px;
			top: 23px;
			right: 27px;
		}

		@include breakpoint-down(mobile) {
			top: 27px;
			right: 41px;
		}
	}

	.block__icon img {
		transform: rotate(-62deg);

		@include breakpoint-down(mobile) {
			transform: scale(-1, 1) rotate(-62deg);
		}
	}

	.block__text span {
		font: 400 24px/1 $ff-nanum-pen;

		@include breakpoint-down(tablet) {
			font-size: 16px;
			letter-spacing: 1px;
		}
	}

	.block__highlight-ico {
		left: -8px !important;

		@include breakpoint-down(tablet) {
			left: -1px !important;
		}
	}

	&:hover {
		opacity: 0.7;
	}

	@include breakpoint-down(mobile) {
		padding-bottom: 75%;
	}
}

/* Block Alt */

.block--alt {
	margin-top: 149px;
	background-color: $c-light-blue;
	.block__inner {
		right: auto;
		left: 92px;
		bottom: 166px;
		text-align: left;

		@include breakpoint-down(small-desktop) {
			left: 15px;
			bottom: 116px;
		}

		@include breakpoint-down(tablet) {
			left: 42px;
			bottom: 135px;
		}

		@include breakpoint-down(mobile) {
			bottom: 99px;
		}
	}

	.block__text {
		top: auto;
		bottom: -63px;
		left: auto;
		right: -134px;

		@include breakpoint-down(small-desktop) {
			bottom: -83px;
			right: -124px;
		}

		@include breakpoint-down(tablet) {
			bottom: -47px;
			right: -74px;
		}
	}

	.block__icon {
		top: -45px;
		right: auto;
		left: 8px;

		@include breakpoint-down(tablet) {
			top: -33px;
			left: 23px;
		}

		@include breakpoint-down(mobile) {
			left: 19px;
		}
	}

	.block__icon img {
		@include breakpoint-down(mobile) {
			transform: scale(1, 1) rotate(-62deg);
		}
	}

	.block__highlight-ico {
		left: 21px !important;

		@include breakpoint-down(tablet) {
			left: 0 !important;
		}
	}

	@include breakpoint-down(tablet) {
		margin-top: 63px;
	}

	@include breakpoint-down(mobile) {
		margin-top: 20px;
	}
}
