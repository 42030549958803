/* ------------------------------------------------------------ *\
  Shell
\* ------------------------------------------------------------ */

.shell {
	max-width: calc(#{$shell-max-width} + #{$shell-gutter} * 2);
	padding-left: $shell-gutter;
	padding-right: $shell-gutter;
	margin: auto;
	width: 100%;

	@include breakpoint-down(mobile) {
		padding-left: 20px;
		padding-right: 20px;
	}
}

/* Shell Large */

.shell--large {
	max-width: calc(#{$shell-large} + #{$shell-gutter} * 2);
}

/* Shell small */

.shell--small {
	max-width: calc(#{$shell-small} + #{$shell-gutter} * 2);
}

/*  Shell medium  */

.shell--medium {
	max-width: calc(#{$shell-medium} + #{$shell-gutter} * 2);
}

/* Shell Fluid */

.shell--fluid {
	max-width: none;
}
