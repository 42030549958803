/* ------------------------------------------------------------ *\
	List services
\* ------------------------------------------------------------ */

.list-services {
	list-style-type: none;
	h5 {
		font-family: $ff-sans-serif;
		font-weight: 400;
		margin-bottom: 20px;
		@include breakpoint-down(tablet) {
			margin-bottom: 15px;
		}
	}
	ul {
		margin-right: -32px;

		@include breakpoint-down(tablet) {
			margin-right: 0;
		}

	}

	li {
		display: inline-block;
		vertical-align: middle;
		margin-right: 30px;
		margin-bottom: 16px;
		@include breakpoint-down(tablet) {
			margin-right: 13px;
			margin-bottom: 7px;
		}
	}

	a {
		display: block;
		min-width: 56px;
		text-decoration: none;
		&:hover {
			color: $c-pink;
		}

		@include breakpoint-down(tablet) {
			font-size: 12px;
		}
	}

	&--alt {
		li {
			margin-right: 0;
			width: 30%;

			@include breakpoint-down(large-desktop) {
				width: 49%;
			}

			@include breakpoint-down(tablet) {
				margin-bottom: 15px;
			}

			@include breakpoint-down(mobile) {
				width: 49% !important;
			}

		}

		a {
			font-weight: 700;

			@include breakpoint-down(tablet) {
				font-size: 14px;
				line-height: 2;
			}

			@include breakpoint-down(mobile) {
				font-size: 12px;
			}

		}

		i {
			@include breakpoint-down(tablet) {
				margin-right: 7px;
			}

			@include breakpoint-down(mobile) {
				margin-right: 0;
			}

			img {
				max-width: 40px;
				@include breakpoint-down(mobile) {
					max-width: 30px;
					margin-right: 0;
				}
			}
		}
	}
}
