/* ------------------------------------------------------------ *\
	List partners Alt
\* ------------------------------------------------------------ */

.list-partners-alt {
	ul {
		list-style-type: none;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-right: -32px;
	}

	h5 {
		font-family: $ff-sans-serif;
		font-weight: 400;
		margin-bottom: 20px;
	}

	li {
		display: flex;
		align-items: center;
		min-width: 100px;
		min-height: 38px;
		margin-right: 32px;
		margin-bottom: 9px;

		@include breakpoint-down(tablet) {
			margin-right: 23px;
			margin-bottom: 13px;
			min-height: 23px;
		}
	}

	li img {
		display: block;
		filter: grayscale(100%);
	}

	&.list-partners--reserve {
		h5 {
			margin-bottom: 32px !important;
		}

		li {
			margin-right: 0;
			width: 25% !important;
			margin-bottom: 13px;
		}
	}

	&.list-partners--space {
		li {
			margin-right: 28px;

			@include breakpoint-down(tablet) {
				margin-right: 20px !important;
			}
		}
	}
}
