/* ------------------------------------------------------------ *\
	Section Bg
\* ------------------------------------------------------------ */

.section-bg {
	padding: 35px 0 108px;
	position: relative;
	/* &:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 400px;
		background-color: $c-pearl;
		z-index: -1;
	
		@include breakpoint-down(tablet) {
			height: 200px;
		}
	
		@include breakpoint-down(mobile) {
			height: 158px;
		}
	} */

	.section__images {
		display: flex;
		justify-content: center;
		align-items: center;

		@include breakpoint-down(mobile) {
			flex-direction: column;
		}
	}

	.section__image img {
		width: 100%;

		@include breakpoint-down(mobile) {
			width: auto;
		}
	}

	.section__image--1 img {
		@include breakpoint-down(mobile) {
			width: 117px;
			height: 228px;
		}
	}

	.section__image + .section__image {
		margin-left: 90px;
		@include breakpoint-down(tablet) {
			margin-left: 47px;
		}

		@include breakpoint-down(mobile) {
			margin-left: 0;
			margin-top: 36px;
		}
	}

	.section__shell {
		@include breakpoint-down(tablet) {
			padding-left: 83px;
			padding-right: 83px;
		}

		@include breakpoint-down(mobile) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	@include breakpoint-down(tablet) {
		padding-bottom: 60px;
	}

	@include breakpoint-down(mobile) {
		padding-bottom: 40px;
		padding-top: 0;
	}

	&--alt {
		padding: 18px 0 54px;
		
		@include breakpoint-down(tablet) {
			padding-top: 94px !important; 
		}

		@include breakpoint-down(mobile) {
		    padding-top: 59px !important;
		}

		.article-single .article__image {
			@include breakpoint-down(tablet) {
				margin-bottom: 24px;
			}

			@include breakpoint-down(mobile) {
				margin-bottom: 13px;
			}
		}

		.article-single h4 {
			@include breakpoint-down(tablet) {
				max-width: 490px;
				margin-bottom: 35px;
			}

			@include breakpoint-down(mobile) {
				max-width: 100%;
				font-size: 24px;
				padding-right: 30px; 
				margin-bottom: 20px;
			}
		}

		.article-single .article__content  p {
			@include breakpoint-down(tablet) {
				max-width: 490px;	
				margin-bottom: 32px;
			}

			@include breakpoint-down(mobile) {
				max-width: 100%;
				margin-bottom: 24px;
			}

		}

		.article-single .article__image img {
			@include breakpoint-down(tablet) {
				width: 100%;
			}
		}

		.article-single--ap .article__image {
			@include breakpoint-down(tablet) {
				margin-bottom: 34px
			}
		}

		.article-single--ap h4 {
			@include breakpoint-down(tablet) {
				max-width: 384px;
			    margin-bottom: 24px;
			}
		}

		.article-single--ap .article__content p {
			@include breakpoint-down(tablet) {
			    margin-bottom: 20px;
			}
		}
	}

	&--rhs {
		@include breakpoint-down(tablet) {
			padding: 65px 0 12px !important;
		}

		@include breakpoint-down(tablet) {
			padding-top: 42px  !important; 
		}
	}

	&--topps {
		padding: 18px 0 72px;
		
		@include breakpoint-down(tablet) {
			padding: 65px 0 12px !important;
		}

		@include breakpoint-down(tablet) {
			padding-top: 42px  !important; 
		}
	}

	&--vashi {
		@include breakpoint-down(tablet) {
			padding: 17px 0 21px !important;
		}

		@include breakpoint-down(mobile) {
			padding: 115px 0 21px !important;
		}
	}
}
