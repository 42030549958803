/* ------------------------------------------------------------ *\
	List Partners
\* ------------------------------------------------------------ */

.list-partners {
	list-style-type: none;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: -54px !important;

	li {
		width: 25%;
		text-align: center;
		min-height: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 54px;

		@include breakpoint-down(tablet) {
			width: 21%;
			margin-bottom: 39px;
		}

		@include breakpoint-down(mobile) {
			width: 50%;
			margin-bottom: 32px;
		}

		img {
			filter: grayscale(1);
		}
	}

	@include breakpoint-down(mobile) {
		margin-bottom: -32px !important;
	}
}
