/* ------------------------------------------------------------ *\
	Section-images-ob
\* ------------------------------------------------------------ */

.section-images-ob {
	padding: 183px 0 12px;	

	@include breakpoint-down(tablet) {
		padding: 0 35px 5px; 
	}

	@include breakpoint-down(mobile) {
		padding: 0 0 5px; 
	}


	&.section .section__images {
	    padding: 150px 0 139px;
	    
		@include breakpoint-down(tablet) {
			padding: 114px 0 60px; 
		}

		@include breakpoint-down(mobile) {
			padding: 133px 0 111px;
		}
	}

	&.section .section__image + .section__image {
	    margin-top: 120px;

		@include breakpoint-down(tablet) {
			margin-top: 63px; 
		}

		@include breakpoint-down(mobile) {
			margin-top: 39px; 
		}

		@include breakpoint-down(mobile-small) {
			margin-left: auto; 
		}
	}
}