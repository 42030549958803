/* ------------------------------------------------------------ *\
	Stats
\* ------------------------------------------------------------ */

.stats {
	.stats__items {
		display: flex;
		justify-content: center;
		flex-flow: row wrap;

		@include breakpoint-down(mobile) {
			flex-direction: column;
			align-items: center;
		}
	}

	.stats__item {
		max-width: 210px;
		flex: 0 0 210px;

		@include breakpoint-down(mobile) {
			flex: 0 0 1;
		}
	}

	.stats__item + .stats__item {
		margin-left: 149px;
		@include breakpoint-down(tablet) {
			margin-left: 35px;
		}

		@include breakpoint-down(mobile) {
			margin-left: 0;
			margin-top: 40px;
		}
	}

	&--alt {
		.stat {
			z-index: 1;
		}

		.stat__outer {
			font-family: $ff-nanum-pen;
			font-size: 26px; 
			line-height: 0.85;
			font-weight: 400; 

			@include breakpoint-down(tablet) {
				font-size: 22px;
				line-height: 1.1;
			}

			@include breakpoint-down(mobile) {
				font-size: 26px;
				line-height: 0.85; 
			}

		}

		.stat .stat__inner {
			margin-top: 2px; 
		}

		.stat:after {
			top: 9px;
		}

		.stats__item + .stats__item {
			@include breakpoint-down(tablet) {
				margin-left: 15px; 
			}

			@include breakpoint-down(mobile) {
				margin-left: -105px; 
				margin-top: 0 ; 
			}
		}

		.stats__item {
			@include breakpoint-down(mobile) {
				margin-left: -60px; 
				max-height: 170px;
			}
		}


	}
}

.stat {
	position: relative;
	min-height: 127px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	.stat__inner {
		margin-left: 28px;
		margin-top: 25px;
	}

	.stat__entry {
		font-size: 11px;
		line-height: 1.3;
		font-weight: 300;
		margin-left: 44px;
		margin-bottom: 0;
		letter-spacing: 0.01px;
	}

	.stat__number {
		font: 600 47px/1 $ff-plantin;
		margin-bottom: -6px;
		display: block;
	}

	.stat__number em {
		font-weight: 300;
	}

	.stat__outer {
		position: absolute;
		right: 11px;
		top: 23px;
		padding-bottom: 9px;
	}

	.stat__outer span {
		font-weight: 700;
	}

	.stat__icon {
		position: absolute;
		bottom: 4px;
		left: -22px;
		width: 18px;
		height: 14px;
		display: inline-block;
	}

	.stat__icon img {
		display: block;
		width: 100%;
		height: 100%;
	}

	&:after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		z-index: -1;
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}
}

.stat--product {
	&:after {
		width: 133px;
		height: 127px;
		background-image: url(../images/svg/circle-2.svg);
	}
}

.stat--conversion {
	.stat__inner {
		margin-left: 21px;
	}

	.stat__entry {
		margin-left: 40px;
	}

	.stat__outer {
		right: 38px;
		top: 9px;
	}

	&:after {
		width: 127px;
		height: 130px;
		background-image: url(../images/svg/circle-3.svg);
	}
}

.stat--money {
	.stat__inner {
		margin-left: 8px;
	}

	.stat__entry {
		margin-left: 57px;
	}

	&:after {
		width: 131px;
		height: 126px;
		background-image: url(../images/svg/circle-4.svg);
	}
}

.stat--growth {
	.stat__inner {
		margin-left: 13px;

		@include breakpoint-down(tablet) {
			margin-left: 19px; 
		}

		@include breakpoint-down(mobile) {
			margin-left: -4px;
		    margin-right: 29px;
		}

	}

	.stat__outer {
	  	right: -14px;
	    top: 5px;

	    @include breakpoint-down(tablet) {
	    	right: -11px;
			top: 8px;
	    }

	    @include breakpoint-down(mobile) {
		    right: -62px;
		    top: 4px;
		}
	}

	.stat__icon {
	    bottom: 15px;
	    left: -29px;

	    @include breakpoint-down(tablet) {
	    	bottom: 23px;
	    }

		@include breakpoint-down(mobile) {
			bottom: 16px;
		}

	}

	&:after {
		width: 133px;
		height: 127px;
		background-image: url(../images/svg/circle-2.svg);
	    left: 13px;

	    @include breakpoint-down(tablet) {
	    	height: 110px;
	    }

		@include breakpoint-down(mobile) {
			height: 125px;
			left: 0;
		}

	}
}

.stat--views {
	@include breakpoint-down(mobile) {
		margin-left: 0;
	}

	.stat__inner {
		margin-left: 21px;

		@include breakpoint-down(tablet) {
			margin-left: 23px;
		}
	}

	.stat__entry {
		margin-left: 40px;
	}

	.stat__outer {
		right: -23px;
	    top: 4px;
			
		@include breakpoint-down(tablet) {
		    right: 0px;
		    top: 8px;
		}

		@include breakpoint-down(mobile) {
			right: -99px;
		    top: 6px;
		}

		@media only screen 
		and (min-device-width : 375px) 
		and (max-device-width : 667px) {
			right: -130px;
		}
	}

	.stat__icon {
	    bottom: 16px;

	    @include breakpoint-down(tablet) {
	    	left: -33px;
    	    bottom: 23px;
	    }

		@include breakpoint-down(mobile) {
		    left: -26px;
		    bottom: 17px;
		}

	}

	&:after {
		width: 127px;
		height: 130px;
		background-image: url(../images/svg/circle-3.svg);
	    left: 13px;

	    @include breakpoint-down(tablet) {
    	    height: 117px;
		    left: 4px;
	    }

		@include breakpoint-down(mobile) {
			height: 127px;
		}

	}
}

.stat--stores {
	.stat__inner {
		margin-left: 40px;

		@include breakpoint-down(tablet) {
			margin-left: 34px; 
		}

		@include breakpoint-down(mobile) {
		    margin-left: 39px;
		    margin-right: 19px;
		}
	}

	.stat__outer {
	    right: -51px;
	    top: 16px;

	    @include breakpoint-down(tablet) {
    	    right: -2px;
		    top: 11px;
	    	max-width: 103px;
	    }

		@include breakpoint-down(mobile) {
		    right: -118px;
			top: 12px;
		}
	}

	.stat__icon {
	    bottom: 24px;
		left: -26px;

		@include breakpoint-down(tablet) {
		    bottom: 44px;
		    left: -23px;
		}

	}
	
	&:after {
		width: 131px;
		height: 126px;
		background-image: url(../images/svg/circle-4.svg);
		left: 0;

		@include breakpoint-down(tablet) {
	    	height: 116px;
		    left: -3px;
	    }

		@include breakpoint-down(mobile) {
			height: 130px;
		}

	}
}

.stat--rate {
	.stat__inner {
		margin-left: 37px;

		@include breakpoint-down(tablet) {
			margin-left: 29px;
			margin-top: -5px !important; 
		}

		@include breakpoint-down(mobile) {
			margin-left: 23px;
		    margin-right: 49px;
		    margin-top: 8px !important; 
		}

	}

	.stat__outer {
  	    right: -42px;
	    top: 11px;

	    @include breakpoint-down(tablet) {
	    	right: -14px;
		    top: 3px ;
	    }

	    @include breakpoint-down(mobile) {
		    right: -92px;
			top: 8px;	
		}
	}

	.stat__icon {
	    bottom: 41px;
	    left: -29px;

	    @include breakpoint-down(tablet) {
	    	bottom: 43px;
	    }

		@include breakpoint-down(mobile) {
			bottom: 39px;
			left: -44px;
		}
		
		@media only screen 
		and (min-device-width : 375px) 
		and (max-device-width : 667px) {
			left: -25px;
		}
	}

	&:after {
		width: 133px;
		height: 127px;
		background-image: url(../images/svg/circle-2.svg);
	    left: 13px;

	    @include breakpoint-down(tablet) {
	    	height: 110px;
	    }

		@include breakpoint-down(mobile) {
			height: 125px;
			left: 0;
		}

	}
}

.stat--transactions {
	@include breakpoint-down(mobile) {
		margin-left: 0;
	}

	.stat__inner {
		margin-left: 28px;

		@include breakpoint-down(tablet) {
			margin-left: 22px;
			margin-top: -5px !important; 
		}

		@include breakpoint-down(mobile) {
			margin-left: 18px;
		    margin-right: 9px;
		    margin-top: 3px !important;
		}
	}

	.stat__entry {
		margin-left: 40px;
	}

	.stat__outer {
		max-width: 96px;
	    right: -29px;
	    top: 9px;
			
		@include breakpoint-down(tablet) {
			max-width: 80px;
		    right: 15px;
		    top: 3px;
		}

		@include breakpoint-down(mobile) {
		    right: -124px;
		    top: 11px;
		    max-width: 85px;
		}

		@media only screen 
		and (min-device-width : 375px) 
		and (max-device-width : 667px) {
			max-width: 104px;
			right: -130px;
		}
	}

	.stat__icon {
	    bottom: 40px;
        left: -29px;

	    @include breakpoint-down(tablet) {
    	    left: -29px;
		    bottom: 45px;
	    }

		@include breakpoint-down(mobile) {
		    left: -45px;
		    bottom: 42px;
		}

		@media only screen 
		and (min-device-width : 375px) 
		and (max-device-width : 667px) {
			left: -25px;
		}
	}

	&:after {
		width: 127px;
		height: 130px;
		background-image: url(../images/svg/circle-3.svg);
	    left: 13px;

	    @include breakpoint-down(tablet) {
    	    height: 117px;
		    left: 4px;
	    }

		@include breakpoint-down(mobile) {
			height: 127px;
		}
	}
}

.stat--duration {
	.stat__inner {
		margin-left: 21px;

		@include breakpoint-down(tablet) {
			margin-left: 16px;  
		}

		@include breakpoint-down(mobile) {
	        margin-left: 35px;
		    margin-right: -21px;
		}
	}

	.stat__outer {
        right: -14px;
	    top: 18px;
	    max-width: 96px;

	    @include breakpoint-down(tablet) {
	       	right: 3px;
		   	top: 9px;
	    	max-width: 85px;
	    }

		@include breakpoint-down(mobile) {
		   	right: -137px;
		    top: 13px;
		    max-width: 90px
		}
		
		@media only screen 
		and (min-device-width : 375px) 
		and (max-device-width : 667px) {
			max-width: 110px;
			right: -150px;
		}
	}

	.stat__icon {
	    bottom: 47px;
		left: -26px;

		@include breakpoint-down(tablet) {
		    bottom: 44px;
		    left: -23px;
		}

		@include breakpoint-down(mobile) {
		    bottom: 40px;
		    left: -47px;
		}

		@media only screen 
		and (min-device-width : 375px) 
		and (max-device-width : 667px) {
			left: -25px;
		}
	}
	
	&:after {
		width: 131px;
		height: 126px;
		background-image: url(../images/svg/circle-4.svg);
		left: 0;

		@include breakpoint-down(tablet) {
	    	height: 116px;
		    left: -3px;
	    }

		@include breakpoint-down(mobile) {
			height: 130px;
		}

	}
}

.stat--session {
	.stat__inner {
		margin-left: 5px;

		@include breakpoint-down(tablet) {
			margin-left: 29px;
			margin-top: -5px !important; 
		}

		@include breakpoint-down(mobile) {
			margin-left: 23px;
		    margin-right: 49px;
		    margin-top: 8px !important; 
		}

	}

	.stat__outer {
		right: -56px;
	    top: 9px;

	    @include breakpoint-down(tablet) {
    	    right: -22px;
		    top: 3px ;
		    max-width: 80px;
	    }

	    @include breakpoint-down(mobile) {
		    right: -72px;
			top: 8px;	
		}
	}

	.stat__icon {
	    bottom: 14px;
	    left: -31px;

	    @include breakpoint-down(tablet) {
	    	bottom: 44px;
	    	left: -17px;
	    }

		@include breakpoint-down(mobile) {
			bottom: 40px;
		    left: -38px;
		}

	}

	&:after {
		width: 133px;
		height: 127px;
		background-image: url(../images/svg/circle-2.svg);
	    left: -15px;

	    @include breakpoint-down(tablet) {
	    	height: 110px;
	    	left: 11px;
	    }

		@include breakpoint-down(mobile) {
			height: 125px;
			left: 0;
		}

	}
}

.stat--load-time {
	@include breakpoint-down(mobile) {
		margin-left: 0;
	}

	.stat__inner {
		margin-left: -2px;

		@include breakpoint-down(tablet) {
			margin-left: 22px;
			margin-top: -5px !important; 
		}

		@include breakpoint-down(mobile) {
			margin-left: 25px;
		    margin-right: 7px;
		    margin-top: 3px !important;
		}
	}

	.stat__entry {
		margin-left: 40px;
	}

	.stat__outer {
		max-width: 130px;
	    right: -29px;
	    top: 9px;
			
		@include breakpoint-down(tablet) {
			max-width: 80px;
		    right: 0;
		    top: 3px;
		}

		@include breakpoint-down(mobile) {
		    right: -130px;
		    top: 11px;
		    max-width: 93px;
		}

		@media only screen 
		and (min-device-width : 375px) 
		and (max-device-width : 667px) {
			max-width: 120px;
			right: -150px;
		}
	}

	.stat__icon {
	    bottom: 40px;
        left: -24px;

	    @include breakpoint-down(tablet) {
	        left: -33px;
		    bottom: 68px;
	    }

		@include breakpoint-down(mobile) {
	        left: -33px;
		    bottom: 54px
		}

	}

	&:after {
		width: 127px;
		height: 130px;
		background-image: url(../images/svg/circle-3.svg);
	    left: -22px;

	    @include breakpoint-down(tablet) {
    	    height: 117px;
		    left: 4px;
	    }

		@include breakpoint-down(mobile) {
			height: 127px;
		}
	}
}

.stat--conversion-rate {
	.stat__inner {
	    margin-left: -10px;
	    margin-top: 16px !important; 

		@include breakpoint-down(tablet) {
			margin-left: 16px;  
			margin-top: 4px !important;
		}

		@include breakpoint-down(mobile) {
	       margin-left: 16px;
		   margin-right: -5px;
		}
	}

	.stat__outer {
        right: -64px;
	    top: 18px;
	    max-width: 180px;

	    @include breakpoint-down(tablet) {
	       	right: -3px;
		   	top: 9px;
	    	max-width: 80px;
	    }

		@include breakpoint-down(mobile) {
		   	right: -137px;
		    top: 13px;
		    max-width: 94px
		}

		@media only screen 
		and (min-device-width : 375px) 
		and (max-device-width : 667px) {
			max-width: 120px;
			right: -160px;
		}
	}

	.stat__icon {
        bottom: 23px;
	    left: -28px;
		@include breakpoint-down(tablet) {
		    bottom: 70px;
		    left: -29px;
		}

		@include breakpoint-down(mobile) {
	        bottom: 54px;
		    left: -31px;
		}
	}
	
	&:after {
		width: 131px;
		height: 126px;
		background-image: url(../images/svg/circle-4.svg);
		left: -31px;

		@include breakpoint-down(tablet) {
	    	height: 116px;
		    left: -3px;
	    }

		@include breakpoint-down(mobile) {
			height: 130px;
		}

	}
}

.stat--retail {
	@include breakpoint-down(tablet) {
		margin-left: -38px; 
	}

	@include breakpoint-down(mobile) {
	    margin-left: 25px;
	}

	.stat__inner {
		margin-left: -11px;

		@include breakpoint-down(tablet) {
			margin-left: 17px;
			margin-top: -5px !important; 
		}

		@include breakpoint-down(mobile) {
			margin-left: 0;
		    margin-right: 49px;
		    margin-top: 8px !important; 
		}

	}

	.stat__outer {
		right: -30px;
	    top: 9px;
	    max-width: 115px;

	    @include breakpoint-down(tablet) {
    	    right: -22px;
		    top: 3px ;
	    }

	    @include breakpoint-down(mobile) {
		    right: -93px;
			top: 8px;	
		}

		@media only screen 
		and (min-device-width : 375px) 
		and (max-device-width : 667px) {
			max-width: 140px;
			right: -100px;
		}
	}

	.stat__icon {
	    bottom: 41px;
	    left: -31px;

	    @include breakpoint-down(tablet) {
	    	bottom: 44px;
	    	left: -28px;
	    }

		@include breakpoint-down(mobile) {
			bottom: 40px;
		    left: -23px;
		}

	}

	&:after {
		width: 133px;
		height: 127px;
		background-image: url(../images/svg/circle-2.svg);
	    left: -15px;

	    @include breakpoint-down(tablet) {
	    	height: 110px;
	    	left: 11px;
	    }

		@include breakpoint-down(mobile) {
			height: 125px;
			left: 0;
		}

	}
}

.stat--trade {
	@include breakpoint-down(tablet) {
		margin-left: 10px;
	}

	@include breakpoint-down(mobile) {
		margin-left: 23px;
	}

	.stat__inner {
		margin-left: -34px;

		@include breakpoint-down(tablet) {
			margin-left: -1px;
			margin-top: -5px !important; 
		}

		@include breakpoint-down(mobile) {
			margin-left: -5px;
		    margin-right: 7px;
		    margin-top: 3px !important;
		}
	}

	.stat__entry {
		margin-left: 40px;
	}

	.stat__outer {
		max-width: 130px;
  	    right: -50px;
	    top: 6px;
			
		@include breakpoint-down(tablet) {
			max-width: 85px;
		    right: -35px;
		    top: 3px;
		}

		@include breakpoint-down(mobile) {
		    right: -120px;
		    top: 11px;
		    max-width: 102px;
		}

		@media only screen 
		  and (min-device-width: 768px) 
		  and (max-device-width: 1024px) 
		  and (-webkit-min-device-pixel-ratio: 2) {
			max-width: 120px;
			right: -67px;
		}

		@media only screen 
		and (min-device-width : 375px) 
		and (max-device-width : 667px) {
			max-width: 140px;
			right: -140px;
		}
	}

	.stat__icon {
	    bottom: 40px;
        left: -24px;

	    @include breakpoint-down(tablet) {
			left: -24px;
		    bottom: 45px;
	    }

		@include breakpoint-down(mobile) {
	       	left: -30px;
		    bottom: 42px;
		}

	}

	&:after {
		width: 127px;
		height: 130px;
		background-image: url(../images/svg/circle-3.svg);
	    left: -22px;

	    @include breakpoint-down(tablet) {
    	    height: 117px;
		    left: 4px;
	    }

		@include breakpoint-down(mobile) {
			height: 127px;
		}
	}
}

.stat--magento {
	@include breakpoint-down(tablet) {
		margin-left: 20px;	
	}

	@include breakpoint-down(mobile) {
		margin-left: -8px; 
	}

	.stat__inner {
	    margin-left: -2px;
	    margin-top: 16px !important; 

		@include breakpoint-down(tablet) {
			margin-left: 21px;  
			margin-top: 4px !important;
		}

		@include breakpoint-down(mobile) {
	       margin-left: 23px;
		   margin-right: -5px;
		}
	}

	.stat__outer {
        right: -20px;
	    top: 7px;
	    max-width: 130px;

	    @include breakpoint-down(tablet) {
	       	right: -35px;
		   	top: 9px;
		   	max-width: 105px;
	    }

		@include breakpoint-down(mobile) {
		   	right: -173px;
		    top: 13px;
		    max-width: 120px;
		}

		@media only screen 
		  and (min-device-width: 768px) 
		  and (max-device-width: 1024px) 
		  and (-webkit-min-device-pixel-ratio: 2) {
			max-width: 120px;
			right: -55px;
		}

		@media only screen 
		and (min-device-width : 375px) 
		and (max-device-width : 667px) {
			max-width: 150px;
			right: -180px;
		}
	}

	.stat__icon {
        bottom: 62px;
	    left: -33px;
		@include breakpoint-down(tablet) {
		    bottom: 70px;
		    left: -29px;
		}

		@include breakpoint-down(mobile) {
			bottom: 63px;
		    left: -23px;
		}
	}
	
	&:after {
		width: 131px;
		height: 126px;
		background-image: url(../images/svg/circle-4.svg);
		left: -31px;

		@include breakpoint-down(tablet) {
	    	height: 116px;
		    left: -3px;
	    }

		@include breakpoint-down(mobile) {
			height: 130px;
		}

	}
}

.stat--bounce-rate {
	.stat__inner {
		margin-left: 23px;

		@include breakpoint-down(tablet) {
			margin-left: 29px;
			margin-top: -5px !important; 
		}

		@include breakpoint-down(mobile) {
			margin-left: 23px;
		    margin-right: 49px;
		    margin-top: 8px !important; 
		}
	}

	.stat__outer {
		right: -56px;
	    top: 16px;
	    max-width: 125px;

	    @include breakpoint-down(tablet) {
    	  	right: -13px;
		    top: 4px;
		    max-width: 80px;
	    }

	    @include breakpoint-down(mobile) {
		    right: -110px;
			top: 7px;	
			max-width: 115px;
		}

		@media only screen 
		  and (min-device-width: 768px) 
		  and (max-device-width: 1024px) 
		  and (-webkit-min-device-pixel-ratio: 2) {
			max-width: 100px;
			right: -35px;
		}


		@media only screen 
		and (min-device-width : 375px) 
		and (max-device-width : 667px) {
			max-width: 140px;
			right: -115px;
		}
	}

	.stat__icon {
	    bottom: 23px;
	    left: -31px;

	    @include breakpoint-down(tablet) {
	    	bottom: 44px;
	    	left: -29px;
	    }

		@include breakpoint-down(mobile) {
			bottom: 19px;
		    left: -31px;
		}

	}

	&:after {
		width: 133px;
		height: 127px;
		background-image: url(../images/svg/circle-2.svg);
	    left: -15px;

	    @include breakpoint-down(tablet) {
	    	height: 110px;
	    	left: 11px;
	    }

		@include breakpoint-down(mobile) {
			height: 125px;
			left: 0;
		}

	}
}

.stat--increase {
	@include breakpoint-down(mobile) {
		margin-left: 0;
	}

	.stat__inner {
		margin-left: 17px;

		@include breakpoint-down(tablet) {
			margin-left: 22px;
			margin-top: -5px !important; 
		}

		@include breakpoint-down(mobile) {
			margin-left: 25px;
		    margin-right: 7px;
		    margin-top: 3px !important;
		}
	}

	.stat__entry {
		margin-left: 40px;
	}

	.stat__outer {
		max-width: 130px;
	    right: -48px;
	    top: 9px;
			
		@include breakpoint-down(tablet) {
		    max-width: 83px;
		    right: 0;
		    top: 5px;
		}

		@include breakpoint-down(mobile) {
	        right: -145px;
		    top: 9px;
		    max-width: 110px;
		}

		@media only screen 
		  and (min-device-width: 768px) 
		  and (max-device-width: 1024px) 
		  and (-webkit-min-device-pixel-ratio: 2) {
			max-width: 100px;
			right: -15px;
		}

		@media only screen 
		and (min-device-width : 375px) 
		and (max-device-width : 667px) {
			max-width: 120px;
			right: -140px;
		}
	}

	.stat__icon {
        bottom: 19px;
	    left: -26px;

	    @include breakpoint-down(tablet) {
	        left: -33px;
		    bottom: 20px;
	    }

		@include breakpoint-down(mobile) {
	        left: -29px;
			bottom: 19px;
		}

	}

	&:after {
		width: 127px;
		height: 130px;
		background-image: url(../images/svg/circle-3.svg);
	    left: -4px;

	    @include breakpoint-down(tablet) {
    	    height: 117px;
		    left: 4px;
	    }

		@include breakpoint-down(mobile) {
			height: 127px;
		}
	}
}

.stat--session-duration {
	.stat__inner {
	    margin-left: 9px;
	    margin-top: 16px !important; 

		@include breakpoint-down(tablet) {
			margin-left: 31px;  
			margin-top: 4px !important;
		}

		@include breakpoint-down(mobile) {
	       margin-left: 16px;
		   margin-right: -5px;
		}
	}

	.stat__outer {
        right: -2px;
	    top: 18px;
	    max-width: 100px;

	    @include breakpoint-down(tablet) {
	       	right: 0px;
		   	top: 9px;
	    	max-width: 80px;
	    }

		@include breakpoint-down(mobile) {
		   	right: -137px;
		    top: 13px;
		    max-width: 92px
		}

		@media only screen 
		  and (min-device-width: 768px) 
		  and (max-device-width: 1024px) 
		  and (-webkit-min-device-pixel-ratio: 2) {
			max-width: 100px;
			right: -35px;
		}


		@media only screen 
		and (min-device-width : 375px) 
		and (max-device-width : 667px) {
			max-width: 120px;
			right: -150px;
		}
	}

	.stat__icon {
        bottom: 48px;
	    left: -28px;
		@include breakpoint-down(tablet) {
		    bottom: 44px;
		    left: -25px;
		}

		@include breakpoint-down(mobile) {
	        bottom: 43px;
			left: -28px;
		}
	}
	
	&:after {
		width: 131px;
		height: 126px;
		background-image: url(../images/svg/circle-4.svg);
		left: -13px;

		@include breakpoint-down(tablet) {
	    	height: 116px;
		    left: 13px;
	    }

		@include breakpoint-down(mobile) {
			height: 130px;
			left: -7px;
		}

	}
}

