/* ------------------------------------------------------------ *\
	Link
\* ------------------------------------------------------------ */

.link {
	color: $c-pink;
	font-weight: 600;
	text-decoration: none;
	transition: opacity 0.3s;
	&:hover {
		color: $c-pink;
		opacity: 0.7;
	}
}
