/* ------------------------------------------------------------ *\
	Category
\* ------------------------------------------------------------ */

.category-md:after {
	background: url('/../images/ellipses/ellipse-md.png');
	width: 74px;
	height: 49px;

	@include breakpoint-down(mobile) {
		background-size: 70px 38px;
	}
}

.category-lg:after {
	background: url('/../images/ellipses/ellipse-lg.png');
	width: 92px;

	@include breakpoint-down(mobile) {
		background-size: auto 38px;
	}
}

.category-xl:after {
	background: url('/../images/ellipses/ellipse-xl.png');
	width: 96px;

	@include breakpoint-down(mobile) {
		background-size: auto 38px;
	}
}

.category-sm:after {
	background: url('/../images/ellipses/ellipse-sm.png');
	width: 55px;

	@include breakpoint-down(mobile) {
		background-size: auto 38px;
	}
}