/* ------------------------------------------------------------ *\
	Testimonail
\* ------------------------------------------------------------ */

.testimonial {
	.testimonial__entry p {
		font: 300 24px/1.33 $ff-plantin;
		margin-bottom: 0;

		@include breakpoint-down(mobile) {
			font-size: 20px;
			line-height: 1.5;
		}
	}

	.testimonial__entry p + p {
		margin-top: 34px;

		@include breakpoint-down(mobile) {
			margin-top: 30px;
		}
	}

	.testimonial__author {
		margin-top: 32px;

		@include breakpoint-down(mobile) {
			margin-top: 21px;
		}
	}

	.testimonial__author p {
		line-height: 1.57;
	}
}
