/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */

.nav {
	li {
		display: inline-block;
		vertical-align: middle;
		margin-right: 31px;

		@include breakpoint-down(tablet) {
			display: block;
			text-align: center;
			margin-right: 0;
		}
	}

	a {
		font-size: 16px;
		letter-spacing: 0.5px;
		text-decoration: none;
		transition: color 0.3s;

		&:hover {
			color: $c-pink;
		}

		@include breakpoint-down(tablet) {
			font: 300 38px/1.26 $ff-plantin;
		}
	}

	.active a {
		color: $c-pink;
	}

	@include breakpoint-down(tablet) {
		margin-bottom: 30px;
	}
}
