/* ------------------------------------------------------------ *\
	Circle
\* ------------------------------------------------------------ */

.circle {
	position: absolute;
	left: -556px;
	top: -310px;
	z-index: -1;

	.circle__ico {
		width: 1167px;
		height: 1120px;
		//animation: spin 10s linear infinite;

		@include breakpoint-down(large-desktop) {
			width: 976px;
			height: 937px;
		}

		@include breakpoint-down(mobile) {
			width: 822px;
			height: 789px;
		}
	}

	.circle__ico svg {
		display: block;
		width: 100%;
		height: 100%;
	}

	@media (min-width: 2500px) {
		display: none;
	}

	@include breakpoint-down(mobile) {
		top: -181px;
		left: -611px;
	}
}

/* Circle 1 */

.circle--1 {
	top: -310px;
	left: -620px;
	.circle__ico {
		width: 965px;
		height: 926px;

		@include breakpoint-down(large-desktop) {
			width: 607px;
			height: 583px;
		}

		@include breakpoint-down(tablet) {
			width: 398px;
			height: 382px;
		}

		@include breakpoint-down(mobile) {
			width: 355px;
			height: 365px;
		}
	}

	@include breakpoint-down(large-desktop) {
		top: 258px;
		left: -357px;
	}

	@include breakpoint-down(tablet) {
		top: 207px;
		left: -255px;
	}

	@include breakpoint-down(mobile) {
		top: -124px;
		left: -252px;
	}
}

.circle--2 {
	top: -93px;
	left: -208px;
	.circle__ico {
		width: 374px;
		height: 360px;
	}
}

.circle--3 {
	top: -240px;
	right: -216px;
	left: auto;
	z-index: 1;

	.circle__ico {
		width: 976px;
		height: 937px;

		@include breakpoint-down(tablet) {
			width: 312px;
			height: 300px;
		}
	}

	@include breakpoint-down(large-desktop) {
		right: -404px;
	}

	@include breakpoint-down(tablet) {
		top: -94px;
		right: -90px;
	}
}

.circle--4 {
	top: -310px;
	left: -310px;
	.circle__ico {
		width: 916px;
		height: 880px;

		@include breakpoint-down(tablet) {
			width: 401px;
			height: 386px;
		}
	}

	@include breakpoint-down(tablet) {
		top: -100px;
		left: -220px;
	}
}

/* Circle Fixed */

.circle--fixed {
	position: fixed;
}
