/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */

.form {
	.form__head {
		margin-bottom: 48px;
		@include breakpoint-down(mobile) {
			margin-bottom: 28px;
		}
	}

	.form__title {
		font-weight: 300;
		line-height: 1.31;

		@include breakpoint-down(tablet) {
			font-size: 24px;
			line-height: 1.42;
		}
	}

	.form__controls {
		position: relative;
		width: 81%;

		@include breakpoint-down(large-desktop) {
			width: 100%;
		}
	}

	.form__field {
		font-size: 16px;
		color: $c-base-2;
		border: 1px solid $c-light-gray-2;
		padding: 13px 17px 14px;
		background-color: transparent;
		width: 100%;
		border-radius: 0;

		&:-ms-input-placeholder,
		&::placeholder {
			color: $c-light-gray-3;
		}
	}

	.form__field--textarea {
		resize: none;
		min-height: 178px;
		&:-ms-input-placeholder {
			color: $c-light-gray-3;
		}
	}

	.form__label {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 14px;
		color: $c-light-gray-3;
		font-size: 16px;
		padding: 0 4px;
		line-height: 1;
		transition: top 0.3s ease-in-out, font-size 0.3s ease-in-out;
		background-color: $c-pearl;
		margin-bottom: 0;

		&.is-active {
			top: 1px;
			font-size: 12px;
		}
	}

	.form__row + .form__row {
		margin-top: 32px;
	}

	.form__actions {
		text-align: right;
		width: 81%;
		margin-top: 26px;

		@include breakpoint-down(large-desktop) {
			width: 100%;
		}
	}

	.form__btn {
		min-width: 168px;
	}
}
