/* ------------------------------------------------------------ *\
	Section Article
\* ------------------------------------------------------------ */

.section-article {
	padding: 238px 0 135px;

	@include breakpoint-down(tablet) {
		padding: 96px 0 95px;
	}

	.section__shell {
		@include breakpoint-down(mobile) {
			padding-left: 20px;
			padding-top: 20px;
		}
	}
}
