/* ------------------------------------------------------------ *\
	Popup
\* ------------------------------------------------------------ */

.popup {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: $c-pearl;
	height: 100vh;
	min-height: 1000px;
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	justify-content: space-between;
	flex-flow: column nowrap;

	.popup__head {
		max-width: 1760px;
		margin: 0 auto;
		padding: 34px 40px;
		position: relative;
		width: 100%;

		@include breakpoint-down(large-desktop) {
			padding: 34px 29px;
		}

		@include breakpoint-down(tablet) {
			padding: 10px 22px;
		}
	}

	.popup__close {
		opacity: 1;
	}

	.popup__close img {
		pointer-events: none;
		width: 100%;
		height: 100%;
		display: block;
	}

	.popup__list {
		padding-top: 135px;
		@include breakpoint-down(mobile) {
			padding-top: 58px;
			margin-bottom: 17px;
		}
	}

	.popup__body {
		width: 100%;
		max-width: 1145px;
		padding-left: 20px;
		padding-right: 20px;
		margin: auto;
		position: relative;
		z-index: 5;

		@include breakpoint-down(large-desktop) {
			max-width: 932px;
		}
	}

	.popup__cols {
		display: flex;
		justify-content: space-between;
		flex-flow: row wrap;

		@include breakpoint-down(mobile) {
			flex-direction: column-reverse;
		}
	}

	.popup__col--size-1 {
		max-width: 48%;
		flex: 0 0 48%;

		@include breakpoint-down(mobile) {
			max-width: 100%;
			flex: 0 0 100%;
		}
	}

	.popup__col--size-2 {
		max-width: 22%;
		flex: 0 0 22%;

		@include breakpoint-down(tablet) {
			max-width: 30%;
			flex: 0 0 30%;
		}

		@include breakpoint-down(mobile) {
			max-width: 100%;
			flex: 0 0 100%;
		}
	}

	@include breakpoint-down(mobile) {
		justify-content: flex-start;
		padding-bottom: 72px;
	}
}
