/* ------------------------------------------------------------ *\
	List Contacts
\* ------------------------------------------------------------ */

.list-contacts {
	list-style-type: none;
	li {
		display: inline-block;
		vertical-align: middle;
		line-height: 1;
		padding-right: 9px;
		margin-right: 7px;
		border-right: 1px solid $c-base-1;

		&:last-child {
			padding-right: 0;
			margin-right: 0;
			border: none;
		}

		@include breakpoint-down(mobile) {
			display: block;
			padding-right: 0;
			margin-right: 0;
			border: none;
			margin-bottom: 10px;
			font-size: 12px;
		}
	}

	a {
		text-decoration: none;
		transition: color 0.3s;
		&:hover {
			color: $c-pink;
		}
	}

	span {
		display: inline-block;
		vertical-align: middle;
	}

	span + i {
		margin-left: 6px;
	}
}
