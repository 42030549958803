/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer {
	position: relative;
	z-index: 5;
	.footer__shell {
		min-height: 65px;
		max-width: 1680px;
		padding-left: 111px;
		padding-right: 111px;
		display: flex;
		align-items: center;

		@include breakpoint-down(large-desktop) {
			padding-left: 31px;
			padding-right: 31px;
		}

		@include breakpoint-down(mobile) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	@include breakpoint-down(mobile) {
		padding-bottom: 16px;
	}
}
