/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */

.btn {
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
	appearance: none;
	letter-spacing: 0.5px;
	padding: 12px 15px 13px;
	border: 1px solid;
	border-radius: 28px;
	min-width: 132px;
	position: relative;
	transition: all 0.35s ease;
	transform: translateZ(0);
	z-index: 2;
	overflow: hidden;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		width: 0;
		transition: width 0.3s;
		z-index: -1;
		border-radius: 28px;
	}

	&:hover:before {
		width: 100%;
		transition: width 0.5s cubic-bezier(0.52, 1.64, 0.37, 0.66);
	}

	&:focus,
	&.focus {
		box-shadow: none;
	}
}

/* Button Primary */

.btn--primary {
	background-color: transparent;
	border-color: $c-pink;
	color: $c-pink;

	&:before {
		background-color: $c-pink;
	}

	&:hover {
		color: $c-white;
	}

	&.is-active {
		/* position: fixed;
		top: 32px;
		right: 29px; */
		color: $c-white;
		&:before {
			width: 100%;
			transition: width 0.5s cubic-bezier(0.52, 1.64, 0.37, 0.66);
		}

		&:hover {
			color: $c-pink;
		}

		&:hover:before {
			width: 0;
		}
	}

	/* &.is-align {
		position: static;
	} */
}

/* Button Secondary */

.btn--secondary {
	background-color: $c-pink;
	border-color: $c-pink;
	color: $c-white;

	&:before {
		background-color: $c-white;
	}

	&:hover {
		color: $c-pink;
	}
}

/*  Btn block  */

.btn--block {
	display: block;
	padding-left: 0;
	padding-right: 0;
}
