/* ------------------------------------------------------------ *\
	NAv trigger
\* ------------------------------------------------------------ */

.nav-trigger {
	position: relative;
	display: inline-block;
	width: 18px;
	height: 12px;
	
	@include breakpoint-down(mobile) {
	    width: 36px;
	}

	span {
		position: absolute;
		display: block;
		height: 2px;
		width: 100%;
		background: $c-black;
		transition: top 0.2s 0.25s, opacity 0.2s 0.25s, transform 0.2s 0s;
		&:nth-child(1) {
			top: 0;
		}
		&:nth-child(2) {
			top: 5px;
		}
		&:nth-child(3) {
			top: 10px;
		}
	}

	&.is-active span {
		transition: background 0.2s, top 0.2s, opacity 0.2s,
			transform 0.2s 0.25s;
	}

	&.is-active span:nth-child(3),
	&.is-active span:nth-child(1) {
		top: 5px;
	}

	&.is-active span:nth-child(2) {
		opacity: 0;
	}

	&.is-active span:nth-child(1) {
		transform: rotate(45deg);
	}

	&.is-active span:nth-child(3) {
		transform: rotate(-45deg);
	}
}
