/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	padding: 32px 0;
	z-index: 50;
	.header__shell {
		margin: auto;
		max-width: 1760px;
		padding-left: 40px;
		padding-right: 40px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		transition: max-width 0.6s;

		@include breakpoint-down(large-desktop) {
			padding-left: 29px;
			padding-right: 29px;
		}

		&.is-active {
			transition: max-width 0.6s;
			max-width: 680px;
			@include breakpoint-down(tablet) {
				max-width: 100%;
			}

			@include breakpoint-down(mobile) {
				padding-right: 3px;
			}
		}

		@include breakpoint-down(tablet) {
			padding-left: 40px;
			padding-right: 33px;
		}

		@include breakpoint-down(mobile) {
			padding-left: 20px;
			padding-right: 24px;
		}
	}

	.header__aside {
		display: flex;
		align-items: center;

		@include breakpoint-down(tablet) {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			height: 100vh;
			background-color: #f9f7f3;
			opacity: 0;
			visibility: hidden;
			transition: opacity 0.3s, visibility 0.3s;
			flex-direction: column;
			justify-content: center;

			&:after {
				content: '';
				position: absolute;
				left: -88px;
				top: -73px;
				width: 269px;
				height: 258px;
				background-image: url(../images/svg/circle-1.svg);
				background-repeat: no-repeat;
				background-size: 100% 100%;
				/* animation: spin 10s linear infinite; */
			}

			&.is-active {
				opacity: 1;
				visibility: visible;
			}
		}

		/* &.is-active {
			padding-right: 132px;
		} */
	}

	.header__slogan {
		font-weight: 300;
		position: relative;
		z-index: 6;
		opacity: 0;
		visibility: hidden;
		height: 0;
		/* transition: opacity 0.3s, visibility 0.3s; */
		&.is-active {
			opacity: 1;
			visibility: visible;
			height: auto;
			transition: opacity 0.3s, visibility 0.3s;
			@include breakpoint-down(tablet) {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
			}
		}
	}

	.header__slogan h4 {
		margin-bottom: 0;
		font-weight: 300;
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 0;
		z-index: 1;
		background-color: $c-white;
		box-shadow: 0 0 15px rgba($c-light-gray-1, 0.5);
		transition: height 0.7s;

		@include breakpoint-down(mobile) {
			transition: height 0.3s;
		}
	}

	&.is-fixed {
		position: fixed;
		transition: all 0.3s;
		&:after {
			height: 100%;
			transition: height 0.7s;
		}
	}

	&.is-active {
		position: fixed;
	}

	&.is-active .nav,
	&.is-active .logo {
		opacity: 0;
	}

	@include breakpoint-down(tablet) {
		padding: 9px 0;
	}

	@include breakpoint-down(mobile) {
		padding: 9px 0;
	}
}
