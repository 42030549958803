/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */

.logo {
	display: block;
	width: 141px;
	height: 47px;
	img {
		display: block;
		width: 100%;
		height: 100%;
	}

	&.is-hidden {
		display: none;
	}

	@include breakpoint-down(tablet) {
		position: relative;
		z-index: 5;
	}

	@include breakpoint-down(mobile) {
		width: 225px;
		height: auto;
	}
}

/* Logo Bonas */

.logo-bonas {
	width: 200px;
	height: 19px;
}

/* Logo topps */

.logo-topps {
	width: 178px;
	height: 37px;
}
