/* ------------------------------------------------------------ *\
  Vendor
\* ------------------------------------------------------------ */
@import url(~bootstrap/dist/css/bootstrap.css);
@import url(~aos/dist/aos.css);
@import url(~swiper/css/swiper.css);
@import url(~magnific-popup/dist/magnific-popup.css);
/* ------------------------------------------------------------ *\
	Generic
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
  SCSS Functions
\* ------------------------------------------------------------ */
/* example usage*/
/* ------------------------------------------------------------ *\
	Reset
\* ------------------------------------------------------------ */
@import url(_sprite.css);
* {
  padding: 0;
  margin: 0;
  outline: 0;
  box-sizing: border-box; }

*:before,
*:after {
  box-sizing: inherit; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
main {
  display: block; }

template {
  display: none; }

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: none; }

img,
iframe,
video,
audio,
object {
  max-width: 100%; }

img,
iframe {
  border: 0 none; }

img {
  height: auto;
  display: inline-block;
  vertical-align: middle; }

b,
strong {
  font-weight: bold; }

address {
  font-style: normal; }

svg:not(:root) {
  overflow: hidden; }

a,
button,
input[type="submit"],
input[type="button"],
input[type="reset"],
input[type="file"],
input[type="image"],
label[for] {
  cursor: pointer; }

a[href^="tel"],
button[disabled],
input[disabled],
textarea[disabled],
select[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0; }

input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"],
textarea,
a[href^="tel"] {
  appearance: none; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  appearance: none; }

textarea {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased; }

button,
select {
  text-transform: none; }

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0; }

nav ul,
nav ol {
  list-style: none outside none; }

input::placeholder {
  color: inherit;
  opacity: 1; }

textarea::placeholder {
  color: inherit;
  opacity: 1; }

input:-webkit-autofill {
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: 0 0 0 1000px #fff inset; }

input[type=text],
input[type=button],
input[type=tel],
input[type=email],
input[type=submit],
input[type=date],
input[type=search],
textarea {
  appearance: none; }

/* ------------------------------------------------------------ *\
    Fonts
\* ------------------------------------------------------------ */
@font-face {
  font-family: 'Plantin';
  src: url("../fonts/Plantin-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Plantin';
  src: url("../fonts/Plantin-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Plantin';
  src: url("../fonts/Plantin-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Plantin';
  src: url("../fonts/Plantin-SemiboldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Domaine';
  src: url("../fonts/DomaineText-Regular.woff2") format("woff2"), url("../fonts/DomaineText-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */
body {
  min-width: 320px;
  background: #fff;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.71;
  color: #212121; }
  body.is-fixed {
    position: fixed;
    height: 100vh;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    overflow-y: auto; }

a {
  color: inherit;
  text-decoration: underline; }
  a:hover {
    text-decoration: none; }
  a[href^='tel'] {
    text-decoration: none;
    color: #e12954; }

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  margin-bottom: calc(1.71em / 2);
  font-family: "Plantin", sans-serif; }

.h1,
h1 {
  font-size: 96px; }
  @media (max-width: 991px) {
    .h1,
    h1 {
      font-size: 54px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .h1,
    h1 {
      font-size: 38px; } }

.h2,
h2 {
  font-size: 54px; }
  @media (max-width: 991px) {
    .h2,
    h2 {
      font-size: 30px; } }

.h3,
h3 {
  font-size: 32px; }
  @media (max-width: 991px) {
    .h3,
    h3 {
      font-size: 20px; } }

.h4,
h4 {
  font-size: 24px; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .h4,
    h4 {
      font-size: 20px; } }

.h5,
h5 {
  font-size: 20px; }
  @media (max-width: 991px) {
    .h5,
    h5 {
      font-size: 16px; } }

.h6,
h6 {
  font-size: 14px; }

p,
ul,
ol,
dl,
hr,
table,
blockquote {
  margin-bottom: 1.71em; }

h1[class],
h2[class],
h3[class],
h4[class],
h5[class],
h6[class],
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
table:last-child,
blockquote:last-child {
  margin-bottom: 0; }

@keyframes spin {
  100% {
    transform: rotate(360deg); } }

/* ------------------------------------------------------------ *\
  Helpers
\* ------------------------------------------------------------ */
/*  Clear  */
.clear:after {
  content: '';
  line-height: 0;
  display: table;
  clear: both; }

/* sr-only */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

/*  Notext  */
.notext {
  white-space: nowrap;
  text-indent: 100%;
  text-shadow: none;
  overflow: hidden; }

.notext-old {
  font-size: 0;
  line-height: 0;
  text-indent: -4000px; }

/*  Hidden  */
[hidden],
.hidden {
  display: none; }

/*  Disabled  */
[disabled],
.disabled {
  cursor: default; }

/*  Responsive Helpers  */
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important; }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hidden-xs {
    display: none !important; }
  .visible-xs-block {
    display: block !important; }
  .visible-xs-inline {
    display: inline !important; }
  .visible-xs-inline-block {
    display: inline-block !important; } }

@media (min-width: 767.02px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important; }
  .visible-sm-block {
    display: block !important; }
  .visible-sm-inline {
    display: inline !important; }
  .visible-sm-inline-block {
    display: inline-block !important; } }

@media (min-width: 991.02px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important; }
  .visible-md-block {
    display: block !important; }
  .visible-md-inline {
    display: inline !important; }
  .visible-md-inline-block {
    display: inline-block !important; } }

@media (min-width: 1199.02px) {
  .hidden-lg {
    display: none !important; }
  .visible-lg-block {
    display: block !important; }
  .visible-lg-inline {
    display: inline !important; }
  .visible-lg-inline-block {
    display: inline-block !important; } }

/* ------------------------------------------------------------ *\
	Components
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Article Alt
\* ------------------------------------------------------------ */
.article-alt {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-height: inherit;
  flex-direction: row; }
  .article-alt .article__images,
  .article-alt .article__body {
    width: 48%; }
  .article-alt .article__title {
    font-weight: 300; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .article-alt .article__title {
        line-height: 1.4; } }
  .article-alt .article__head {
    margin-bottom: 22px; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .article-alt .article__head {
        margin-bottom: 15px; } }
  .article-alt .article__inner {
    max-width: 336px; }
  .article-alt .article__body {
    padding-left: 95px;
    /* min-height: 635px; */
    display: flex;
    align-items: center; }
    @media (max-width: 1440px) {
      .article-alt .article__body {
        padding-left: 116px;
        width: 48%;
        min-height: 590px; } }
    @media (max-width: 991px) {
      .article-alt .article__body {
        width: 100%;
        min-height: auto;
        padding-left: 83px;
        max-width: 508px;
        margin: auto; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .article-alt .article__body {
        padding-left: 0; } }
  .article-alt .article__images {
    position: relative;
    min-height: 635px; }
    @media (max-width: 1440px) {
      .article-alt .article__images {
        min-height: 590px;
        width: 51%; } }
    @media (max-width: 991px) {
      .article-alt .article__images {
        width: 100%;
        max-width: 508px;
        margin: auto;
        min-height: 480px;
        margin-bottom: 60px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .article-alt .article__images {
        min-height: 317px;
        margin-bottom: 44px; } }
  .article-alt .article__images--small {
    min-height: 530px; }
    @media (max-width: 1440px) {
      .article-alt .article__images--small {
        min-height: 400px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .article-alt .article__images--small {
      min-height: 335px; } }
  @media (max-width: 1440px) {
    .article-alt .article__body--small {
      padding-left: 160px; } }
  @media (max-width: 1199px) {
    .article-alt .article__body--small {
      padding-left: 0px; } }
  @media (max-width: 991px) {
    .article-alt .article__body--small {
      min-height: auto;
      padding-left: 83px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .article-alt .article__body--small {
      padding-left: 0; } }
  .article-alt .article__image {
    position: absolute; }
  .article-alt .article__image--1 {
    top: 114px;
    left: 0;
    width: 437px;
    height: 436px; }
    @media (max-width: 1440px) {
      .article-alt .article__image--1 {
        width: 405px;
        height: 405px; } }
    @media (max-width: 991px) {
      .article-alt .article__image--1 {
        width: 330px;
        height: 330px;
        top: 86px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .article-alt .article__image--1 {
        width: 217px;
        height: 217px;
        top: 57px; } }
  .article-alt .article__image--2 {
    top: 0;
    right: -7px;
    width: 305px;
    height: 405px; }
    @media (max-width: 1440px) {
      .article-alt .article__image--2 {
        width: 283px;
        height: 376px;
        right: -9px;
        top: 6px; } }
    @media (max-width: 991px) {
      .article-alt .article__image--2 {
        top: 0;
        width: 231px;
        height: 306px;
        right: 0; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .article-alt .article__image--2 {
        width: 152px;
        height: 202px; } }
  .article-alt .article__image--3 {
    bottom: 0;
    right: 67px;
    width: 375px;
    height: 375px; }
    @media (max-width: 1440px) {
      .article-alt .article__image--3 {
        width: 348px;
        height: 348px;
        right: 59px;
        bottom: -7px; } }
    @media (max-width: 991px) {
      .article-alt .article__image--3 {
        bottom: 0;
        right: 55px;
        width: 284px;
        height: 284px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .article-alt .article__image--3 {
        width: 187px;
        height: 187px;
        right: 36px; } }
  .article-alt .article__image--4 {
    top: 0;
    left: 0;
    width: 405px;
    height: 405px; }
    @media (max-width: 1440px) {
      .article-alt .article__image--4 {
        width: 306px;
        height: 305px;
        left: 70px; } }
    @media (max-width: 1199px) {
      .article-alt .article__image--4 {
        left: 0; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .article-alt .article__image--4 {
        display: none; } }
  .article-alt .article__image--5 {
    top: 98px;
    right: 0;
    width: 576px;
    height: 432px; }
    @media (max-width: 1440px) {
      .article-alt .article__image--5 {
        width: 435px;
        height: 326px;
        right: 40px;
        top: 76px; } }
    @media (max-width: 1199px) {
      .article-alt .article__image--5 {
        right: 0; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .article-alt .article__image--5 {
        width: 335px;
        height: 335px;
        left: 0;
        right: 0;
        top: 0; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .article-alt .article__entry {
      max-width: 310px; } }

/* Article Alt reverse */
.article-alt--reverse {
  flex-direction: row-reverse; }

/* ------------------------------------------------------------ *\
	Article Blog
\* ------------------------------------------------------------ */
.article-blog {
  width: 50%;
  padding: 24px 20px 43px; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .article-blog {
      width: 100%;
      padding: 0 20px 57px; } }
  .article-blog .article__image {
    margin-bottom: 20px;
    width: 100%; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .article-blog .article__image {
        margin-bottom: 16px; } }
  .article-blog .article__image img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .article-blog .article__title {
    font-family: "Domaine", sans-serif;
    line-height: 1.4;
    font-weight: 400;
    margin-bottom: 19px; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .article-blog .article__title {
        line-height: 1.5;
        margin-bottom: 16px; } }
  .article-blog .article__title a {
    text-decoration: none;
    transition: 0.4s; }
  .article-blog .article__title a:hover {
    color: #e12954; }
  .article-blog h6 {
    font-family: "Lato", Helvetica, Arial, sans-serif;
    font-size: 16px;
    color: #9E9E9E;
    font-weight: 400;
    margin-bottom: 28px; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .article-blog h6 {
        font-size: 14px;
        margin-bottom: 25px; } }

/* ------------------------------------------------------------ *\
	Article single
\* ------------------------------------------------------------ */
.article-single {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media (max-width: 991px) {
    .article-single {
      display: block;
      padding: 0 37px !important; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .article-single {
      padding: 0 !important; } }
  .article-single .article__content {
    max-width: 435px;
    width: 100%;
    margin: 53px 93px 0 0;
    padding-left: 20px; }
    @media (max-width: 991px) {
      .article-single .article__content {
        padding-left: 0; } }
    .article-single .article__content--xs {
      max-width: 320px; }
    .article-single .article__content--295w {
      max-width: 295px !important; }
    .article-single .article__content--320w {
      max-width: 320px !important; }
    .article-single .article__content--310w {
      max-width: 310px !important; }
    .article-single .article__content--260w {
      max-width: 260px !important; }
    .article-single .article__content--350w {
      max-width: 350px !important; }
    .article-single .article__content--335w {
      max-width: 335px !important; }
    .article-single .article__content--390w {
      max-width: 390px !important; }
    @media (max-width: 991px) {
      .article-single .article__content {
        max-width: 510px !important;
        margin: 0 auto !important; } }
  .article-single .article__content p {
    letter-spacing: 0.15px; }
  .article-single--ap {
    align-items: flex-start; }
    @media (max-width: 991px) {
      .article-single--ap .article__image {
        margin-bottom: 34px; } }
    .article-single--ap .article__content {
      margin: 55px 93px 0 0; }
    .article-single--ap h4 {
      margin-bottom: 31px; }
    .article-single--ap p {
      margin-bottom: 33px; }
  .article-single--rhs {
    align-items: flex-start;
    margin-bottom: 57px; }
    @media (max-width: 991px) {
      .article-single--rhs {
        margin-bottom: 0; } }
    .article-single--rhs .article__content {
      margin-top: 60px; }
      @media (max-width: 991px) {
        .article-single--rhs .article__content {
          max-width: 100% !important;
          margin-left: 0 !important; } }
      .article-single--rhs .article__content h4 {
        margin-bottom: 33px; }
      @media (max-width: 991px) {
        .article-single--rhs .article__content p {
          max-width: 97% !important;
          margin-bottom: 20px !important; } }
      @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
        .article-single--rhs .article__content p {
          margin-bottom: 27px !important; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .article-single--rhs .article__image {
        margin-bottom: 37px !important; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .article-single--topps .article__image {
      margin-bottom: 18px !important; } }
  .article-single--topps h4 {
    margin-bottom: 30px !important; }
  .article-single h4 {
    font-weight: 400; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .article-single h4 {
        font-size: 24px; } }
  @media (max-width: 991px) {
    .article-single .article__image {
      text-align: center;
      margin-bottom: 38px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .article-single .article__image {
      margin-bottom: 19px; } }
  .article-single--reversed {
    flex-direction: row-reverse; }
    .article-single--reversed .article__content {
      padding-left: 0; }
    .article-single--reversed--xs {
      max-width: 330px; }
    .article-single--reversed--295w {
      max-width: 315px !important; }
    .article-single--reversed--320w {
      max-width: 340px !important; }
    .article-single--reversed--310w {
      max-width: 330px !important; }
    .article-single--reversed--260w {
      max-width: 280px !important; }
    .article-single--reversed--350w {
      max-width: 370px !important; }
    .article-single--reversed--335w {
      max-width: 355px !important; }
    .article-single--reversed--390w {
      max-width: 410px !important; }

.article-single--alt {
  align-items: flex-start; }
  .article-single--alt p {
    font-size: 16px;
    line-height: 1.5; }
  @media (max-width: 991px) {
    .article-single--alt h4 {
      max-width: 415px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .article-single--alt h4 {
      font-size: 24px; } }
  @media (max-width: 991px) {
    .article-single--alt img {
      width: 100%; } }
  .article-single--alt .article__content {
    margin: 182px 82px 0 0; }
    @media (max-width: 991px) {
      .article-single--alt .article__content {
        max-width: 99% !important;
        margin-left: 0 !important; } }

.article-single--vashi {
  align-items: flex-start; }
  .article-single--vashi .article__content {
    margin-top: 55px; }
    .article-single--vashi .article__content h4 {
      margin-bottom: 40px; }
    @media (max-width: 991px) {
      .article-single--vashi .article__content p {
        margin-bottom: 20px !important; } }
  @media (max-width: 991px) {
    .article-single--vashi .article__image {
      margin-bottom: 37px !important; } }
  @media (max-width: 991px) {
    .article-single--vashi .article__image {
      margin-bottom: 22px !important; } }

/* ------------------------------------------------------------ *\
	Article Small
\* ------------------------------------------------------------ */
.article-small .article__title {
  font-weight: 300;
  line-height: 1.31; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .article-small .article__title {
      font-size: 24px;
      line-height: 1.42; } }

.article-small .article__head {
  margin-bottom: 27px; }
  @media (max-width: 991px) {
    .article-small .article__head {
      max-width: 320px;
      margin-bottom: 15px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .article-small .article__head {
      margin-bottom: 23px; } }

.article-small .article__entry {
  max-width: 384px; }
  @media (max-width: 991px) {
    .article-small .article__entry {
      max-width: 303px; } }

.article-small .article__foot {
  margin-top: 54px; }
  @media (max-width: 991px) {
    .article-small .article__foot {
      max-width: 100%;
      margin-top: 0; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .article-small .article__foot {
      margin-top: 20px; } }

@media (max-width: 991px) {
  .article-small {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; } }

/* ------------------------------------------------------------ *\
	Article Small Alt
\* ------------------------------------------------------------ */
@media (max-width: 991px) {
  .article-small--alt .article__head {
    max-width: 265px; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .article-small--alt .article__head {
    max-width: 100%; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .article-small--alt .article__entry {
    max-width: 100%; } }

@media (max-width: 991px) {
  .article-small--alt .article__title {
    font-size: 24px;
    line-height: 1.17; } }

@media (max-width: 991px) {
  .article-small--alt .article__foot {
    max-width: 303px;
    width: 100%;
    margin-left: auto;
    margin-top: 37px; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .article-small--alt .article__foot {
    margin-left: 0;
    margin-top: 46px; } }

.article-small--topps .article__title {
  max-width: 500px; }
  @media (max-width: 991px) {
    .article-small--topps .article__title {
      display: none; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .article-small--topps .article__title {
      display: block; } }

.article-small--topps .article__entry {
  max-width: 460px; }
  @media (max-width: 991px) {
    .article-small--topps .article__entry {
      max-width: 100%;
      display: flex;
      flex-wrap: wrap; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .article-small--topps .article__entry {
      display: block; } }
  @media (max-width: 991px) {
    .article-small--topps .article__entry .article__title {
      display: block;
      width: 50%;
      font-size: 22px;
      line-height: 1.27;
      order: -4;
      max-width: 270px;
      margin-right: 15px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .article-small--topps .article__entry .article__title {
      display: none; } }
  @media (max-width: 991px) {
    .article-small--topps .article__entry p {
      width: 50%;
      max-width: 285px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .article-small--topps .article__entry p {
      width: 100%;
      max-width: 100%; } }
  @media (max-width: 991px) {
    .article-small--topps .article__entry p:nth-child(2) {
      order: -2; } }
  @media (max-width: 991px) {
    .article-small--topps .article__entry p + p {
      margin-left: 40px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .article-small--topps .article__entry p + p {
      margin-left: 0; } }

.article-small--vashi .article__head {
  max-width: 330px; }

.article-small--vashi .article__entry {
  max-width: 420px; }
  @media (max-width: 991px) {
    .article-small--vashi .article__entry {
      max-width: 303px; } }

/* ------------------------------------------------------------ *\
	Article
\* ------------------------------------------------------------ */
.article .article__head {
  max-width: 624px;
  margin: 0 auto 63px; }
  @media (max-width: 1440px) {
    .article .article__head {
      max-width: 660px; } }
  @media (max-width: 991px) {
    .article .article__head {
      max-width: 83%;
      margin: 0 auto 30px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .article .article__head {
      margin: 0 0 45px; } }

.article .article__title {
  font-size: 55px;
  font-weight: 300;
  line-height: 1.16; }
  @media (max-width: 991px) {
    .article .article__title {
      font-size: 30px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .article .article__title {
      font-size: 28px;
      line-height: 1.21; } }

.article .article__subtitle {
  font-weight: 300;
  line-height: 1.33;
  margin-bottom: 33px; }
  @media (max-width: 991px) {
    .article .article__subtitle {
      margin-bottom: 15px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .article .article__subtitle {
      line-height: 1.5;
      margin-bottom: 31px; } }

.article .article__entry p {
  font-size: 16px;
  line-height: 1.75; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .article .article__entry p {
      font-size: 14px;
      line-height: 1.78;
      margin-bottom: 0; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .article .article__entry p + p {
    margin-top: 34px; } }

.article .article__group {
  max-width: 624px;
  margin: auto; }
  @media (max-width: 1440px) {
    .article .article__group {
      max-width: 660px; } }
  @media (max-width: 991px) {
    .article .article__group {
      max-width: 83%; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .article .article__group {
      margin: 0;
      max-width: 92%; } }

.article .article__group--medium {
  max-width: 816px; }
  @media (max-width: 1440px) {
    .article .article__group--medium {
      max-width: 740px; } }

.article .article__group--small {
  max-width: 528px; }
  @media (max-width: 1440px) {
    .article .article__group--small {
      max-width: 506px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .article .article__group--small {
      margin: auto;
      max-width: 88%; } }

.article .article__group--large {
  max-width: 100%; }
  @media (max-width: 1440px) {
    .article .article__group--large {
      max-width: 974px; } }

.article .article__images {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .article .article__images {
      flex-direction: column; } }

.article .article__image + .article__image {
  margin-top: 117px; }
  @media (max-width: 1440px) {
    .article .article__image + .article__image {
      margin-top: 128px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .article .article__image + .article__image {
      margin-top: 40px; } }

.article .article__image img {
  width: 100%; }

@media (max-width: 1440px) {
  .article .article__image--half {
    width: 48%; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .article .article__image--half {
    width: 100%; } }

.article .article__image--bg {
  padding-bottom: 56%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .article .article__image--bg {
      padding-bottom: 83%;
      position: relative;
      width: 100vw;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw; } }

.article .article__group + .article__group {
  margin-top: 64px; }
  @media (max-width: 991px) {
    .article .article__group + .article__group {
      margin-top: 30px; } }
  @media (max-width: 991px) {
    .article .article__group + .article__group {
      margin-top: 45px; } }

.article .article__body ul li {
  margin-left: 40px; }

/* ------------------------------------------------------------ *\
	Articles Blog
\* ------------------------------------------------------------ */
.articles-blog {
  display: flex;
  flex-wrap: wrap;
  margin: -24px -20px -43px; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .articles-blog {
      display: block; } }

/* ------------------------------------------------------------ *\
	Articles Olivers
\* ------------------------------------------------------------ */
.articles-olivers .article-single {
  margin-bottom: 142px;
  padding: 0 54px 0 41px;
  justify-content: space-between; }
  @media (max-width: 991px) {
    .articles-olivers .article-single {
      margin-bottom: 80px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .articles-olivers .article-single {
      margin-bottom: 126px; } }
  .articles-olivers .article-single .article__content {
    margin-top: -20px; }

.articles-olivers .article-single:first-child {
  align-items: flex-start; }
  .articles-olivers .article-single:first-child .article__content {
    margin-top: 97px; }

@media (max-width: 991px) {
  .articles-olivers .article-single:nth-child(2) .article__image {
    text-align: center; } }

@media (max-width: 991px) {
  .articles-olivers .article-single:nth-child(2) .article__image img {
    width: 81%; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .articles-olivers .article-single:nth-child(2) .article__image img {
    width: 100%; } }

.articles-olivers .article-single:last-child {
  padding-right: 120px; }

.articles-olivers .article-single--reversed {
  padding-left: 155px; }

.articles-olivers .article-single:last-child {
  margin-bottom: 0; }

/* ------------------------------------------------------------ *\
	Articles Agent
\* ------------------------------------------------------------ */
.articles-agent {
  padding: 130px 0 47px; }
  @media (max-width: 991px) {
    .articles-agent {
      padding: 25px 0 37px; } }
  .articles-agent .article-single {
    margin-bottom: 182px;
    padding: 0 16px 0 72px;
    justify-content: space-between; }
    .articles-agent .article-single .article__content {
      margin-top: 0; }
      .articles-agent .article-single .article__content p {
        letter-spacing: 0; }
    @media (max-width: 991px) {
      .articles-agent .article-single .article__image {
        margin-bottom: 65px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .articles-agent .article-single .article__image {
        margin-bottom: 40px; } }
    @media (max-width: 991px) {
      .articles-agent .article-single {
        margin-bottom: 106px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .articles-agent .article-single {
        margin-bottom: 148px; } }
  .articles-agent .article-single:first-child {
    justify-content: space-evenly;
    padding-left: 0; }
    .articles-agent .article-single:first-child .article__content {
      margin: -40px 0 0 0; }
  @media (max-width: 991px) {
    .articles-agent .article-single:nth-child(2) {
      margin-bottom: 150px; } }
  .articles-agent .article-single:nth-child(2) .article__content {
    margin: -30px 79px 0 0; }
  @media (max-width: 991px) {
    .articles-agent .article-single:nth-child(2) .article__image {
      text-align: center;
      margin-bottom: 26px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .articles-agent .article-single:nth-child(2) .article__image {
      margin-bottom: 20px; } }
  @media (max-width: 991px) {
    .articles-agent .article-single:nth-child(2) .article__image img {
      width: 100%; } }
  .articles-agent .article-single:nth-child(3) {
    align-items: flex-start;
    margin-bottom: 273px; }
    @media (max-width: 991px) {
      .articles-agent .article-single:nth-child(3) {
        margin-bottom: 120px; } }
    .articles-agent .article-single:nth-child(3) .article__content {
      margin-top: 118px; }
      @media (max-width: 1440px) {
        .articles-agent .article-single:nth-child(3) .article__content {
          margin-top: 30px; } }
      .articles-agent .article-single:nth-child(3) .article__content h4 {
        letter-spacing: -0.1px; }
    .articles-agent .article-single:nth-child(3) .article__image img {
      height: 408px;
      width: auto; }
      @media (max-width: 1440px) {
        .articles-agent .article-single:nth-child(3) .article__image img {
          width: 100%;
          height: auto; } }
    @media (max-width: 991px) {
      .articles-agent .article-single:nth-child(3) .article__image {
        text-align: center; } }
    @media (max-width: 991px) {
      .articles-agent .article-single:nth-child(3) .article__image img {
        width: 81%;
        height: auto; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .articles-agent .article-single:nth-child(3) .article__image img {
        width: 100%; } }
  .articles-agent .article-single:nth-child(4) {
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 0 23px;
    margin-bottom: 265px; }
    @media (max-width: 991px) {
      .articles-agent .article-single:nth-child(4) {
        margin-bottom: 98px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .articles-agent .article-single:nth-child(4) {
        margin-bottom: 141px; } }
    @media (max-width: 991px) {
      .articles-agent .article-single:nth-child(4) .article__image {
        margin-bottom: 92px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .articles-agent .article-single:nth-child(4) .article__image {
        margin-bottom: 70px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .articles-agent .article-single:nth-child(4) .article__image img {
        width: 85%;
        height: auto; } }
    .articles-agent .article-single:nth-child(4) .article__content {
      margin-top: 157px; }
  .articles-agent .article-single:last-child {
    padding: 0 120px 0 80px;
    align-items: flex-start; }
    @media (max-width: 991px) {
      .articles-agent .article-single:last-child .article__image {
        margin-bottom: 30px; } }
    .articles-agent .article-single:last-child .article__content {
      margin-top: 85px; }
  .articles-agent .article-single--reversed {
    padding-left: 97px; }
  .articles-agent .article-single:last-child {
    margin-bottom: 0; }

/* ------------------------------------------------------------ *\
	Articles RHS
\* ------------------------------------------------------------ */
.articles-rhs {
  padding-top: 30px; }
  @media (max-width: 991px) {
    .articles-rhs {
      padding-top: 0; } }
  .articles-rhs .article-single {
    margin-bottom: 136px; }
    @media (max-width: 991px) {
      .articles-rhs .article-single {
        margin-bottom: 150px; } }
    .articles-rhs .article-single .article__content p {
      letter-spacing: 0; }
  .articles-rhs .article-single:first-child {
    margin-bottom: 181px;
    padding-left: 100px;
    align-items: flex-start; }
    @media (max-width: 991px) {
      .articles-rhs .article-single:first-child {
        margin-bottom: 100px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .articles-rhs .article-single:first-child {
        margin-bottom: 142px; } }
    .articles-rhs .article-single:first-child .article__content {
      margin: 90px 80px 0 0; }
      @media (max-width: 1199px) {
        .articles-rhs .article-single:first-child .article__content {
          margin-top: 50px; } }
      .articles-rhs .article-single:first-child .article__content h4 {
        max-width: 220px; }
        @media (max-width: 991px) {
          .articles-rhs .article-single:first-child .article__content h4 {
            max-width: 100%; } }
    @media (max-width: 991px) {
      .articles-rhs .article-single:first-child .article__image {
        margin-bottom: 26px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .articles-rhs .article-single:first-child .article__image {
        margin-bottom: 18px; } }
    @media (max-width: 991px) {
      .articles-rhs .article-single:first-child .article__image img {
        width: 100%; } }
  .articles-rhs .article-single:nth-child(2) {
    padding: 0 107px 0 38px;
    align-items: flex-start; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .articles-rhs .article-single:nth-child(2) {
        margin-bottom: 130px; } }
    .articles-rhs .article-single:nth-child(2) .article__content {
      margin-top: 145px; }
      @media (max-width: 1199px) {
        .articles-rhs .article-single:nth-child(2) .article__content {
          margin-top: 100px; } }
    @media (max-width: 991px) {
      .articles-rhs .article-single:nth-child(2) .article__image img {
        width: 64%;
        height: auto; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .articles-rhs .article-single:nth-child(2) .article__image img {
        width: 80%; } }
  .articles-rhs .article-single:nth-child(3) {
    align-items: flex-start; }
    @media (max-width: 991px) {
      .articles-rhs .article-single:nth-child(3) {
        margin-bottom: 110px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .articles-rhs .article-single:nth-child(3) {
        margin-bottom: 140px; } }
    .articles-rhs .article-single:nth-child(3) .article__content {
      margin: 197px 99px 0 0; }
      @media (max-width: 1199px) {
        .articles-rhs .article-single:nth-child(3) .article__content {
          margin-top: 120px; } }
    @media (max-width: 991px) {
      .articles-rhs .article-single:nth-child(3) .article__image img {
        width: 64%;
        height: auto; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .articles-rhs .article-single:nth-child(3) .article__image img {
        width: 82%; } }
  .articles-rhs .article-single:nth-child(4) {
    align-items: flex-start;
    padding: 8px 119px 0 38px; }
    @media (max-width: 991px) {
      .articles-rhs .article-single:nth-child(4) {
        margin-bottom: 0; } }
    .articles-rhs .article-single:nth-child(4) .article__content {
      margin: 203px 99px 0 0; }
      @media (max-width: 1199px) {
        .articles-rhs .article-single:nth-child(4) .article__content {
          margin-top: 50px; } }
    @media (max-width: 991px) {
      .articles-rhs .article-single:nth-child(4) .article__image {
        margin-bottom: 60px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .articles-rhs .article-single:nth-child(4) .article__image {
        margin-bottom: 38px; } }

/* ------------------------------------------------------------ *\
	Articles topps
\* ------------------------------------------------------------ */
@media (max-width: 991px) {
  .articles-topps {
    padding-top: 0; } }

.articles-topps .article-single .article__content p {
  letter-spacing: 0; }

.articles-topps .article-single:first-child {
  max-width: 740px;
  width: 100%;
  margin: 0 auto 222px;
  display: block; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .articles-topps .article-single:first-child {
      margin-bottom: 120px; } }
  .articles-topps .article-single:first-child .article__content {
    max-width: 100% !important;
    padding-left: 0;
    margin-top: 0; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .articles-topps .article-single:first-child h3 {
      line-height: 1.3; } }
  .articles-topps .article-single:first-child .article__image {
    margin-bottom: 29px; }
    @media (max-width: 991px) {
      .articles-topps .article-single:first-child .article__image {
        margin-bottom: 53px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .articles-topps .article-single:first-child .article__image {
        margin-bottom: 26px; } }
    @media (max-width: 991px) {
      .articles-topps .article-single:first-child .article__image img {
        width: 100%; } }

.articles-topps .article-single:nth-child(2) {
  padding: 0 107px 0 38px;
  align-items: flex-start;
  margin-bottom: 100px; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .articles-topps .article-single:nth-child(2) {
      margin-bottom: 130px; } }
  .articles-topps .article-single:nth-child(2) .article__content {
    margin: 75px 32px 0 0; }
    @media (max-width: 1199px) {
      .articles-topps .article-single:nth-child(2) .article__content {
        margin-top: 50px; } }
  .articles-topps .article-single:nth-child(2) .article__image img {
    max-width: 544px; }
    @media (max-width: 991px) {
      .articles-topps .article-single:nth-child(2) .article__image img {
        max-width: 100%;
        height: auto; } }

.articles-topps .article-single:nth-child(3) {
  padding: 0 40px;
  margin-bottom: 99px; }
  @media (max-width: 991px) {
    .articles-topps .article-single:nth-child(3) {
      margin-bottom: 148px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .articles-topps .article-single:nth-child(3) {
      margin-bottom: 130px; } }
  .articles-topps .article-single:nth-child(3) .article__content {
    margin: 0 99px 25px 0; }
    @media (max-width: 1199px) {
      .articles-topps .article-single:nth-child(3) .article__content {
        margin-top: 120px; } }
  @media (max-width: 991px) {
    .articles-topps .article-single:nth-child(3) .article__image {
      margin-bottom: 24px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .articles-topps .article-single:nth-child(3) .article__image {
      margin-bottom: 12px; } }
  @media (max-width: 991px) {
    .articles-topps .article-single:nth-child(3) .article__image img {
      max-width: 100%;
      height: auto; } }

.articles-topps .article-single:nth-child(4) {
  padding: 0 0 0 38px;
  margin-bottom: 175px; }
  @media (max-width: 991px) {
    .articles-topps .article-single:nth-child(4) {
      margin-bottom: 146px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .articles-topps .article-single:nth-child(4) {
      margin-bottom: 128px; } }
  .articles-topps .article-single:nth-child(4) .article__content {
    margin: 0 178px 28px 0; }
    @media (max-width: 1199px) {
      .articles-topps .article-single:nth-child(4) .article__content {
        margin-top: 50px; } }
  @media (max-width: 991px) {
    .articles-topps .article-single:nth-child(4) .article__image {
      margin-bottom: 24px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .articles-topps .article-single:nth-child(4) .article__image {
      margin-bottom: 16px; } }
  @media (max-width: 991px) {
    .articles-topps .article-single:nth-child(4) .article__image img {
      width: 100%; } }

.articles-topps .article-single:nth-child(5) {
  padding: 0 42px;
  margin-bottom: 40px; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .articles-topps .article-single:nth-child(5) {
      margin-bottom: 17px; } }
  .articles-topps .article-single:nth-child(5) .article__content {
    margin: 0 178px 28px 0; }
    @media (max-width: 1199px) {
      .articles-topps .article-single:nth-child(5) .article__content {
        margin-top: 50px; } }
  @media (max-width: 991px) {
    .articles-topps .article-single:nth-child(5) .article__image {
      margin-bottom: 49px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .articles-topps .article-single:nth-child(5) .article__image {
      margin-bottom: 14px; } }

/* ------------------------------------------------------------ *\
	Articles Vashi 
\* ------------------------------------------------------------ */
.articles-vashi .article-single:first-child {
  align-items: flex-start;
  padding: 0 42px 135px 158px; }
  @media (max-width: 991px) {
    .articles-vashi .article-single:first-child {
      margin-bottom: 152px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .articles-vashi .article-single:first-child {
      margin-bottom: 156px; } }
  .articles-vashi .article-single:first-child .article__content {
    margin-top: 63px; }

.articles-vashi .article-single:nth-child(2) {
  align-items: flex-start;
  padding: 0 74px 152px 39px; }
  @media (max-width: 991px) {
    .articles-vashi .article-single:nth-child(2) {
      margin-bottom: 144px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .articles-vashi .article-single:nth-child(2) {
      margin-bottom: 127px; } }
  .articles-vashi .article-single:nth-child(2) .article__content {
    margin: 78px 74px 0 0;
    max-width: 340px; }
    @media (max-width: 1199px) {
      .articles-vashi .article-single:nth-child(2) .article__content {
        margin-top: 50px; } }

.articles-vashi .article-single:nth-child(3) {
  align-items: flex-start;
  padding: 0px 36px 216px 159px; }
  @media (max-width: 991px) {
    .articles-vashi .article-single:nth-child(3) {
      margin-bottom: 147px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .articles-vashi .article-single:nth-child(3) {
      margin-bottom: 125px; } }
  .articles-vashi .article-single:nth-child(3) .article__content {
    margin: 100px 0 0 0; }
    .articles-vashi .article-single:nth-child(3) .article__content p {
      max-width: 290px; }
      @media (max-width: 991px) {
        .articles-vashi .article-single:nth-child(3) .article__content p {
          max-width: 100%; } }
  @media (max-width: 991px) {
    .articles-vashi .article-single:nth-child(3) .article__image {
      margin-bottom: 28px; } }

.articles-vashi .article-single:nth-child(4) {
  align-items: flex-start;
  padding: 0px 136px 26px 38px; }
  @media (max-width: 991px) {
    .articles-vashi .article-single:nth-child(4) {
      margin-bottom: 40px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .articles-vashi .article-single:nth-child(4) {
      margin-bottom: 20px; } }
  .articles-vashi .article-single:nth-child(4) .article__content {
    margin: 67px 0 0 0; }
  @media (max-width: 991px) {
    .articles-vashi .article-single:nth-child(4) .article__image {
      margin-bottom: 28px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .articles-vashi .article-single:nth-child(4) .article__image {
      margin-bottom: 17px; } }

@media (max-width: 1199px) {
  .articles-vashi .article-single {
    padding: 0 40px 135px 40px !important; } }

@media (max-width: 991px) {
  .articles-vashi .article-single {
    padding: 0  !important; } }

@media (max-width: 991px) {
  .articles-vashi .article-single .article__image img {
    max-width: 100%;
    width: 100%;
    height: auto; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .articles-vashi .article-single .article__image {
    margin-bottom: 27px; } }

/* ------------------------------------------------------------ *\
	Block Project
\* ------------------------------------------------------------ */
.block-project {
  position: relative; }
  .block-project > a {
    position: absolute;
    top: 0;
    bottom: 0;
    display: block;
    left: 0;
    right: 0;
    z-index: 5; }
  .block-project .block__image {
    padding-bottom: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 22px; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .block-project .block__image {
        margin-bottom: 19px; } }
  .block-project .block__foot {
    text-align: center;
    position: relative;
    padding-bottom: 52px; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .block-project .block__foot {
        padding-bottom: 0; } }
  .block-project .block__info {
    color: #e12954;
    letter-spacing: 0.5px;
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s, bottom 0.3s; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .block-project .block__info {
        display: none; } }
  .block-project:hover .block__info {
    bottom: 0;
    opacity: 1;
    visibility: visible; }

.block-project--alt {
  margin-top: 120px; }
  @media (max-width: 991px) {
    .block-project--alt {
      margin-top: 70px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .block-project--alt {
      margin-top: 32px; } }

.block-project--slider .block__partner {
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center; }

.block-project--slider .block__image {
  margin-bottom: 14px; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .block-project--slider .block__image {
      margin-bottom: 6px; } }

/* ------------------------------------------------------------ *\
	Block
\* ------------------------------------------------------------ */
.blocks {
  padding-bottom: 86px; }
  .blocks .blocks__items {
    display: flex;
    justify-content: center; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .blocks .blocks__items {
        flex-direction: column; } }
  .blocks .blocks__item {
    width: 47%; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .blocks .blocks__item {
        width: 100%; } }
  .blocks .blocks__item + .blocks__item {
    margin-left: 80px; }
    @media (max-width: 991px) {
      .blocks .blocks__item + .blocks__item {
        margin-left: 40px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .blocks .blocks__item + .blocks__item {
        margin-left: 0; } }
  @media (max-width: 991px) {
    .blocks {
      padding-bottom: 0; } }

/* Blocks Alt */
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .blocks--alt {
    padding-bottom: 100px; } }

.block {
  position: relative;
  transition: opacity 0.3s;
  background-color: #f7f4f1;
  padding-bottom: 100%;
  position: relative; }
  .block > a {
    position: absolute;
    top: 0;
    bottom: 0;
    display: block;
    left: 0;
    right: 0;
    z-index: 5; }
  .block .block__inner {
    position: absolute;
    bottom: 160px;
    right: 75px;
    text-align: right; }
    @media (max-width: 1199px) {
      .block .block__inner {
        right: 20px; } }
    @media (max-width: 991px) {
      .block .block__inner {
        right: 48px;
        bottom: 120px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .block .block__inner {
        right: auto;
        left: 37px;
        text-align: left;
        bottom: 83px; } }
  .block .block__title {
    font-weight: 300; }
    @media (max-width: 991px) {
      .block .block__title {
        letter-spacing: 0.5px; } }
  .block .block__text {
    position: absolute;
    top: -81px;
    left: -8px; }
    @media (max-width: 991px) {
      .block .block__text {
        top: -53px;
        left: 14px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .block .block__text {
        left: auto;
        right: -24px;
        top: -49px; } }
  .block .block__icon {
    position: absolute;
    top: 31px;
    right: -1px;
    width: 25px;
    height: 43px; }
    @media (max-width: 991px) {
      .block .block__icon {
        width: 15px;
        height: 26px;
        top: 23px;
        right: 27px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .block .block__icon {
        top: 27px;
        right: 41px; } }
  .block .block__icon img {
    transform: rotate(-62deg); }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .block .block__icon img {
        transform: scale(-1, 1) rotate(-62deg); } }
  .block .block__text span {
    font: 400 24px/1 "Nanum Pen Script", cursive; }
    @media (max-width: 991px) {
      .block .block__text span {
        font-size: 16px;
        letter-spacing: 1px; } }
  .block .block__highlight-ico {
    left: -8px !important; }
    @media (max-width: 991px) {
      .block .block__highlight-ico {
        left: -1px !important; } }
  .block:hover {
    opacity: 0.7; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .block {
      padding-bottom: 75%; } }

/* Block Alt */
.block--alt {
  margin-top: 149px;
  background-color: #eff3f3; }
  .block--alt .block__inner {
    right: auto;
    left: 92px;
    bottom: 166px;
    text-align: left; }
    @media (max-width: 1199px) {
      .block--alt .block__inner {
        left: 15px;
        bottom: 116px; } }
    @media (max-width: 991px) {
      .block--alt .block__inner {
        left: 42px;
        bottom: 135px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .block--alt .block__inner {
        bottom: 99px; } }
  .block--alt .block__text {
    top: auto;
    bottom: -63px;
    left: auto;
    right: -134px; }
    @media (max-width: 1199px) {
      .block--alt .block__text {
        bottom: -83px;
        right: -124px; } }
    @media (max-width: 991px) {
      .block--alt .block__text {
        bottom: -47px;
        right: -74px; } }
  .block--alt .block__icon {
    top: -45px;
    right: auto;
    left: 8px; }
    @media (max-width: 991px) {
      .block--alt .block__icon {
        top: -33px;
        left: 23px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .block--alt .block__icon {
        left: 19px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .block--alt .block__icon img {
      transform: scale(1, 1) rotate(-62deg); } }
  .block--alt .block__highlight-ico {
    left: 21px !important; }
    @media (max-width: 991px) {
      .block--alt .block__highlight-ico {
        left: 0 !important; } }
  @media (max-width: 991px) {
    .block--alt {
      margin-top: 63px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .block--alt {
      margin-top: 20px; } }

/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */
.btn {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  appearance: none;
  letter-spacing: 0.5px;
  padding: 12px 15px 13px;
  border: 1px solid;
  border-radius: 28px;
  min-width: 132px;
  position: relative;
  transition: all 0.35s ease;
  transform: translateZ(0);
  z-index: 2;
  overflow: hidden; }
  .btn:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 0;
    transition: width 0.3s;
    z-index: -1;
    border-radius: 28px; }
  .btn:hover:before {
    width: 100%;
    transition: width 0.5s cubic-bezier(0.52, 1.64, 0.37, 0.66); }
  .btn:focus, .btn.focus {
    box-shadow: none; }

/* Button Primary */
.btn--primary {
  background-color: transparent;
  border-color: #e12954;
  color: #e12954;
  /* &.is-align {
		position: static;
	} */ }
  .btn--primary:before {
    background-color: #e12954; }
  .btn--primary:hover {
    color: #fff; }
  .btn--primary.is-active {
    /* position: fixed;
		top: 32px;
		right: 29px; */
    color: #fff; }
    .btn--primary.is-active:before {
      width: 100%;
      transition: width 0.5s cubic-bezier(0.52, 1.64, 0.37, 0.66); }
    .btn--primary.is-active:hover {
      color: #e12954; }
    .btn--primary.is-active:hover:before {
      width: 0; }

/* Button Secondary */
.btn--secondary {
  background-color: #e12954;
  border-color: #e12954;
  color: #fff; }
  .btn--secondary:before {
    background-color: #fff; }
  .btn--secondary:hover {
    color: #e12954; }

/*  Btn block  */
.btn--block {
  display: block;
  padding-left: 0;
  padding-right: 0; }

/* ------------------------------------------------------------ *\
	Category
\* ------------------------------------------------------------ */
.category-md:after {
  background: url("/../images/ellipses/ellipse-md.png");
  width: 74px;
  height: 49px; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .category-md:after {
      background-size: 70px 38px; } }

.category-lg:after {
  background: url("/../images/ellipses/ellipse-lg.png");
  width: 92px; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .category-lg:after {
      background-size: auto 38px; } }

.category-xl:after {
  background: url("/../images/ellipses/ellipse-xl.png");
  width: 96px; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .category-xl:after {
      background-size: auto 38px; } }

.category-sm:after {
  background: url("/../images/ellipses/ellipse-sm.png");
  width: 55px; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .category-sm:after {
      background-size: auto 38px; } }

/* ------------------------------------------------------------ *\
	Checkbox
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Circle
\* ------------------------------------------------------------ */
.circle {
  position: absolute;
  left: -556px;
  top: -310px;
  z-index: -1; }
  .circle .circle__ico {
    width: 1167px;
    height: 1120px; }
    @media (max-width: 1440px) {
      .circle .circle__ico {
        width: 976px;
        height: 937px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .circle .circle__ico {
        width: 822px;
        height: 789px; } }
  .circle .circle__ico svg {
    display: block;
    width: 100%;
    height: 100%; }
  @media (min-width: 2500px) {
    .circle {
      display: none; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .circle {
      top: -181px;
      left: -611px; } }

/* Circle 1 */
.circle--1 {
  top: -310px;
  left: -620px; }
  .circle--1 .circle__ico {
    width: 965px;
    height: 926px; }
    @media (max-width: 1440px) {
      .circle--1 .circle__ico {
        width: 607px;
        height: 583px; } }
    @media (max-width: 991px) {
      .circle--1 .circle__ico {
        width: 398px;
        height: 382px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .circle--1 .circle__ico {
        width: 355px;
        height: 365px; } }
  @media (max-width: 1440px) {
    .circle--1 {
      top: 258px;
      left: -357px; } }
  @media (max-width: 991px) {
    .circle--1 {
      top: 207px;
      left: -255px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .circle--1 {
      top: -124px;
      left: -252px; } }

.circle--2 {
  top: -93px;
  left: -208px; }
  .circle--2 .circle__ico {
    width: 374px;
    height: 360px; }

.circle--3 {
  top: -240px;
  right: -216px;
  left: auto;
  z-index: 1; }
  .circle--3 .circle__ico {
    width: 976px;
    height: 937px; }
    @media (max-width: 991px) {
      .circle--3 .circle__ico {
        width: 312px;
        height: 300px; } }
  @media (max-width: 1440px) {
    .circle--3 {
      right: -404px; } }
  @media (max-width: 991px) {
    .circle--3 {
      top: -94px;
      right: -90px; } }

.circle--4 {
  top: -310px;
  left: -310px; }
  .circle--4 .circle__ico {
    width: 916px;
    height: 880px; }
    @media (max-width: 991px) {
      .circle--4 .circle__ico {
        width: 401px;
        height: 386px; } }
  @media (max-width: 991px) {
    .circle--4 {
      top: -100px;
      left: -220px; } }

/* Circle Fixed */
.circle--fixed {
  position: fixed; }

/* ------------------------------------------------------------ *\
	Fields
\* ------------------------------------------------------------ */
/*  Field textarea  */
/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */
.footer {
  position: relative;
  z-index: 5; }
  .footer .footer__shell {
    min-height: 65px;
    max-width: 1680px;
    padding-left: 111px;
    padding-right: 111px;
    display: flex;
    align-items: center; }
    @media (max-width: 1440px) {
      .footer .footer__shell {
        padding-left: 31px;
        padding-right: 31px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .footer .footer__shell {
        padding-left: 20px;
        padding-right: 20px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .footer {
      padding-bottom: 16px; } }

/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */
.form .form__head {
  margin-bottom: 48px; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .form .form__head {
      margin-bottom: 28px; } }

.form .form__title {
  font-weight: 300;
  line-height: 1.31; }
  @media (max-width: 991px) {
    .form .form__title {
      font-size: 24px;
      line-height: 1.42; } }

.form .form__controls {
  position: relative;
  width: 81%; }
  @media (max-width: 1440px) {
    .form .form__controls {
      width: 100%; } }

.form .form__field {
  font-size: 16px;
  color: #222;
  border: 1px solid #d4d4d4;
  padding: 13px 17px 14px;
  background-color: transparent;
  width: 100%;
  border-radius: 0; }
  .form .form__field:-ms-input-placeholder, .form .form__field::placeholder {
    color: #949494; }

.form .form__field--textarea {
  resize: none;
  min-height: 178px; }
  .form .form__field--textarea:-ms-input-placeholder {
    color: #949494; }

.form .form__label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 14px;
  color: #949494;
  font-size: 16px;
  padding: 0 4px;
  line-height: 1;
  transition: top 0.3s ease-in-out, font-size 0.3s ease-in-out;
  background-color: #f7f4f1;
  margin-bottom: 0; }
  .form .form__label.is-active {
    top: 1px;
    font-size: 12px; }

.form .form__row + .form__row {
  margin-top: 32px; }

.form .form__actions {
  text-align: right;
  width: 81%;
  margin-top: 26px; }
  @media (max-width: 1440px) {
    .form .form__actions {
      width: 100%; } }

.form .form__btn {
  min-width: 168px; }

/* ------------------------------------------------------------ *\
	Grid
\* ------------------------------------------------------------ */
.grid {
  display: flex;
  flex-flow: row wrap;
  /*  Grid col 1of2  */ }
  .grid .grid__col {
    max-width: 100%;
    flex: 1; }
  .grid .grid__col--1of2 {
    max-width: 50%;
    flex: 0 0 50%; }

/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */
.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 32px 0;
  z-index: 50; }
  .header .header__shell {
    margin: auto;
    max-width: 1760px;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: max-width 0.6s; }
    @media (max-width: 1440px) {
      .header .header__shell {
        padding-left: 29px;
        padding-right: 29px; } }
    .header .header__shell.is-active {
      transition: max-width 0.6s;
      max-width: 680px; }
      @media (max-width: 991px) {
        .header .header__shell.is-active {
          max-width: 100%; } }
      @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
        .header .header__shell.is-active {
          padding-right: 3px; } }
    @media (max-width: 991px) {
      .header .header__shell {
        padding-left: 40px;
        padding-right: 33px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .header .header__shell {
        padding-left: 20px;
        padding-right: 24px; } }
  .header .header__aside {
    display: flex;
    align-items: center;
    /* &.is-active {
			padding-right: 132px;
		} */ }
    @media (max-width: 991px) {
      .header .header__aside {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100vh;
        background-color: #f9f7f3;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s, visibility 0.3s;
        flex-direction: column;
        justify-content: center; }
        .header .header__aside:after {
          content: '';
          position: absolute;
          left: -88px;
          top: -73px;
          width: 269px;
          height: 258px;
          background-image: url(../images/svg/circle-1.svg);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          /* animation: spin 10s linear infinite; */ }
        .header .header__aside.is-active {
          opacity: 1;
          visibility: visible; } }
  .header .header__slogan {
    font-weight: 300;
    position: relative;
    z-index: 6;
    opacity: 0;
    visibility: hidden;
    height: 0;
    /* transition: opacity 0.3s, visibility 0.3s; */ }
    .header .header__slogan.is-active {
      opacity: 1;
      visibility: visible;
      height: auto;
      transition: opacity 0.3s, visibility 0.3s; }
      @media (max-width: 991px) {
        .header .header__slogan.is-active {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%; } }
  .header .header__slogan h4 {
    margin-bottom: 0;
    font-weight: 300; }
  .header:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 0;
    z-index: 1;
    background-color: #fff;
    box-shadow: 0 0 15px rgba(220, 220, 220, 0.5);
    transition: height 0.7s; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .header:after {
        transition: height 0.3s; } }
  .header.is-fixed {
    position: fixed;
    transition: all 0.3s; }
    .header.is-fixed:after {
      height: 100%;
      transition: height 0.7s; }
  .header.is-active {
    position: fixed; }
  .header.is-active .nav,
  .header.is-active .logo {
    opacity: 0; }
  @media (max-width: 991px) {
    .header {
      padding: 9px 0; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .header {
      padding: 9px 0; } }

/* ------------------------------------------------------------ *\
	Highlight
\* ------------------------------------------------------------ */
.highlight {
  position: relative;
  display: inline-block; }
  .highlight .highlight__ico {
    position: absolute;
    top: 101%;
    left: -21px;
    overflow: hidden;
    width: 0;
    transition: width 1s;
    height: 15px;
    display: block; }
    @media (max-width: 991px) {
      .highlight .highlight__ico {
        left: 0; } }
  .highlight .highlight__ico svg {
    position: absolute;
    top: 0;
    left: 0;
    /* width: 100%; */ }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .highlight--1 .highlight__ico {
    margin-top: -8px; } }

@media (max-width: 991px) {
  .highlight--2 .highlight__ico {
    margin-top: -3px; } }

@media (max-width: 991px) {
  .highlight--3 .highlight__ico {
    margin-top: -3px; } }

.highlight--4 .highlight__ico {
  margin-top: -4px;
  left: -25px; }
  @media (max-width: 991px) {
    .highlight--4 .highlight__ico {
      margin-top: -11px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .highlight--4 .highlight__ico {
      margin-top: -8px; } }

.highlight--5 .highlight__ico {
  margin-top: -5px;
  left: -5px; }
  @media (max-width: 1440px) {
    .highlight--5 .highlight__ico {
      margin-top: -8px; } }
  @media (max-width: 1440px) {
    .highlight--5 .highlight__ico.is-active {
      width: 79px !important; } }

.highlight--6 .highlight__ico {
  left: -4px;
  top: 88%; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .highlight--6 .highlight__ico {
      left: 7px;
      margin-top: -5px; } }

.highlight--7 .highlight__ico {
  left: -4px;
  margin-top: -1px; }

.highlight--8 .highlight__ico {
  left: -4px;
  margin-top: -5px; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .highlight--8 .highlight__ico {
      margin-top: 0; } }

.highlight--9 .highlight__ico {
  left: 2px;
  margin-top: -1px; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .highlight--9 .highlight__ico {
      margin-top: 0; } }

/* ------------------------------------------------------------ *\
	Icons
\* ------------------------------------------------------------ */
.ico-arrow {
  position: absolute;
  right: 15px;
  top: -43px; }
  @media (max-width: 1440px) {
    .ico-arrow {
      right: 45px; } }
  @media (max-width: 1199px) {
    .ico-arrow {
      right: 0; } }
  @media (max-width: 991px) {
    .ico-arrow {
      width: 32px;
      height: 45px;
      right: 170px;
      top: -33px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .ico-arrow {
      right: 20px;
      top: -47px; } }

.ico-mouse {
  position: absolute;
  bottom: -28px;
  left: -159px;
  width: 155px;
  height: 85px; }
  @media (max-width: 991px) {
    .ico-mouse {
      width: 99px;
      height: 54px;
      bottom: -32px;
      left: -89px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .ico-mouse {
      left: 100%;
      transform: scale(-1, 1); } }

.ico-bulb {
  position: absolute;
  top: -40px;
  right: 1px; }
  @media (max-width: 991px) {
    .ico-bulb {
      width: 38px;
      height: 53px;
      top: -34px;
      right: 9px; } }

.ico-arrow-small {
  display: inline-block;
  vertical-align: middle; }

.logo-oliver-bonas {
  width: 155px;
  height: 14px;
  display: inline-block; }
  .logo-oliver-bonas img {
    display: block;
    width: 100%;
    height: 100%; }
  @media (max-width: 991px) {
    .logo-oliver-bonas {
      width: 109px;
      height: 10px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .logo-oliver-bonas {
      width: 121px;
      height: 11px; } }

.ap-logo {
  width: 187px;
  height: 32px;
  display: inline-block; }
  .ap-logo img {
    display: block;
    width: 100%;
    height: 100%; }
  @media (max-width: 991px) {
    .ap-logo {
      width: 131px;
      height: 23px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .ap-logo {
      width: 146px;
      height: 25px; } }

.topps-logo {
  width: 130px;
  height: 28px;
  display: inline-block; }
  .topps-logo img {
    display: block;
    width: 100%;
    height: 100%; }
  @media (max-width: 991px) {
    .topps-logo {
      width: 91px;
      height: 19px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .topps-logo {
      width: 108px;
      height: 23px; } }

.logo-microsoft-dynamics {
  margin-top: -13px; }

.scouts-logo {
  width: 127px;
  height: 36px;
  display: inline-block;
  margin-top: -8px; }
  .scouts-logo img {
    display: block;
    width: 100%;
    height: 100%; }
  @media (max-width: 991px) {
    .scouts-logo {
      width: 89px;
      height: 25px; } }

.john-lobb-logo {
  width: 140px;
  height: 18px;
  display: inline-block; }
  .john-lobb-logo img {
    display: block;
    width: 100%;
    height: 100%; }
  @media (max-width: 991px) {
    .john-lobb-logo {
      width: 98px;
      height: 13px; } }

.lsa-logo {
  width: 91px;
  height: 50px;
  display: inline-block; }
  .lsa-logo img {
    display: block;
    width: 100%;
    height: 100%; }
  @media (max-width: 991px) {
    .lsa-logo {
      width: 64px;
      height: 34px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .lsa-logo {
      width: 71px;
      height: 38px; } }

.creed-logo {
  width: 112px;
  height: 44px;
  display: inline-block; }
  .creed-logo img {
    display: block;
    width: 100%;
    height: 100%; }
  @media (max-width: 991px) {
    .creed-logo {
      width: 78px;
      height: 31px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .creed-logo {
      width: 93px;
      height: 37px; } }

.logo-biscuiteers {
  width: 172px;
  height: 23px;
  display: inline-block; }
  .logo-biscuiteers img {
    display: block;
    width: 100%;
    height: 100%; }
  @media (max-width: 991px) {
    .logo-biscuiteers {
      width: 120px;
      height: 15px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .logo-biscuiteers {
      width: 132px;
      height: 17px; } }

.millies-logo {
  width: 127px;
  height: 58px;
  display: inline-block; }
  .millies-logo img {
    display: block;
    width: 100%;
    height: 100%; }
  @media (max-width: 991px) {
    .millies-logo {
      width: 89px;
      height: 40px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .millies-logo {
      width: 106px;
      height: 48px; } }

.pooky-logo {
  width: 136px;
  height: 55px;
  display: inline-block; }
  .pooky-logo img {
    display: block;
    width: 100%;
    height: 100%; }
  @media (max-width: 991px) {
    .pooky-logo {
      width: 95px;
      height: 39px; } }

.daisy-logo {
  width: 124px;
  height: 44px;
  display: inline-block; }
  .daisy-logo img {
    display: block;
    width: 100%;
    height: 100%; }
  @media (max-width: 991px) {
    .daisy-logo {
      width: 86px;
      height: 31px; } }

.em-logo {
  width: 160px;
  height: 32px;
  display: inline-block; }
  .em-logo img {
    display: block;
    width: 100%;
    height: 100%; }
  @media (max-width: 991px) {
    .em-logo {
      width: 112px;
      height: 23px; } }

.logo-oliver-bonas-large {
  width: 200px;
  height: 19px;
  display: inline-block; }
  .logo-oliver-bonas-large img {
    display: block;
    width: 100%;
    height: 100%; }
  @media (max-width: 991px) {
    .logo-oliver-bonas-large {
      width: 160px;
      height: 16px; } }

.topps-logo-large {
  width: 178px;
  height: 37px;
  display: inline-block; }
  .topps-logo-large img {
    display: block;
    width: 100%;
    height: 100%; }
  @media (max-width: 991px) {
    .topps-logo-large {
      width: 141px;
      height: 31px; } }

.topps-logo-small {
  width: 135px;
  height: 28px;
  display: inline-block; }
  .topps-logo-small img {
    display: block;
    width: 100%;
    height: 100%; }

.logo-oliver-bonas-small {
  width: 160px;
  height: 15px;
  display: inline-block; }
  .logo-oliver-bonas-small img {
    display: block;
    width: 100%;
    height: 100%; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .logo-oliver-bonas-small {
      width: 140px;
      height: 14px; } }

.pooky-logo-small {
  width: 99px;
  height: 40px;
  display: inline-block; }
  .pooky-logo-small img {
    display: block;
    width: 100%;
    height: 100%; }

.creed-logo-small {
  width: 93px;
  height: 37px;
  display: inline-block; }
  .creed-logo-small img {
    display: block;
    width: 100%;
    height: 100%; }

/* ------------------------------------------------------------ *\
	Link
\* ------------------------------------------------------------ */
.link {
  color: #e12954;
  font-weight: 600;
  text-decoration: none;
  transition: opacity 0.3s; }
  .link:hover {
    color: #e12954;
    opacity: 0.7; }

/* ------------------------------------------------------------ *\
	list basic
\* ------------------------------------------------------------ */
.list-basic ul {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none; }

.list-basic li {
  width: 20%;
  margin-bottom: 20px; }

.list-basic a {
  text-decoration: none; }

.list-basic--alt li {
  width: 33.33%; }

/* ------------------------------------------------------------ *\
	List Categories
\* ------------------------------------------------------------ */
.list-caregories {
  list-style-type: none;
  display: flex; }
  .list-caregories a {
    text-decoration: none;
    position: relative;
    z-index: 2;
    font-weight: 700;
    color: #D53657; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .list-caregories a {
        font-size: 12px; } }
  .list-caregories li {
    position: relative;
    z-index: 2;
    padding-left: 20px; }
  .list-caregories li:after {
    position: absolute;
    content: '';
    left: 0;
    top: -11px;
    height: 42px;
    background-repeat: no-repeat;
    z-index: 1; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .list-caregories li:after {
        height: 38px;
        top: -5px; } }
  .list-caregories li + li {
    padding-left: 50px; }
  .list-caregories li + li:after {
    left: 34px; }

/* ------------------------------------------------------------ *\
	List Contacts Alt
\* ------------------------------------------------------------ */
.list-contacts-alt {
  list-style-type: none; }
  .list-contacts-alt h5 {
    margin-bottom: 8px; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .list-contacts-alt h5 {
        font-size: 20px; } }
  .list-contacts-alt a {
    text-decoration: none;
    line-height: 1.71;
    transition: color 0.3s; }
    .list-contacts-alt a[href^='tel'] {
      text-decoration: none;
      color: #212121; }
    .list-contacts-alt a:hover {
      color: #e12954; }
  .list-contacts-alt address {
    line-height: 1.71; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .list-contacts-alt li {
      width: 47%;
      margin-bottom: 46px; } }
  .list-contacts-alt li + li {
    margin-top: 46px; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .list-contacts-alt li + li {
        margin-top: 0; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .list-contacts-alt {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; } }

/* ------------------------------------------------------------ *\
	List Contacts
\* ------------------------------------------------------------ */
.list-contacts {
  list-style-type: none; }
  .list-contacts li {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    padding-right: 9px;
    margin-right: 7px;
    border-right: 1px solid #212121; }
    .list-contacts li:last-child {
      padding-right: 0;
      margin-right: 0;
      border: none; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .list-contacts li {
        display: block;
        padding-right: 0;
        margin-right: 0;
        border: none;
        margin-bottom: 10px;
        font-size: 12px; } }
  .list-contacts a {
    text-decoration: none;
    transition: color 0.3s; }
    .list-contacts a:hover {
      color: #e12954; }
  .list-contacts span {
    display: inline-block;
    vertical-align: middle; }
  .list-contacts span + i {
    margin-left: 6px; }

/* ------------------------------------------------------------ *\
	List partners Alt
\* ------------------------------------------------------------ */
.list-partners-alt ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: -32px; }

.list-partners-alt h5 {
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-weight: 400;
  margin-bottom: 20px; }

.list-partners-alt li {
  display: flex;
  align-items: center;
  min-width: 100px;
  min-height: 38px;
  margin-right: 32px;
  margin-bottom: 9px; }
  @media (max-width: 991px) {
    .list-partners-alt li {
      margin-right: 23px;
      margin-bottom: 13px;
      min-height: 23px; } }

.list-partners-alt li img {
  display: block;
  filter: grayscale(100%); }

.list-partners-alt.list-partners--reserve h5 {
  margin-bottom: 32px !important; }

.list-partners-alt.list-partners--reserve li {
  margin-right: 0;
  width: 25% !important;
  margin-bottom: 13px; }

.list-partners-alt.list-partners--space li {
  margin-right: 28px; }
  @media (max-width: 991px) {
    .list-partners-alt.list-partners--space li {
      margin-right: 20px !important; } }

/* ------------------------------------------------------------ *\
	List Partners
\* ------------------------------------------------------------ */
.list-partners {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: -54px !important; }
  .list-partners li {
    width: 25%;
    text-align: center;
    min-height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 54px; }
    @media (max-width: 991px) {
      .list-partners li {
        width: 21%;
        margin-bottom: 39px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .list-partners li {
        width: 50%;
        margin-bottom: 32px; } }
    .list-partners li img {
      filter: grayscale(1); }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .list-partners {
      margin-bottom: -32px !important; } }

/* ------------------------------------------------------------ *\
	List services
\* ------------------------------------------------------------ */
.list-services {
  list-style-type: none; }
  .list-services h5 {
    font-family: "Lato", Helvetica, Arial, sans-serif;
    font-weight: 400;
    margin-bottom: 20px; }
    @media (max-width: 991px) {
      .list-services h5 {
        margin-bottom: 15px; } }
  .list-services ul {
    margin-right: -32px; }
    @media (max-width: 991px) {
      .list-services ul {
        margin-right: 0; } }
  .list-services li {
    display: inline-block;
    vertical-align: middle;
    margin-right: 30px;
    margin-bottom: 16px; }
    @media (max-width: 991px) {
      .list-services li {
        margin-right: 13px;
        margin-bottom: 7px; } }
  .list-services a {
    display: block;
    min-width: 56px;
    text-decoration: none; }
    .list-services a:hover {
      color: #e12954; }
    @media (max-width: 991px) {
      .list-services a {
        font-size: 12px; } }
  .list-services--alt li {
    margin-right: 0;
    width: 30%; }
    @media (max-width: 1440px) {
      .list-services--alt li {
        width: 49%; } }
    @media (max-width: 991px) {
      .list-services--alt li {
        margin-bottom: 15px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .list-services--alt li {
        width: 49% !important; } }
  .list-services--alt a {
    font-weight: 700; }
    @media (max-width: 991px) {
      .list-services--alt a {
        font-size: 14px;
        line-height: 2; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .list-services--alt a {
        font-size: 12px; } }
  @media (max-width: 991px) {
    .list-services--alt i {
      margin-right: 7px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .list-services--alt i {
      margin-right: 0; } }
  .list-services--alt i img {
    max-width: 40px; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .list-services--alt i img {
        max-width: 30px;
        margin-right: 0; } }

/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */
.logo {
  display: block;
  width: 141px;
  height: 47px; }
  .logo img {
    display: block;
    width: 100%;
    height: 100%; }
  .logo.is-hidden {
    display: none; }
  @media (max-width: 991px) {
    .logo {
      position: relative;
      z-index: 5; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .logo {
      width: 225px;
      height: auto; } }

/* Logo Bonas */
.logo-bonas {
  width: 200px;
  height: 19px; }

/* Logo topps */
.logo-topps {
  width: 178px;
  height: 37px; }

/* ------------------------------------------------------------ *\
	Main container
\* ------------------------------------------------------------ */
.main-container {
  flex-grow: 1;
  position: relative; }

/* ------------------------------------------------------------ *\
	Main
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Nav Filter
\* ------------------------------------------------------------ */
.nav-filter {
  display: flex;
  list-style-type: none;
  justify-content: center;
  align-items: center;
  text-align: center; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .nav-filter {
      justify-content: flex-start;
      margin-bottom: 0; } }
  .nav-filter li + li {
    padding-left: 40px; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .nav-filter li + li {
        padding: 0; } }
  .nav-filter a {
    text-decoration: none;
    font-size: 16px;
    transition: 0.4s;
    display: inline-block; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .nav-filter a {
        font-size: 14px; } }
  .nav-filter .is-active a,
  .nav-filter a:hover {
    color: #e12954; }

/* ------------------------------------------------------------ *\
	NAv trigger
\* ------------------------------------------------------------ */
.nav-trigger {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 12px; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .nav-trigger {
      width: 36px; } }
  .nav-trigger span {
    position: absolute;
    display: block;
    height: 2px;
    width: 100%;
    background: #000;
    transition: top 0.2s 0.25s, opacity 0.2s 0.25s, transform 0.2s 0s; }
    .nav-trigger span:nth-child(1) {
      top: 0; }
    .nav-trigger span:nth-child(2) {
      top: 5px; }
    .nav-trigger span:nth-child(3) {
      top: 10px; }
  .nav-trigger.is-active span {
    transition: background 0.2s, top 0.2s, opacity 0.2s, transform 0.2s 0.25s; }
  .nav-trigger.is-active span:nth-child(3),
  .nav-trigger.is-active span:nth-child(1) {
    top: 5px; }
  .nav-trigger.is-active span:nth-child(2) {
    opacity: 0; }
  .nav-trigger.is-active span:nth-child(1) {
    transform: rotate(45deg); }
  .nav-trigger.is-active span:nth-child(3) {
    transform: rotate(-45deg); }

/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */
.nav li {
  display: inline-block;
  vertical-align: middle;
  margin-right: 31px; }
  @media (max-width: 991px) {
    .nav li {
      display: block;
      text-align: center;
      margin-right: 0; } }

.nav a {
  font-size: 16px;
  letter-spacing: 0.5px;
  text-decoration: none;
  transition: color 0.3s; }
  .nav a:hover {
    color: #e12954; }
  @media (max-width: 991px) {
    .nav a {
      font: 300 38px/1.26 "Plantin", sans-serif; } }

.nav .active a {
  color: #e12954; }

@media (max-width: 991px) {
  .nav {
    margin-bottom: 30px; } }

/* ------------------------------------------------------------ *\
	Popup
\* ------------------------------------------------------------ */
.popup {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f7f4f1;
  height: 100vh;
  min-height: 1000px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  flex-flow: column nowrap; }
  .popup .popup__head {
    max-width: 1760px;
    margin: 0 auto;
    padding: 34px 40px;
    position: relative;
    width: 100%; }
    @media (max-width: 1440px) {
      .popup .popup__head {
        padding: 34px 29px; } }
    @media (max-width: 991px) {
      .popup .popup__head {
        padding: 10px 22px; } }
  .popup .popup__close {
    opacity: 1; }
  .popup .popup__close img {
    pointer-events: none;
    width: 100%;
    height: 100%;
    display: block; }
  .popup .popup__list {
    padding-top: 135px; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .popup .popup__list {
        padding-top: 58px;
        margin-bottom: 17px; } }
  .popup .popup__body {
    width: 100%;
    max-width: 1145px;
    padding-left: 20px;
    padding-right: 20px;
    margin: auto;
    position: relative;
    z-index: 5; }
    @media (max-width: 1440px) {
      .popup .popup__body {
        max-width: 932px; } }
  .popup .popup__cols {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .popup .popup__cols {
        flex-direction: column-reverse; } }
  .popup .popup__col--size-1 {
    max-width: 48%;
    flex: 0 0 48%; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .popup .popup__col--size-1 {
        max-width: 100%;
        flex: 0 0 100%; } }
  .popup .popup__col--size-2 {
    max-width: 22%;
    flex: 0 0 22%; }
    @media (max-width: 991px) {
      .popup .popup__col--size-2 {
        max-width: 30%;
        flex: 0 0 30%; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .popup .popup__col--size-2 {
        max-width: 100%;
        flex: 0 0 100%; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .popup {
      justify-content: flex-start;
      padding-bottom: 72px; } }

/* ------------------------------------------------------------ *\
	Radio
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Section Article
\* ------------------------------------------------------------ */
.section-article {
  padding: 238px 0 135px; }
  @media (max-width: 991px) {
    .section-article {
      padding: 96px 0 95px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-article .section__shell {
      padding-left: 20px;
      padding-top: 20px; } }

/* ------------------------------------------------------------ *\
	Section articles
\* ------------------------------------------------------------ */
.section-articles .section__head {
  margin-bottom: 94px;
  text-align: center; }
  @media (max-width: 991px) {
    .section-articles .section__head {
      margin-bottom: 122px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-articles .section__head {
      margin-bottom: 60px; } }

@media (max-width: 991px) {
  .section-articles .section__head--rhs {
    margin-bottom: 64px; } }

.section-articles .section__head--topps {
  margin-bottom: 75px; }
  @media (max-width: 991px) {
    .section-articles .section__head--topps {
      margin-bottom: 64px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-articles .section__head--topps {
      margin-bottom: 43px; } }

.section-articles .section__head--vashi {
  margin-bottom: 82px; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-articles .section__head--vashi {
      margin-bottom: 50px; } }

@media (max-width: 991px) {
  .section-articles h3 {
    font-size: 32px; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-articles h3 {
    font-size: 24px; } }

/* ------------------------------------------------------------ *\
	Section Bg
\* ------------------------------------------------------------ */
.section-bg {
  padding: 35px 0 108px;
  position: relative;
  /* &:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 400px;
		background-color: $c-pearl;
		z-index: -1;
	
		@include breakpoint-down(tablet) {
			height: 200px;
		}
	
		@include breakpoint-down(mobile) {
			height: 158px;
		}
	} */ }
  .section-bg .section__images {
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-bg .section__images {
        flex-direction: column; } }
  .section-bg .section__image img {
    width: 100%; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-bg .section__image img {
        width: auto; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-bg .section__image--1 img {
      width: 117px;
      height: 228px; } }
  .section-bg .section__image + .section__image {
    margin-left: 90px; }
    @media (max-width: 991px) {
      .section-bg .section__image + .section__image {
        margin-left: 47px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-bg .section__image + .section__image {
        margin-left: 0;
        margin-top: 36px; } }
  @media (max-width: 991px) {
    .section-bg .section__shell {
      padding-left: 83px;
      padding-right: 83px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-bg .section__shell {
      padding-left: 20px;
      padding-right: 20px; } }
  @media (max-width: 991px) {
    .section-bg {
      padding-bottom: 60px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-bg {
      padding-bottom: 40px;
      padding-top: 0; } }
  .section-bg--alt {
    padding: 18px 0 54px; }
    @media (max-width: 991px) {
      .section-bg--alt {
        padding-top: 94px !important; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-bg--alt {
        padding-top: 59px !important; } }
    @media (max-width: 991px) {
      .section-bg--alt .article-single .article__image {
        margin-bottom: 24px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-bg--alt .article-single .article__image {
        margin-bottom: 13px; } }
    @media (max-width: 991px) {
      .section-bg--alt .article-single h4 {
        max-width: 490px;
        margin-bottom: 35px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-bg--alt .article-single h4 {
        max-width: 100%;
        font-size: 24px;
        padding-right: 30px;
        margin-bottom: 20px; } }
    @media (max-width: 991px) {
      .section-bg--alt .article-single .article__content p {
        max-width: 490px;
        margin-bottom: 32px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-bg--alt .article-single .article__content p {
        max-width: 100%;
        margin-bottom: 24px; } }
    @media (max-width: 991px) {
      .section-bg--alt .article-single .article__image img {
        width: 100%; } }
    @media (max-width: 991px) {
      .section-bg--alt .article-single--ap .article__image {
        margin-bottom: 34px; } }
    @media (max-width: 991px) {
      .section-bg--alt .article-single--ap h4 {
        max-width: 384px;
        margin-bottom: 24px; } }
    @media (max-width: 991px) {
      .section-bg--alt .article-single--ap .article__content p {
        margin-bottom: 20px; } }
  @media (max-width: 991px) {
    .section-bg--rhs {
      padding: 65px 0 12px !important; } }
  @media (max-width: 991px) {
    .section-bg--rhs {
      padding-top: 42px  !important; } }
  .section-bg--topps {
    padding: 18px 0 72px; }
    @media (max-width: 991px) {
      .section-bg--topps {
        padding: 65px 0 12px !important; } }
    @media (max-width: 991px) {
      .section-bg--topps {
        padding-top: 42px  !important; } }
  @media (max-width: 991px) {
    .section-bg--vashi {
      padding: 17px 0 21px !important; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-bg--vashi {
      padding: 115px 0 21px !important; } }

/* ------------------------------------------------------------ *\
	Section Blog
\* ------------------------------------------------------------ */
.section-blog {
  padding: 143px 0 145px;
  position: relative; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-blog {
      padding: 91px 0 108px; } }
  .section-blog:before {
    content: '';
    width: 600px;
    height: 495px;
    background: url("/../images/svg/circle-5.svg");
    transform: rotate(-19deg) translate(-24%, -59%);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-blog:before {
        display: none; } }
  .section-blog .highlight--6 .highlight__ico {
    top: 100%; }
  .section-blog .section__title {
    margin-bottom: 49px;
    font-family: "Domaine", sans-serif; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-blog .section__title {
        margin-bottom: 25px; } }
  .section-blog .section__head {
    text-align: center;
    margin-bottom: 51px;
    padding: 0 20px; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-blog .section__head {
        padding: 0;
        margin-bottom: 25px; } }
  .section-blog .section__actions {
    margin-top: 69px;
    text-align: center; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-blog .section__actions {
        margin-top: 63px; } }
  .section-blog .section__nav {
    max-width: 350px;
    height: 100%;
    position: relative;
    margin: 0 auto 20px;
    padding: 0 40px; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-blog .section__nav {
        text-align: left;
        overflow-x: hidden;
        padding: 0 60px 0 40px; } }
    @media (max-width: 374px) {
      .section-blog .section__nav {
        padding: 0 27px 0 20px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-blog .section__nav:after {
        content: '';
        width: 24px;
        height: 6px;
        background: url("/../images/svg/arrow-6.svg");
        background-repeat: no-repeat;
        position: absolute;
        left: calc(100% - 24px);
        top: 50%;
        transform: translate(0, -50%); } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-blog .section__nav-inner {
      overflow-x: hidden; } }
  .section-blog .section__body {
    padding-top: 25px; }
  .section-blog.hide-actions .section__actions {
    display: none; }

/* ------------------------------------------------------------ *\
	Section Default
\* ------------------------------------------------------------ */
.section-default {
  position: relative;
  padding: 174px 0 83px;
  text-align: center; }
  .section-default .section__head {
    margin-bottom: 56px; }
    @media (max-width: 1440px) {
      .section-default .section__head {
        margin-bottom: 36px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-default .section__head {
        margin-bottom: 42px; } }
  .section-default .section__body {
    position: relative; }
  .section-default .section__title {
    font-weight: 300; }
    @media (max-width: 1440px) {
      .section-default .section__title {
        font-size: 32px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-default .section__title {
        font-size: 24px; } }
  @media (max-width: 1440px) {
    .section-default {
      padding: 166px 0 83px; } }
  @media (max-width: 991px) {
    .section-default {
      padding: 166px 0 46px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-default {
      padding: 111px 0 46px; } }
  .section-default--ob {
    padding-top: 130px; }
    @media (max-width: 991px) {
      .section-default--ob {
        padding-top: 105px; } }

/* Section Default Alt */
.section-default--alt .section__head {
  max-width: 684px;
  margin: auto; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-default--alt .section__head {
      max-width: 330px; } }

@media (max-width: 1440px) {
  .section-default--alt .section__title {
    font-size: 54px; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-default--alt .section__title {
    font-size: 38px;
    line-height: 1.11; } }

.section-default--alt .section__outer {
  margin-top: 75px;
  margin-bottom: 60px; }
  @media (max-width: 1440px) {
    .section-default--alt .section__outer {
      margin-bottom: 73px;
      margin-left: 87px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-default--alt .section__outer {
      margin-top: 34px;
      margin-bottom: 41px;
      margin-left: 100px; } }

.section-default--alt .section__outer span {
  font: 400 21px/1 "Nanum Pen Script", cursive;
  color: #e12954;
  display: block;
  margin-bottom: 9px; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-default--alt .section__outer span {
      font-size: 16px;
      margin-bottom: 6px; } }

.section-default--alt .section__icon {
  margin-left: -10px; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-default--alt .section__icon {
      display: inline-block;
      width: 28px;
      height: 36px; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-default--alt .section__icon img {
    width: 100%;
    height: 100%;
    display: block; } }

@media (max-width: 991px) {
  .section-default--alt .section__body {
    padding-left: 20px;
    padding-right: 20px; } }

@media (max-width: 1440px) {
  .section-default--alt {
    padding: 246px 0 166px; } }

@media (max-width: 991px) {
  .section-default--alt {
    padding: 132px 0 119px; } }

/* SEction Default Small */
.section-default--small {
  padding: 165px 0 0;
  height: calc(100vh - 65px);
  min-height: 950px; }
  @media (max-width: 1440px) {
    .section-default--small {
      min-height: 662px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-default--small {
      padding: 108px 0 0;
      height: calc(100vh - 84px);
      min-height: 580px; } }
  @media (max-width: 374px) {
    .section-default--small {
      min-height: 480px; } }
  @media only screen and (min-device-width: 320px) and (max-device-width: 767px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .section-default--small {
      min-height: 800px; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .section-default--small {
      min-height: 700px; } }
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
    .section-default--small {
      min-height: 840px; } }

/* Section default services */
@media (max-width: 1440px) {
  .section-default--services {
    padding: 120px 0; } }

/* ------------------------------------------------------------ *\
	Section-images-ob
\* ------------------------------------------------------------ */
.section-images-ob {
  padding: 183px 0 12px; }
  @media (max-width: 991px) {
    .section-images-ob {
      padding: 0 35px 5px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-images-ob {
      padding: 0 0 5px; } }
  .section-images-ob.section .section__images {
    padding: 150px 0 139px; }
    @media (max-width: 991px) {
      .section-images-ob.section .section__images {
        padding: 114px 0 60px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-images-ob.section .section__images {
        padding: 133px 0 111px; } }
  .section-images-ob.section .section__image + .section__image {
    margin-top: 120px; }
    @media (max-width: 991px) {
      .section-images-ob.section .section__image + .section__image {
        margin-top: 63px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-images-ob.section .section__image + .section__image {
        margin-top: 39px; } }
    @media (max-width: 374px) {
      .section-images-ob.section .section__image + .section__image {
        margin-left: auto; } }

/* ------------------------------------------------------------ *\
	Section Intro
\* ------------------------------------------------------------ */
.section-intro {
  min-height: 1080px;
  display: flex;
  flex-direction: row; }
  .section-intro .section__container {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    min-height: inherit; }
  .section-intro .section__title {
    font-weight: 300;
    line-height: 1.04;
    letter-spacing: 1px;
    margin-bottom: 84px; }
    @media (max-width: 1440px) {
      .section-intro .section__title {
        margin-bottom: 74px; } }
    @media (max-width: 991px) {
      .section-intro .section__title {
        letter-spacing: 0;
        line-height: 1.2;
        margin-bottom: 22px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-intro .section__title {
        margin-bottom: 46px; } }
  .section-intro .section__subtitle {
    position: relative; }
  .section-intro .section__subtitle h3 {
    margin-bottom: 0;
    font-weight: 300;
    line-height: 1.31; }
    @media (max-width: 991px) {
      .section-intro .section__subtitle h3 {
        line-height: 1.4; } }
  @media (max-width: 1440px) {
    .section-intro .section__shell {
      max-width: 1191px; } }
  @media (max-width: 1440px) {
    .section-intro {
      min-height: 800px; } }
  @media (max-width: 991px) {
    .section-intro {
      min-height: auto;
      padding: 176px 0 114px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-intro {
      padding: 181px 0 130px; } }

/* Section Intro alt */
.section-intro--alt .section__head {
  width: 49%;
  margin-bottom: 0; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-intro--alt .section__head {
      width: 93%;
      margin-bottom: 31px; } }

.section-intro--alt .section__shell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  min-height: inherit; }
  @media (max-width: 991px) {
    .section-intro--alt .section__shell {
      padding-left: 69px;
      padding-right: 69px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-intro--alt .section__shell {
      padding-left: 20px;
      padding-right: 20px;
      flex-direction: column;
      align-items: flex-start; } }

.section-intro--alt .section__title {
  letter-spacing: 0;
  line-height: 1.19;
  margin-bottom: 0; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-intro--alt .section__title {
      font-size: 28px;
      line-height: 1.36; } }

.section-intro--alt .section__image {
  width: 41%; }
  @media (max-width: 991px) {
    .section-intro--alt .section__image {
      width: 48%; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-intro--alt .section__image {
      width: 100%; } }

.section-intro--alt .section__image img {
  width: 100%; }

@media (max-width: 991px) {
  .section-intro--alt {
    padding: 150px 0 185px; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-intro--alt {
    padding: 114px 0 0; } }

/* ------------------------------------------------------------ *\
	Section Intro OB
\* ------------------------------------------------------------ */
@media (max-width: 991px) {
  .section-intro--ob {
    padding: 113px 0 17px; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-intro--ob {
    padding: 58px 0 0;
    margin-bottom: -27px; } }

@media (max-width: 991px) {
  .section-intro--ob .section__head {
    margin-bottom: 72px; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-intro--ob .section__head {
    margin-bottom: 85px; } }

@media (max-width: 991px) {
  .section-intro--ob .section__title {
    font-size: 28px;
    line-height: 1.38; } }

/* ------------------------------------------------------------ *\
	Section Intro RHS
\* ------------------------------------------------------------ */
@media (max-width: 991px) {
  .section-intro--rhs {
    padding: 145px 0 0; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-intro--rhs {
    padding: 58px 0 0;
    margin-bottom: -27px; } }

@media (max-width: 991px) {
  .section-intro--rhs .section__head {
    margin-bottom: 18px; } }

@media (max-width: 991px) {
  .section-intro--rhs .section__title {
    font-size: 28px;
    line-height: 1.38; } }

/* ------------------------------------------------------------ *\
	Section Intro Topps
\* ------------------------------------------------------------ */
@media (max-width: 991px) {
  .section-intro--topps {
    padding: 65px 0 12px !important; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-intro--topps {
    padding: 58px 0 12px !important; } }

.section-intro--topps .section__head {
  margin-bottom: 22px; }
  @media (max-width: 991px) {
    .section-intro--topps .section__head {
      margin-bottom: 18px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-intro--topps .section__head {
      margin-bottom: 85px; } }

/* ------------------------------------------------------------ *\
	Section Intro Vashi
\* ------------------------------------------------------------ */
@media (max-width: 991px) {
  .section-intro--vashi {
    padding: 134px 0 7px; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-intro--vashi {
    padding: 58px 0 13px; } }

.section-intro--vashi .section__head {
  margin-bottom: 30px; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-intro--vashi .section__head {
      margin-bottom: 47px; } }

/* ------------------------------------------------------------ *\
	Section Simple
\* ------------------------------------------------------------ */
.section-simple {
  min-height: 500px;
  padding: 48px 0 200px; }
  .section-simple .section__cols {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap; }
  .section-simple .section__col {
    max-width: 100%;
    flex: 1; }
  .section-simple .section__col--size1 {
    max-width: 38%;
    flex: 0 0 38%; }
    @media (max-width: 991px) {
      .section-simple .section__col--size1 {
        max-width: 100%;
        flex: 0 0 100%; } }
  .section-simple .section__col--size2 {
    max-width: 41%;
    flex: 0 0 41%; }
    @media (max-width: 991px) {
      .section-simple .section__col--size2 {
        max-width: 100%;
        flex: 0 0 100%;
        display: flex;
        justify-content: space-between; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-simple .section__col--size2 {
        flex-direction: column; } }
  @media (max-width: 991px) {
    .section-simple .section__col + .section__col {
      margin-top: 55px; } }
  @media (max-width: 991px) {
    .section-simple .section__list {
      max-width: 48%; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-simple .section__list {
      max-width: 100%; } }
  .section-simple .section__list + .section__list {
    margin-top: 40px; }
    @media (max-width: 991px) {
      .section-simple .section__list + .section__list {
        margin-top: 0; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-simple .section__list + .section__list {
        margin-top: 30px; } }
  .section-simple .section__head {
    margin-bottom: 65px; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-simple .section__head {
        margin-bottom: 32px; } }
  .section-simple .section__head--small {
    max-width: 702px;
    margin: 0 auto 65px; }
    @media (max-width: 1440px) {
      .section-simple .section__head--small {
        margin: 0 auto 82px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-simple .section__head--small {
        margin-bottom: 55px; } }
  .section-simple .section__title {
    font-weight: 300;
    line-height: 1.31; }
    @media (max-width: 991px) {
      .section-simple .section__title {
        font-size: 32px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-simple .section__title {
        font-size: 24px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-simple .section__title {
      font-size: 20px;
      line-height: 1.4; } }
  .section-simple .section__subtitle {
    margin-bottom: 14px;
    font-weight: 600;
    font-family: "Lato", Helvetica, Arial, sans-serif;
    text-transform: uppercase; }
  .section-simple .section__head--alt {
    max-width: 890px;
    padding: 28px 0;
    margin: 0 auto 282px; }
    @media (max-width: 991px) {
      .section-simple .section__head--alt {
        margin-bottom: 97px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-simple .section__head--alt {
        margin-bottom: 106px; } }
    .section-simple .section__head--alt .section__subtitle {
      margin-bottom: 5px; }
      @media (max-width: 991px) {
        .section-simple .section__head--alt .section__subtitle {
          margin-bottom: 1px; } }
      @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
        .section-simple .section__head--alt .section__subtitle {
          margin-bottom: 7px; } }
    @media (max-width: 991px) {
      .section-simple .section__head--alt .section__title {
        font-size: 28px;
        line-height: 1.5;
        padding: 0 25px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-simple .section__head--alt .section__title {
        padding: 0 2px;
        font-size: 24px;
        line-height: 1.33; } }
  .section-simple .section__images {
    display: flex;
    justify-content: center;
    align-items: center;
    /* @include breakpoint-down(mobile) {
			justify-content: center;
		} */ }
    @media (max-width: 991px) {
      .section-simple .section__images {
        justify-content: flex-start; } }
  .section-simple .section__image {
    position: relative; }
  .section-simple .section__image img {
    width: 100%; }
  .section-simple .section__image + .section__image {
    margin-left: 90px; }
    @media (max-width: 1440px) {
      .section-simple .section__image + .section__image {
        margin-left: 128px; } }
    @media (max-width: 991px) {
      .section-simple .section__image + .section__image {
        margin-left: 29px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-simple .section__image + .section__image {
        margin-left: 40px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-simple .section__image--mobile {
      display: flex;
      justify-content: center; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-simple .section__image--mobile img {
      width: 132px;
      height: 258px; } }
  .section-simple .section__image-outer {
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    margin-left: 20px;
    width: 150px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column; }
    @media (max-width: 1440px) {
      .section-simple .section__image-outer {
        margin-left: 11px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-simple .section__image-outer {
        position: static; } }
  .section-simple .section__info span {
    display: block;
    letter-spacing: 1px;
    font: 400 21px/1 "Nanum Pen Script", cursive;
    margin-left: 29px; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-simple .section__info span {
        font-size: 16px;
        margin-left: 13px; } }
  .section-simple .section__info--top {
    margin-top: 92px; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-simple .section__info--top {
        margin-top: 55px; } }
  .section-simple .section__info--bottom {
    margin-bottom: 163px; }
    @media (max-width: 1440px) {
      .section-simple .section__info--bottom {
        margin-bottom: 23px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-simple .section__info--bottom {
        margin-bottom: 43px; } }
  .section-simple .section__info--bottom .section__icon img {
    transform: scale(1, -1); }
    @media (max-width: 1440px) {
      .section-simple .section__info--bottom .section__icon img {
        transform: scale(1, 1); } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-simple .section__info--bottom .section__icon img {
        transform: scale(1, -1); } }
  @media (max-width: 1440px) {
    .section-simple .section__info--bottom .section__icon {
      width: 91px;
      height: 153px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-simple .section__info--bottom .section__icon {
      width: 50px;
      height: 47px; } }
  .section-simple .section__info--top span {
    margin-bottom: -13px; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-simple .section__info--top span {
        margin-bottom: -5px; } }
  .section-simple .section__info--bottom span {
    margin-top: -16px; }
    @media (max-width: 1440px) {
      .section-simple .section__info--bottom span {
        margin-top: 4px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-simple .section__info--bottom span {
        margin-top: -11px; } }
  .section-simple .section__icon {
    width: 67px;
    height: 69px;
    display: inline-block; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-simple .section__icon {
        width: 50px;
        height: 47px; } }
  .section-simple .section__icon img {
    width: 100%;
    height: 100%;
    display: block; }
  .section-simple .section__icon--arrow-2 {
    display: none !important; }
    @media (max-width: 1440px) {
      .section-simple .section__icon--arrow-2 {
        display: block !important; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-simple .section__icon--arrow-2 {
        display: none !important; } }
  @media (max-width: 1440px) {
    .section-simple .section__icon--arrow-1 {
      display: none !important; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-simple .section__icon--arrow-1 {
      display: block !important; } }
  .section-simple .section__group {
    min-height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap; }
    @media (max-width: 1199px) {
      .section-simple .section__group {
        min-height: auto; } }
  @media (max-width: 991px) {
    .section-simple .section__group + .section__group {
      margin-top: 175px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-simple .section__group + .section__group {
      margin-top: 40px; } }
  .section-simple .section__lists {
    min-width: 565px;
    width: 100%;
    margin-right: 0; }
    @media (max-width: 1440px) {
      .section-simple .section__lists {
        min-width: 41%; } }
    @media (max-width: 991px) {
      .section-simple .section__lists {
        padding-top: 73px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-simple .section__lists {
        padding-top: 44px; } }
    @media (max-width: 991px) {
      .section-simple .section__lists h5 {
        font-size: 18px;
        margin-bottom: 27px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-simple .section__lists h5 {
        font-size: 16px; } }
    @media (max-width: 991px) {
      .section-simple .section__lists .section__list {
        max-width: 100%; } }
    @media (max-width: 991px) {
      .section-simple .section__lists .section__list li {
        width: 30%;
        margin-right: 0; } }
    @media (max-width: 991px) {
      .section-simple .section__lists .section__list + .section__list {
        margin-top: 50px; } }
  .section-simple .section__shell--flex {
    min-height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  @media (max-width: 1440px) {
    .section-simple .section__shell {
      max-width: 1185px; } }
  @media (max-width: 991px) {
    .section-simple .section__shell {
      padding-left: 69px;
      padding-right: 69px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-simple .section__shell {
      padding-left: 20px;
      padding-right: 20px; } }
  @media (max-width: 1440px) {
    .section-simple {
      padding-bottom: 140px; } }
  @media (max-width: 991px) {
    .section-simple {
      min-height: auto;
      padding-bottom: 43px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-simple {
      padding: 43px 0; } }

.section-simple--small-padding {
  padding: 48px 0;
  display: flex;
  flex-direction: row; }
  @media (max-width: 991px) {
    .section-simple--small-padding .section__shell {
      padding-left: 34px;
      padding-right: 34px; } }
  @media (max-width: 991px) {
    .section-simple--small-padding {
      padding: 108px 0 70px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-simple--small-padding {
      padding: 56px 0 34px; } }

.section-simple--no-padding {
  padding: 0; }
  .section-simple--no-padding .section__head {
    margin-bottom: 0;
    padding: 83px 0;
    max-width: 510px; }
    @media (max-width: 1440px) {
      .section-simple--no-padding .section__head {
        padding: 193px 0 127px; } }
    @media (max-width: 991px) {
      .section-simple--no-padding .section__head {
        padding: 163px 0 70px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-simple--no-padding .section__head {
        padding: 42px 0 32px; } }
  @media (max-width: 1440px) {
    .section-simple--no-padding .section__shell {
      max-width: 1272px; } }

.section-simple--large-padding {
  padding: 154px 0 50px; }
  @media (max-width: 991px) {
    .section-simple--large-padding {
      padding-top: 75px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-simple--large-padding {
      padding-top: 51px; } }
  .section-simple--large-padding .section__head--alt {
    margin-bottom: 184px; }
    @media (max-width: 991px) {
      .section-simple--large-padding .section__head--alt {
        margin-bottom: 122px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-simple--large-padding .section__head--alt {
        margin-bottom: 155px; } }
    @media (max-width: 991px) {
      .section-simple--large-padding .section__head--alt .section__title {
        padding: 0; } }
  .section-simple--large-padding .article-single--alt .article__content {
    margin-top: 126px; }

.section-simple--rhs {
  padding-bottom: 183px; }
  @media (max-width: 991px) {
    .section-simple--rhs {
      padding: 118px 0 94px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-simple--rhs {
      padding: 85px 0 138px; } }
  .section-simple--rhs .section__head--alt {
    margin-bottom: 173px; }
    @media (max-width: 991px) {
      .section-simple--rhs .section__head--alt {
        padding: 0 25px;
        margin-bottom: 130px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-simple--rhs .section__head--alt {
        padding: 0 8px; } }
  .section-simple--rhs .article-single--alt {
    padding-left: 70px; }
    .section-simple--rhs .article-single--alt .article__content {
      max-width: 357px;
      margin: 135px -29px 0 0; }
      @media (max-width: 1440px) {
        .section-simple--rhs .article-single--alt .article__content {
          margin-right: 0; } }
      @media (max-width: 991px) {
        .section-simple--rhs .article-single--alt .article__content {
          max-width: 510px !important;
          margin: 0 auto  !important; } }
      .section-simple--rhs .article-single--alt .article__content h4 {
        margin-bottom: 28px; }
        @media (max-width: 991px) {
          .section-simple--rhs .article-single--alt .article__content h4 {
            margin-bottom: 23px; } }

.section-simple--topps {
  padding-bottom: 93px; }
  @media (max-width: 991px) {
    .section-simple--topps {
      padding: 34px 0 36px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-simple--topps {
      padding: 4px 0 104px; } }
  .section-simple--topps .section__head--alt {
    margin-bottom: 256px; }
    @media (max-width: 991px) {
      .section-simple--topps .section__head--alt {
        padding: 0 25px;
        margin-bottom: 123px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-simple--topps .section__head--alt {
        padding: 0 7px;
        margin-bottom: 134px; } }
  .section-simple--topps .article-single {
    margin-bottom: 160px; }
    @media (max-width: 991px) {
      .section-simple--topps .article-single {
        margin-bottom: 70px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-simple--topps .article-single {
        margin-bottom: 23px; } }
    @media (max-width: 991px) {
      .section-simple--topps .article-single h4 {
        max-width: 515px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-simple--topps .section__lists {
      padding-top: 105px; } }

.section-simple--vashi {
  padding-bottom: 298px; }
  @media (max-width: 991px) {
    .section-simple--vashi {
      padding: 10px 0 113px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-simple--vashi {
      padding: 0 0 120px; } }
  @media (max-width: 991px) {
    .section-simple--vashi .section__head--alt {
      margin-bottom: 120px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-simple--vashi .section__head--alt {
      margin-bottom: 105px; } }
  .section-simple--vashi .article-single {
    align-items: flex-start; }
    .section-simple--vashi .article-single .article__content {
      margin: 111px 78px 0 0; }
      @media (max-width: 991px) {
        .section-simple--vashi .article-single .article__content {
          max-width: 515px !important;
          margin: 0 auto !important; } }
    .section-simple--vashi .article-single h4 {
      margin-bottom: 33px; }
      @media (max-width: 991px) {
        .section-simple--vashi .article-single h4 {
          margin-bottom: 20px; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-simple--no-padding-mobile {
    padding: 0; } }

.section-quote {
  padding: 0 !important; }

/* ------------------------------------------------------------ *\
	Section Stats
\* ------------------------------------------------------------ */
@media (max-width: 991px) {
  .section-stats {
    padding-bottom: 105px; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-stats {
    padding: 101px 0 61px; } }

.section-stats .section__head {
  max-width: 545px;
  width: 100%;
  margin: 0 auto 65px; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-stats .section__head {
      margin-bottom: 48px; } }

@media (max-width: 991px) {
  .section-stats .section__head h3 {
    font-size: 28px;
    line-height: 1;
    max-width: 386px;
    margin: 0 auto; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-stats .section__head h3 {
    font-size: 24px;
    line-height: 1.17;
    padding: 0 20px; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-stats .section__shell {
    padding-left: 0;
    padding-right: 0; } }

.section-stats--height-auto {
  min-height: auto;
  padding: 49px 0 32px; }
  @media (max-width: 991px) {
    .section-stats--height-auto {
      padding-top: 80px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-stats--height-auto {
      padding-top: 72px; } }
  @media (max-width: 991px) {
    .section-stats--height-auto .section__head .section__title {
      font-size: 28px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-stats--height-auto .section__head .section__title {
      font-size: 24px; } }

.section-stats--rhs {
  padding-bottom: 7px; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-stats--rhs {
      padding-top: 84px; } }

.section-stats--topps {
  padding-bottom: 22px; }
  .section-stats--topps .section__head {
    margin-bottom: 47px; }
    @media (max-width: 991px) {
      .section-stats--topps .section__head {
        margin-bottom: 65px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section-stats--topps .section__head {
        margin-bottom: 46px; } }
  @media (max-width: 991px) {
    .section-stats--topps {
      padding: 118px 0 113px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section-stats--topps {
      padding: 84px 0 86px; } }

/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */
.section {
  min-height: 800px;
  display: flex;
  flex-direction: row; }
  .section .section__shell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: inherit; }
  .section .section__head {
    text-align: center;
    margin-bottom: 67px; }
    @media (max-width: 991px) {
      .section .section__head {
        margin-bottom: 29px; } }
  .section .section__title {
    font-weight: 300; }
    @media (max-width: 991px) {
      .section .section__title {
        font-size: 28px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section .section__title {
        font-size: 20px; } }
  .section .section__highlight-ico-1 {
    top: 75%; }
  .section .section__images {
    display: flex;
    justify-content: center;
    padding: 150px 0 140px; }
    @media (max-width: 1440px) {
      .section .section__images {
        padding: 150px 0 130px; } }
    @media (max-width: 991px) {
      .section .section__images {
        padding: 93px 0 95px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section .section__images {
        flex-direction: column;
        padding: 37px 0 98px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .section .section__image {
      max-width: 265px; } }
  .section .section__image + .section__image {
    margin-left: 80px;
    margin-top: 150px; }
    @media (max-width: 991px) {
      .section .section__image + .section__image {
        margin-top: 70px;
        margin-left: 38px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .section .section__image + .section__image {
        margin-left: 69px;
        margin-top: 40px; } }
  @media (max-width: 1440px) {
    .section {
      min-height: 700px; } }
  @media (max-width: 991px) {
    .section {
      min-height: auto; } }

/* Section Alt */
@media (max-width: 991px) {
  .section--alt {
    padding: 112px 0 120px; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section--alt {
    padding: 72px 0 83px; } }

.section--small {
  min-height: 0;
  background: #f7f4f1;
  margin-bottom: 100px;
  padding: 50px 0 !important; }
  .section--small .section__head--alt {
    margin-bottom: 0; }
  .section--small .section__head--alt h3 {
    font-style: italic; }

/* ------------------------------------------------------------ *\
	Select
\* ------------------------------------------------------------ */
.select select::-ms-expand {
  display: none; }

/* ------------------------------------------------------------ *\
	Services
\* ------------------------------------------------------------ */
.services .services__items {
  max-width: 940px;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-items: center;
  margin: auto;
  margin-bottom: -56px; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .services .services__items {
      flex-direction: column;
      margin-bottom: -32px; } }

.services .services__item {
  max-width: 27%;
  flex: 0 0 27%;
  margin-bottom: 56px; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .services .services__item {
      max-width: 210px;
      flex: 0 0 100%;
      margin-bottom: 32px; } }

.service {
  max-width: 210px;
  margin: auto; }
  .service .service__icon {
    min-height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px; }
  .service .service__head {
    margin-bottom: 5px; }
  .service .service__entry p {
    line-height: 1.36; }
  .service .service__title {
    font-weight: 300; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .service .service__title {
        font-size: 24px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .service {
      max-width: 100%; } }

/* ------------------------------------------------------------ *\
  Shell
\* ------------------------------------------------------------ */
.shell {
  max-width: calc(1210px + 32px * 2);
  padding-left: 32px;
  padding-right: 32px;
  margin: auto;
  width: 100%; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .shell {
      padding-left: 20px;
      padding-right: 20px; } }

/* Shell Large */
.shell--large {
  max-width: calc(1392px + 32px * 2); }

/* Shell small */
.shell--small {
  max-width: calc(1056px + 32px * 2); }

/*  Shell medium  */
.shell--medium {
  max-width: calc(1130px + 32px * 2); }

/* Shell Fluid */
.shell--fluid {
  max-width: none; }

/* ------------------------------------------------------------ *\
	Slider
\* ------------------------------------------------------------ */
.slider {
  /* margin-left: 120px; */
  /* .slider__clip.is-active {
		overflow: visible;
	} */
  /* @include breakpoint-down(large-desktop) {
		margin-left: 40px;
	}
	
	@include breakpoint-down(tablet) {
		margin-left: 34px;
	}
	
	@include breakpoint-down(mobile) {
		margin-left: 20px;
	} */ }
  .slider .slider__slide {
    transform: scale(0.8);
    transition: transform 0.3s;
    width: 29.5%; }
    @media (max-width: 1440px) {
      .slider .slider__slide {
        width: 30.3%; } }
    @media (max-width: 991px) {
      .slider .slider__slide {
        width: 58.3%; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .slider .slider__slide {
        width: 75%; } }
  .slider .slider__actions {
    position: absolute;
    right: 0;
    left: 0;
    top: -33px;
    width: 100%; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .slider .slider__actions {
        top: -26px; } }
  .slider .swiper-button-prev,
  .slider .swiper-button-next {
    background-image: url(../images/svg/arrow-right-slider.svg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 58px;
    height: 11px;
    margin-bottom: 0;
    position: absolute;
    top: 0;
    margin-top: 0;
    transition: opacity 0.3s; }
    .slider .swiper-button-prev:after,
    .slider .swiper-button-next:after {
      display: none; }
    .slider .swiper-button-prev:hover,
    .slider .swiper-button-next:hover {
      opacity: 0.7; }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .slider .swiper-button-prev,
      .slider .swiper-button-next {
        width: 38px;
        height: 7px; } }
  .slider .swiper-button-next {
    right: 110px; }
    @media (max-width: 1440px) {
      .slider .swiper-button-next {
        right: 80px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .slider .swiper-button-next {
        right: 18px; } }
  .slider .swiper-button-prev {
    transform: scale(-1, 1);
    left: 110px; }
    @media (max-width: 1440px) {
      .slider .swiper-button-prev {
        left: 80px; } }
    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      .slider .swiper-button-prev {
        left: 18px; } }
  .slider .swiper-slide-active {
    transform: scale(1); }

/* ------------------------------------------------------------ *\
	Stats
\* ------------------------------------------------------------ */
.stats .stats__items {
  display: flex;
  justify-content: center;
  flex-flow: row wrap; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stats .stats__items {
      flex-direction: column;
      align-items: center; } }

.stats .stats__item {
  max-width: 210px;
  flex: 0 0 210px; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stats .stats__item {
      flex: 0 0 1; } }

.stats .stats__item + .stats__item {
  margin-left: 149px; }
  @media (max-width: 991px) {
    .stats .stats__item + .stats__item {
      margin-left: 35px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stats .stats__item + .stats__item {
      margin-left: 0;
      margin-top: 40px; } }

.stats--alt .stat {
  z-index: 1; }

.stats--alt .stat__outer {
  font-family: "Nanum Pen Script", cursive;
  font-size: 26px;
  line-height: 0.85;
  font-weight: 400; }
  @media (max-width: 991px) {
    .stats--alt .stat__outer {
      font-size: 22px;
      line-height: 1.1; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stats--alt .stat__outer {
      font-size: 26px;
      line-height: 0.85; } }

.stats--alt .stat .stat__inner {
  margin-top: 2px; }

.stats--alt .stat:after {
  top: 9px; }

@media (max-width: 991px) {
  .stats--alt .stats__item + .stats__item {
    margin-left: 15px; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .stats--alt .stats__item + .stats__item {
    margin-left: -105px;
    margin-top: 0; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .stats--alt .stats__item {
    margin-left: -60px;
    max-height: 170px; } }

.stat {
  position: relative;
  min-height: 127px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .stat .stat__inner {
    margin-left: 28px;
    margin-top: 25px; }
  .stat .stat__entry {
    font-size: 11px;
    line-height: 1.3;
    font-weight: 300;
    margin-left: 44px;
    margin-bottom: 0;
    letter-spacing: 0.01px; }
  .stat .stat__number {
    font: 600 47px/1 "Plantin", sans-serif;
    margin-bottom: -6px;
    display: block; }
  .stat .stat__number em {
    font-weight: 300; }
  .stat .stat__outer {
    position: absolute;
    right: 11px;
    top: 23px;
    padding-bottom: 9px; }
  .stat .stat__outer span {
    font-weight: 700; }
  .stat .stat__icon {
    position: absolute;
    bottom: 4px;
    left: -22px;
    width: 18px;
    height: 14px;
    display: inline-block; }
  .stat .stat__icon img {
    display: block;
    width: 100%;
    height: 100%; }
  .stat:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    background-size: 100% 100%;
    background-repeat: no-repeat; }

.stat--product:after {
  width: 133px;
  height: 127px;
  background-image: url(../images/svg/circle-2.svg); }

.stat--conversion .stat__inner {
  margin-left: 21px; }

.stat--conversion .stat__entry {
  margin-left: 40px; }

.stat--conversion .stat__outer {
  right: 38px;
  top: 9px; }

.stat--conversion:after {
  width: 127px;
  height: 130px;
  background-image: url(../images/svg/circle-3.svg); }

.stat--money .stat__inner {
  margin-left: 8px; }

.stat--money .stat__entry {
  margin-left: 57px; }

.stat--money:after {
  width: 131px;
  height: 126px;
  background-image: url(../images/svg/circle-4.svg); }

.stat--growth .stat__inner {
  margin-left: 13px; }
  @media (max-width: 991px) {
    .stat--growth .stat__inner {
      margin-left: 19px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--growth .stat__inner {
      margin-left: -4px;
      margin-right: 29px; } }

.stat--growth .stat__outer {
  right: -14px;
  top: 5px; }
  @media (max-width: 991px) {
    .stat--growth .stat__outer {
      right: -11px;
      top: 8px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--growth .stat__outer {
      right: -62px;
      top: 4px; } }

.stat--growth .stat__icon {
  bottom: 15px;
  left: -29px; }
  @media (max-width: 991px) {
    .stat--growth .stat__icon {
      bottom: 23px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--growth .stat__icon {
      bottom: 16px; } }

.stat--growth:after {
  width: 133px;
  height: 127px;
  background-image: url(../images/svg/circle-2.svg);
  left: 13px; }
  @media (max-width: 991px) {
    .stat--growth:after {
      height: 110px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--growth:after {
      height: 125px;
      left: 0; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .stat--views {
    margin-left: 0; } }

.stat--views .stat__inner {
  margin-left: 21px; }
  @media (max-width: 991px) {
    .stat--views .stat__inner {
      margin-left: 23px; } }

.stat--views .stat__entry {
  margin-left: 40px; }

.stat--views .stat__outer {
  right: -23px;
  top: 4px; }
  @media (max-width: 991px) {
    .stat--views .stat__outer {
      right: 0px;
      top: 8px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--views .stat__outer {
      right: -99px;
      top: 6px; } }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
    .stat--views .stat__outer {
      right: -130px; } }

.stat--views .stat__icon {
  bottom: 16px; }
  @media (max-width: 991px) {
    .stat--views .stat__icon {
      left: -33px;
      bottom: 23px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--views .stat__icon {
      left: -26px;
      bottom: 17px; } }

.stat--views:after {
  width: 127px;
  height: 130px;
  background-image: url(../images/svg/circle-3.svg);
  left: 13px; }
  @media (max-width: 991px) {
    .stat--views:after {
      height: 117px;
      left: 4px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--views:after {
      height: 127px; } }

.stat--stores .stat__inner {
  margin-left: 40px; }
  @media (max-width: 991px) {
    .stat--stores .stat__inner {
      margin-left: 34px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--stores .stat__inner {
      margin-left: 39px;
      margin-right: 19px; } }

.stat--stores .stat__outer {
  right: -51px;
  top: 16px; }
  @media (max-width: 991px) {
    .stat--stores .stat__outer {
      right: -2px;
      top: 11px;
      max-width: 103px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--stores .stat__outer {
      right: -118px;
      top: 12px; } }

.stat--stores .stat__icon {
  bottom: 24px;
  left: -26px; }
  @media (max-width: 991px) {
    .stat--stores .stat__icon {
      bottom: 44px;
      left: -23px; } }

.stat--stores:after {
  width: 131px;
  height: 126px;
  background-image: url(../images/svg/circle-4.svg);
  left: 0; }
  @media (max-width: 991px) {
    .stat--stores:after {
      height: 116px;
      left: -3px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--stores:after {
      height: 130px; } }

.stat--rate .stat__inner {
  margin-left: 37px; }
  @media (max-width: 991px) {
    .stat--rate .stat__inner {
      margin-left: 29px;
      margin-top: -5px !important; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--rate .stat__inner {
      margin-left: 23px;
      margin-right: 49px;
      margin-top: 8px !important; } }

.stat--rate .stat__outer {
  right: -42px;
  top: 11px; }
  @media (max-width: 991px) {
    .stat--rate .stat__outer {
      right: -14px;
      top: 3px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--rate .stat__outer {
      right: -92px;
      top: 8px; } }

.stat--rate .stat__icon {
  bottom: 41px;
  left: -29px; }
  @media (max-width: 991px) {
    .stat--rate .stat__icon {
      bottom: 43px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--rate .stat__icon {
      bottom: 39px;
      left: -44px; } }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
    .stat--rate .stat__icon {
      left: -25px; } }

.stat--rate:after {
  width: 133px;
  height: 127px;
  background-image: url(../images/svg/circle-2.svg);
  left: 13px; }
  @media (max-width: 991px) {
    .stat--rate:after {
      height: 110px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--rate:after {
      height: 125px;
      left: 0; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .stat--transactions {
    margin-left: 0; } }

.stat--transactions .stat__inner {
  margin-left: 28px; }
  @media (max-width: 991px) {
    .stat--transactions .stat__inner {
      margin-left: 22px;
      margin-top: -5px !important; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--transactions .stat__inner {
      margin-left: 18px;
      margin-right: 9px;
      margin-top: 3px !important; } }

.stat--transactions .stat__entry {
  margin-left: 40px; }

.stat--transactions .stat__outer {
  max-width: 96px;
  right: -29px;
  top: 9px; }
  @media (max-width: 991px) {
    .stat--transactions .stat__outer {
      max-width: 80px;
      right: 15px;
      top: 3px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--transactions .stat__outer {
      right: -124px;
      top: 11px;
      max-width: 85px; } }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
    .stat--transactions .stat__outer {
      max-width: 104px;
      right: -130px; } }

.stat--transactions .stat__icon {
  bottom: 40px;
  left: -29px; }
  @media (max-width: 991px) {
    .stat--transactions .stat__icon {
      left: -29px;
      bottom: 45px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--transactions .stat__icon {
      left: -45px;
      bottom: 42px; } }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
    .stat--transactions .stat__icon {
      left: -25px; } }

.stat--transactions:after {
  width: 127px;
  height: 130px;
  background-image: url(../images/svg/circle-3.svg);
  left: 13px; }
  @media (max-width: 991px) {
    .stat--transactions:after {
      height: 117px;
      left: 4px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--transactions:after {
      height: 127px; } }

.stat--duration .stat__inner {
  margin-left: 21px; }
  @media (max-width: 991px) {
    .stat--duration .stat__inner {
      margin-left: 16px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--duration .stat__inner {
      margin-left: 35px;
      margin-right: -21px; } }

.stat--duration .stat__outer {
  right: -14px;
  top: 18px;
  max-width: 96px; }
  @media (max-width: 991px) {
    .stat--duration .stat__outer {
      right: 3px;
      top: 9px;
      max-width: 85px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--duration .stat__outer {
      right: -137px;
      top: 13px;
      max-width: 90px; } }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
    .stat--duration .stat__outer {
      max-width: 110px;
      right: -150px; } }

.stat--duration .stat__icon {
  bottom: 47px;
  left: -26px; }
  @media (max-width: 991px) {
    .stat--duration .stat__icon {
      bottom: 44px;
      left: -23px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--duration .stat__icon {
      bottom: 40px;
      left: -47px; } }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
    .stat--duration .stat__icon {
      left: -25px; } }

.stat--duration:after {
  width: 131px;
  height: 126px;
  background-image: url(../images/svg/circle-4.svg);
  left: 0; }
  @media (max-width: 991px) {
    .stat--duration:after {
      height: 116px;
      left: -3px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--duration:after {
      height: 130px; } }

.stat--session .stat__inner {
  margin-left: 5px; }
  @media (max-width: 991px) {
    .stat--session .stat__inner {
      margin-left: 29px;
      margin-top: -5px !important; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--session .stat__inner {
      margin-left: 23px;
      margin-right: 49px;
      margin-top: 8px !important; } }

.stat--session .stat__outer {
  right: -56px;
  top: 9px; }
  @media (max-width: 991px) {
    .stat--session .stat__outer {
      right: -22px;
      top: 3px;
      max-width: 80px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--session .stat__outer {
      right: -72px;
      top: 8px; } }

.stat--session .stat__icon {
  bottom: 14px;
  left: -31px; }
  @media (max-width: 991px) {
    .stat--session .stat__icon {
      bottom: 44px;
      left: -17px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--session .stat__icon {
      bottom: 40px;
      left: -38px; } }

.stat--session:after {
  width: 133px;
  height: 127px;
  background-image: url(../images/svg/circle-2.svg);
  left: -15px; }
  @media (max-width: 991px) {
    .stat--session:after {
      height: 110px;
      left: 11px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--session:after {
      height: 125px;
      left: 0; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .stat--load-time {
    margin-left: 0; } }

.stat--load-time .stat__inner {
  margin-left: -2px; }
  @media (max-width: 991px) {
    .stat--load-time .stat__inner {
      margin-left: 22px;
      margin-top: -5px !important; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--load-time .stat__inner {
      margin-left: 25px;
      margin-right: 7px;
      margin-top: 3px !important; } }

.stat--load-time .stat__entry {
  margin-left: 40px; }

.stat--load-time .stat__outer {
  max-width: 130px;
  right: -29px;
  top: 9px; }
  @media (max-width: 991px) {
    .stat--load-time .stat__outer {
      max-width: 80px;
      right: 0;
      top: 3px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--load-time .stat__outer {
      right: -130px;
      top: 11px;
      max-width: 93px; } }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
    .stat--load-time .stat__outer {
      max-width: 120px;
      right: -150px; } }

.stat--load-time .stat__icon {
  bottom: 40px;
  left: -24px; }
  @media (max-width: 991px) {
    .stat--load-time .stat__icon {
      left: -33px;
      bottom: 68px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--load-time .stat__icon {
      left: -33px;
      bottom: 54px; } }

.stat--load-time:after {
  width: 127px;
  height: 130px;
  background-image: url(../images/svg/circle-3.svg);
  left: -22px; }
  @media (max-width: 991px) {
    .stat--load-time:after {
      height: 117px;
      left: 4px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--load-time:after {
      height: 127px; } }

.stat--conversion-rate .stat__inner {
  margin-left: -10px;
  margin-top: 16px !important; }
  @media (max-width: 991px) {
    .stat--conversion-rate .stat__inner {
      margin-left: 16px;
      margin-top: 4px !important; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--conversion-rate .stat__inner {
      margin-left: 16px;
      margin-right: -5px; } }

.stat--conversion-rate .stat__outer {
  right: -64px;
  top: 18px;
  max-width: 180px; }
  @media (max-width: 991px) {
    .stat--conversion-rate .stat__outer {
      right: -3px;
      top: 9px;
      max-width: 80px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--conversion-rate .stat__outer {
      right: -137px;
      top: 13px;
      max-width: 94px; } }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
    .stat--conversion-rate .stat__outer {
      max-width: 120px;
      right: -160px; } }

.stat--conversion-rate .stat__icon {
  bottom: 23px;
  left: -28px; }
  @media (max-width: 991px) {
    .stat--conversion-rate .stat__icon {
      bottom: 70px;
      left: -29px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--conversion-rate .stat__icon {
      bottom: 54px;
      left: -31px; } }

.stat--conversion-rate:after {
  width: 131px;
  height: 126px;
  background-image: url(../images/svg/circle-4.svg);
  left: -31px; }
  @media (max-width: 991px) {
    .stat--conversion-rate:after {
      height: 116px;
      left: -3px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--conversion-rate:after {
      height: 130px; } }

@media (max-width: 991px) {
  .stat--retail {
    margin-left: -38px; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .stat--retail {
    margin-left: 25px; } }

.stat--retail .stat__inner {
  margin-left: -11px; }
  @media (max-width: 991px) {
    .stat--retail .stat__inner {
      margin-left: 17px;
      margin-top: -5px !important; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--retail .stat__inner {
      margin-left: 0;
      margin-right: 49px;
      margin-top: 8px !important; } }

.stat--retail .stat__outer {
  right: -30px;
  top: 9px;
  max-width: 115px; }
  @media (max-width: 991px) {
    .stat--retail .stat__outer {
      right: -22px;
      top: 3px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--retail .stat__outer {
      right: -93px;
      top: 8px; } }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
    .stat--retail .stat__outer {
      max-width: 140px;
      right: -100px; } }

.stat--retail .stat__icon {
  bottom: 41px;
  left: -31px; }
  @media (max-width: 991px) {
    .stat--retail .stat__icon {
      bottom: 44px;
      left: -28px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--retail .stat__icon {
      bottom: 40px;
      left: -23px; } }

.stat--retail:after {
  width: 133px;
  height: 127px;
  background-image: url(../images/svg/circle-2.svg);
  left: -15px; }
  @media (max-width: 991px) {
    .stat--retail:after {
      height: 110px;
      left: 11px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--retail:after {
      height: 125px;
      left: 0; } }

@media (max-width: 991px) {
  .stat--trade {
    margin-left: 10px; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .stat--trade {
    margin-left: 23px; } }

.stat--trade .stat__inner {
  margin-left: -34px; }
  @media (max-width: 991px) {
    .stat--trade .stat__inner {
      margin-left: -1px;
      margin-top: -5px !important; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--trade .stat__inner {
      margin-left: -5px;
      margin-right: 7px;
      margin-top: 3px !important; } }

.stat--trade .stat__entry {
  margin-left: 40px; }

.stat--trade .stat__outer {
  max-width: 130px;
  right: -50px;
  top: 6px; }
  @media (max-width: 991px) {
    .stat--trade .stat__outer {
      max-width: 85px;
      right: -35px;
      top: 3px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--trade .stat__outer {
      right: -120px;
      top: 11px;
      max-width: 102px; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .stat--trade .stat__outer {
      max-width: 120px;
      right: -67px; } }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
    .stat--trade .stat__outer {
      max-width: 140px;
      right: -140px; } }

.stat--trade .stat__icon {
  bottom: 40px;
  left: -24px; }
  @media (max-width: 991px) {
    .stat--trade .stat__icon {
      left: -24px;
      bottom: 45px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--trade .stat__icon {
      left: -30px;
      bottom: 42px; } }

.stat--trade:after {
  width: 127px;
  height: 130px;
  background-image: url(../images/svg/circle-3.svg);
  left: -22px; }
  @media (max-width: 991px) {
    .stat--trade:after {
      height: 117px;
      left: 4px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--trade:after {
      height: 127px; } }

@media (max-width: 991px) {
  .stat--magento {
    margin-left: 20px; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .stat--magento {
    margin-left: -8px; } }

.stat--magento .stat__inner {
  margin-left: -2px;
  margin-top: 16px !important; }
  @media (max-width: 991px) {
    .stat--magento .stat__inner {
      margin-left: 21px;
      margin-top: 4px !important; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--magento .stat__inner {
      margin-left: 23px;
      margin-right: -5px; } }

.stat--magento .stat__outer {
  right: -20px;
  top: 7px;
  max-width: 130px; }
  @media (max-width: 991px) {
    .stat--magento .stat__outer {
      right: -35px;
      top: 9px;
      max-width: 105px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--magento .stat__outer {
      right: -173px;
      top: 13px;
      max-width: 120px; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .stat--magento .stat__outer {
      max-width: 120px;
      right: -55px; } }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
    .stat--magento .stat__outer {
      max-width: 150px;
      right: -180px; } }

.stat--magento .stat__icon {
  bottom: 62px;
  left: -33px; }
  @media (max-width: 991px) {
    .stat--magento .stat__icon {
      bottom: 70px;
      left: -29px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--magento .stat__icon {
      bottom: 63px;
      left: -23px; } }

.stat--magento:after {
  width: 131px;
  height: 126px;
  background-image: url(../images/svg/circle-4.svg);
  left: -31px; }
  @media (max-width: 991px) {
    .stat--magento:after {
      height: 116px;
      left: -3px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--magento:after {
      height: 130px; } }

.stat--bounce-rate .stat__inner {
  margin-left: 23px; }
  @media (max-width: 991px) {
    .stat--bounce-rate .stat__inner {
      margin-left: 29px;
      margin-top: -5px !important; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--bounce-rate .stat__inner {
      margin-left: 23px;
      margin-right: 49px;
      margin-top: 8px !important; } }

.stat--bounce-rate .stat__outer {
  right: -56px;
  top: 16px;
  max-width: 125px; }
  @media (max-width: 991px) {
    .stat--bounce-rate .stat__outer {
      right: -13px;
      top: 4px;
      max-width: 80px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--bounce-rate .stat__outer {
      right: -110px;
      top: 7px;
      max-width: 115px; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .stat--bounce-rate .stat__outer {
      max-width: 100px;
      right: -35px; } }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
    .stat--bounce-rate .stat__outer {
      max-width: 140px;
      right: -115px; } }

.stat--bounce-rate .stat__icon {
  bottom: 23px;
  left: -31px; }
  @media (max-width: 991px) {
    .stat--bounce-rate .stat__icon {
      bottom: 44px;
      left: -29px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--bounce-rate .stat__icon {
      bottom: 19px;
      left: -31px; } }

.stat--bounce-rate:after {
  width: 133px;
  height: 127px;
  background-image: url(../images/svg/circle-2.svg);
  left: -15px; }
  @media (max-width: 991px) {
    .stat--bounce-rate:after {
      height: 110px;
      left: 11px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--bounce-rate:after {
      height: 125px;
      left: 0; } }

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .stat--increase {
    margin-left: 0; } }

.stat--increase .stat__inner {
  margin-left: 17px; }
  @media (max-width: 991px) {
    .stat--increase .stat__inner {
      margin-left: 22px;
      margin-top: -5px !important; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--increase .stat__inner {
      margin-left: 25px;
      margin-right: 7px;
      margin-top: 3px !important; } }

.stat--increase .stat__entry {
  margin-left: 40px; }

.stat--increase .stat__outer {
  max-width: 130px;
  right: -48px;
  top: 9px; }
  @media (max-width: 991px) {
    .stat--increase .stat__outer {
      max-width: 83px;
      right: 0;
      top: 5px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--increase .stat__outer {
      right: -145px;
      top: 9px;
      max-width: 110px; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .stat--increase .stat__outer {
      max-width: 100px;
      right: -15px; } }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
    .stat--increase .stat__outer {
      max-width: 120px;
      right: -140px; } }

.stat--increase .stat__icon {
  bottom: 19px;
  left: -26px; }
  @media (max-width: 991px) {
    .stat--increase .stat__icon {
      left: -33px;
      bottom: 20px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--increase .stat__icon {
      left: -29px;
      bottom: 19px; } }

.stat--increase:after {
  width: 127px;
  height: 130px;
  background-image: url(../images/svg/circle-3.svg);
  left: -4px; }
  @media (max-width: 991px) {
    .stat--increase:after {
      height: 117px;
      left: 4px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--increase:after {
      height: 127px; } }

.stat--session-duration .stat__inner {
  margin-left: 9px;
  margin-top: 16px !important; }
  @media (max-width: 991px) {
    .stat--session-duration .stat__inner {
      margin-left: 31px;
      margin-top: 4px !important; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--session-duration .stat__inner {
      margin-left: 16px;
      margin-right: -5px; } }

.stat--session-duration .stat__outer {
  right: -2px;
  top: 18px;
  max-width: 100px; }
  @media (max-width: 991px) {
    .stat--session-duration .stat__outer {
      right: 0px;
      top: 9px;
      max-width: 80px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--session-duration .stat__outer {
      right: -137px;
      top: 13px;
      max-width: 92px; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .stat--session-duration .stat__outer {
      max-width: 100px;
      right: -35px; } }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
    .stat--session-duration .stat__outer {
      max-width: 120px;
      right: -150px; } }

.stat--session-duration .stat__icon {
  bottom: 48px;
  left: -28px; }
  @media (max-width: 991px) {
    .stat--session-duration .stat__icon {
      bottom: 44px;
      left: -25px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--session-duration .stat__icon {
      bottom: 43px;
      left: -28px; } }

.stat--session-duration:after {
  width: 131px;
  height: 126px;
  background-image: url(../images/svg/circle-4.svg);
  left: -13px; }
  @media (max-width: 991px) {
    .stat--session-duration:after {
      height: 116px;
      left: 13px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .stat--session-duration:after {
      height: 130px;
      left: -7px; } }

/* ------------------------------------------------------------ *\
	Testimonail
\* ------------------------------------------------------------ */
.testimonial .testimonial__entry p {
  font: 300 24px/1.33 "Plantin", sans-serif;
  margin-bottom: 0; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .testimonial .testimonial__entry p {
      font-size: 20px;
      line-height: 1.5; } }

.testimonial .testimonial__entry p + p {
  margin-top: 34px; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .testimonial .testimonial__entry p + p {
      margin-top: 30px; } }

.testimonial .testimonial__author {
  margin-top: 32px; }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .testimonial .testimonial__author {
      margin-top: 21px; } }

.testimonial .testimonial__author p {
  line-height: 1.57; }

/* ------------------------------------------------------------ *\
	Wrapper outer
\* ------------------------------------------------------------ */
.wrapper-outer {
  display: flex; }

/* ------------------------------------------------------------ *\
	Wrapper
\* ------------------------------------------------------------ */
.wrapper {
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  width: 100%; }

/* ------------------------------------------------------------ *\
	Overwrites
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Magnific popup overites
\* ------------------------------------------------------------ */
.mfp-container {
  padding: 0; }

.mfp-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mfp-close {
  opacity: 1;
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 0;
  top: 45px;
  right: 103px; }
  .mfp-close:active {
    top: 45px; }
  @media (max-width: 1440px) {
    .mfp-close {
      right: 44px; } }
  @media (max-width: 991px) {
    .mfp-close {
      top: 21px;
      right: 25px; }
      .mfp-close:active {
        top: 25px; } }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .mfp-close {
      width: 14px;
      height: 14px;
      top: 17px;
      right: 25px; }
      .mfp-close:active {
        top: 17px; } }

/* ------------------------------------------------------------ *\
// 	Nav Slider
\* ------------------------------------------------------------ */
.nav-slider .swiper-slide {
  display: inline-block;
  width: auto !important; }
