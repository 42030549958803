/* ------------------------------------------------------------ *\
	Nav Filter
\* ------------------------------------------------------------ */

.nav-filter {
	display: flex;
	list-style-type: none;
	justify-content: center;
	align-items: center;
	text-align: center; 

	@include breakpoint-down(mobile) {
		justify-content: flex-start;
		margin-bottom: 0; 
	}

	li + li {
		padding-left: 40px; 

		@include breakpoint-down(mobile) {
			padding: 0;	
		}
	}

	a {
		text-decoration: none;
		font-size: 16px;
		transition: $t-duration;
		display: inline-block;

		@include breakpoint-down(mobile) {
			font-size: 14px;	
		}
	}

	.is-active a,
	a:hover {
		color: $c-pink;
	}
}